//@flow

import type { SingleFieldValidationResult } from '../types';
import { VALIDATION_INVALID_SF_ID_FORMAT } from './errorCodes';

/**
 * Validates is SalesForce account canonical ID correct
 * Should satisfy next format "^[\\w]*-[\\d]*$" -> Starts with characters, one hyphen, numbers
 * IMPORTANT: This filter is custom and based on the SF ID representation, if ID format will change
 * in the future, filter should be updated as well.
 *
 * @param sfAccountCanonicalId
 * @returns {boolean}
 */
export const isSfAccountCanonicalIdValid = (sfAccountCanonicalId: string) => {
  const sfIdExpectedFormat = new RegExp('^[\\w]*-[\\d]*$');
  return sfAccountCanonicalId && sfIdExpectedFormat.test(sfAccountCanonicalId);
};

export const validateIsSfIdFormatCorrect = (
  sfAccountCanonicalId: string
): ?SingleFieldValidationResult => {
  if (!isSfAccountCanonicalIdValid(sfAccountCanonicalId)) {
    return { code: VALIDATION_INVALID_SF_ID_FORMAT };
  }
  return undefined;
};
