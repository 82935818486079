const circles = new Map();
let requestAnimationId;
let animate = false;
let height = 0;
let width = 0;

const generate = (container, emojis) => {
  for (let i = 0; i < 15; i++) {
    for (let j = 0; j <= width; j += 200) {
      addCircle(
        i * 150,
        [10 + j, 300],
        emojis[Math.floor(Math.random() * emojis.length)],
        container,
        [i, j]
      );
      addCircle(
        i * 150,
        [10 - j, -300],
        emojis[Math.floor(Math.random() * emojis.length)],
        container,
        [i, j]
      );
    }
  }
};

const addCircle = (delay, range, emoji, container, key) => {
  if (delay !== 0) {
    setTimeout(function () {
      const c = new Circle(
        range[0] + Math.random() * range[1],
        80 + Math.random() * 4,
        emoji,
        {
          x: -0.15 + Math.random() * 0.3,
          y: 1 + Math.random() * 10,
        },
        range,
        container,
        key
      );
      circles.set(key, c);
    }, delay);
  } else {
    const c = new Circle(
      range[0] + Math.random() * range[1],
      80 + Math.random() * 4,
      emoji,
      {
        x: -0.15 + Math.random() * 0.3,
        y: 1 + Math.random() * 10,
      },
      range,
      container,
      key
    );
    circles.set(key, c);
  }
};

function Circle(x, y, emoji, v, range, container, key) {
  const _this = this;
  this.x = x;
  this.y = y;
  this.color = emoji;
  this.v = v;
  this.range = range;
  this.element = document.createElement('span');
  this.element.style.position = 'absolute';
  this.element.style.fontSize = '36px';
  this.element.innerHTML = emoji;
  container.appendChild(this.element);

  this.update = function () {
    if (_this.y > height - 80) {
      if (animate) {
        _this.y = 80 + Math.random() * 4;
        _this.x = _this.range[0] + Math.random() * _this.range[1];
      } else {
        this.element.innerHTML = null;
        circles.delete(key);
        return;
      }
    }
    _this.y += _this.v.y;
    _this.x += _this.v.x;
    this.element.style.transform = `translate3d(${_this.x}px, ${_this.y}px, 0px)`;
    this.element.style.webkitTransform = `translate3d(${_this.x}px, ${_this.y}px, 0px)`;
    this.element.style.mozTransform = `translate3d(${_this.x}px, ${_this.y}px, 0px)`;
    return true;
  };
}

const triggerAnimation = () => {
  circles.forEach(circle => circle.update());
  requestAnimationId = requestAnimationFrame(() => triggerAnimation());
  if (circles.size === 0) {
    finishAnimation();
  }
};

export const animateEmojis = (container, emojis, winHeight, winWidth) => {
  finishAnimation();
  height = winHeight;
  width = winWidth;
  container.current.innerHTML = '';

  generate(container.current, emojis);
  animate = true;
  triggerAnimation();
};

function finishAnimation() {
  circles.clear();
  cancelAnimationFrame(requestAnimationId);
  requestAnimationId = null;
}

export const stopEmojiAnimation = container => {
  animate = false;
};
