import React, { useEffect, useState } from 'react';
import EligibilityOptions from './EligibilityOptions';
import { ELIGIBILITY_RULE_OPTION_ACTIONS } from './reducers/useEligibilityRuleOption';
import SpinnerContext from '../../../common/spinner/SpinnerContext';
import { httpGet } from '../../../../service/http';
import { notificationService } from '../../../../service/notification';

const WorkLocationOptions = ({
  eligibilityRuleOption,
  setEligibilityRuleOption,
  changeEligibilityOptionsField,
  savedEligibilityRules,
  integrationScopeId,
}) => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [body, setBody] = useState([]);
  const [loading, setLoading] = useState(true);
  const headers = [
    {
      key: 'workLocationId',
      label: 'Work Location Id',
    },
    {
      key: 'workLocationName',
      label: 'Work Location Name',
    },
  ];

  useEffect(() => {
    const fetchWorkLocationOptions = async () => {
      try {
        setLoading(true);
        await httpGet(`/v1/integration-scope/${integrationScopeId}/work-location`).then(data => {
          if (!data.length) {
            notificationService.push({
              message: 'No work locations found',
              variant: 'warning',
            });
            return;
          }
          const transformedWorkLocations = data?.map(obj => ({
            workLocationId: obj.id != null ? obj.id : 'EMPTY',
            workLocationName: obj.name,
          }));

          savedEligibilityRules?.split(',').forEach(workLocation => {
            if (!transformedWorkLocations.some(b => b.workLocationId === workLocation)) {
              transformedWorkLocations.push({
                workLocationId: workLocation,
                workLocationName: 'Not found',
              });
            }
          });
          setBody(transformedWorkLocations);
        });
      } catch (e) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    executeWithSpinner(fetchWorkLocationOptions());
  }, [executeWithSpinner, savedEligibilityRules, integrationScopeId]);

  return (
    !loading &&
    body.length > 0 && (
      <EligibilityOptions
        keyField={'workLocationId'}
        headers={headers}
        body={body}
        selectedOptions={eligibilityRuleOption}
        setSelectedOptions={setEligibilityRuleOption}
        optionType={ELIGIBILITY_RULE_OPTION_ACTIONS.SET_WORK_LOCATIONS}
        changeEligibilityOptionsField={changeEligibilityOptionsField}
      />
    )
  );
};

export default WorkLocationOptions;
