import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PayoutCalculationPage from 'app/ui/gyms/gym/payout/steps/PayoutCalculationPage';
import FetchDataFromSfPage from 'app/ui/gyms/gym/payout/steps/FetchDataFromSfPage';
import AdjustRevenuesPage from 'app/ui/gyms/gym/payout/steps/adjusted_revenues/AdjustRevenuesPage';
import CompleteReviewPayoutPage from './steps/complete_review_payout/CompleteReviewPayoutPage';
import {
  PayoutStepsInfo,
  OVERVIEW_STEP,
  FETCH_DATA_STEP,
  ADJUST_REVENUES_STEP,
  COMPLETE_REVIEW_STEP,
} from 'app/ui/gyms/gym/payout/PayoutStepsInfo';

const PayoutSubRouter = () => (
  <Routes>
    <Route index element={<PayoutCalculationPage step={PayoutStepsInfo.get(OVERVIEW_STEP)} />} />
    <Route path=":calculationRunId/*">
      <Route
        path="fetch-salesforce-data"
        element={<FetchDataFromSfPage step={PayoutStepsInfo.get(FETCH_DATA_STEP)} />}
      />
      <Route
        path="adjust-revenues"
        element={<AdjustRevenuesPage step={PayoutStepsInfo.get(ADJUST_REVENUES_STEP)} />}
      />
      <Route
        path="payout-result"
        element={<CompleteReviewPayoutPage step={PayoutStepsInfo.get(COMPLETE_REVIEW_STEP)} />}
      />
    </Route>
  </Routes>
);

export default PayoutSubRouter;
