// @flow
import type { UserRole, User } from 'app/ui/user-manager/types';

const actionsPrefix = 'USER_MANAGER@USER@';

/** Action types. */
export const USER_LOADING = `${actionsPrefix}USER_LOADING`;
export const USER_LOAD_SUCCESS = `${actionsPrefix}USER_LOAD_SUCCESS`;
export const USER_LOAD_FAILURE = `${actionsPrefix}USER_LOAD_FAILURE`;
export const USER_SAVE_SUCCESS = `${actionsPrefix}USER_SAVE_SUCCESS`;
export const OPEN_ROLES_MODAL = `${actionsPrefix}OPEN_ROLES_MODAL`;
export const CLOSE_ROLES_MODAL = `${actionsPrefix}CLOSE_ROLES_MODAL`;
export const OPEN_NEW_EMAIL_MODAL = `${actionsPrefix}OPEN_NEW_EMAIL_MODAL`;
export const CLOSE_NEW_EMAIL_MODAL = `${actionsPrefix}CLOSE_NEW_EMAIL_MODAL`;
export const OPEN_PASSWORD_RESET_MODAL = `${actionsPrefix}OPEN_PASSWORD_RESET_MODAL`;
export const CLOSE_PASSWORD_RESET_MODAL = `${actionsPrefix}CLOSE_PASSWORD_RESET_MODAL`;
export const SET_NEW_EMAIL_TO_USER_SUCCESS = `${actionsPrefix}SET_NEW_EMAIL_TO_USER_SUCCESS`;
export const ADD_ROLE_TO_USER_SUCCESS = `${actionsPrefix}ADD_ROLE_TO_USER_SUCCESS`;
export const REMOVE_ROLE_FROM_USER_SUCCESS = `${actionsPrefix}REMOVE_ROLE_FROM_USER_SUCCESS`;
export const REMOVE_ROLE_FROM_USER_FAILURE = `${actionsPrefix}REMOVE_ROLE_FROM_USER_FAILURE`;

/** Action creators. */
export const userLoading = () => ({
  type: USER_LOADING,
});

export const userLoadSuccess = (user: User) => ({
  type: USER_LOAD_SUCCESS,
  user: user,
});

export const userLoadFailure = (errorMessage: any) => ({
  type: USER_LOAD_FAILURE,
  errorMessage: errorMessage,
});

export const userSaveSuccess = (savedUser: User) => ({
  type: USER_SAVE_SUCCESS,
  user: savedUser,
});

export const openRolesModal = () => ({
  type: OPEN_ROLES_MODAL,
});

export const closeRolesModal = () => ({
  type: CLOSE_ROLES_MODAL,
});

export const openNewEmailModal = () => ({
  type: OPEN_NEW_EMAIL_MODAL,
});

export const closeNewEmailModal = () => ({
  type: CLOSE_NEW_EMAIL_MODAL,
});

export const openPasswordResetModal = () => ({
  type: OPEN_PASSWORD_RESET_MODAL,
});

export const closePasswordResetModal = () => ({
  type: CLOSE_PASSWORD_RESET_MODAL,
});

export const setNewEmailToUserSuccess = (newEmail: string) => ({
  type: SET_NEW_EMAIL_TO_USER_SUCCESS,
  newEmail: newEmail,
});

export const addRoleToUserSuccess = (role: UserRole) => ({
  type: ADD_ROLE_TO_USER_SUCCESS,
  role: role,
});

export const removeRoleFromUserSuccess = (roles: Array<UserRole>) => ({
  type: REMOVE_ROLE_FROM_USER_SUCCESS,
  roles: roles,
});

export const removeRoleFromUserFailure = (errorMessage: any) => ({
  type: REMOVE_ROLE_FROM_USER_FAILURE,
  errorMessage: errorMessage,
});
