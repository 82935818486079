// @flow
import React, { useState, useRef } from 'react';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { validateNew } from 'app/validation/self-signup-page-manager/validator';

import FileUploadFormField from 'app/ui/components/FileUploadFormField';
import TextAreaFormField from 'app/ui/components/TextAreaFormField';
import TextFormField from 'app/ui/components/TextFormField';

import type {
  SignupPageFrontendModel,
  MembershipOffer,
  LocationValidationResult,
  MultiEntitySignupPage,
} from 'app/ui/types';
import { dateToCET } from 'app/utils/date/dateUtil';
import HorizontalFormRow from '../../components/horizontal-form/HorizontalFormRow';
import HorizontalFormLabel from '../../components/horizontal-form/HorizontalFormLabel';
import HorizontalFormTextField from '../../components/horizontal-form/HorizontalFormTextField';
import Modal from 'app/ui/common/modal/Modal';
import { notificationService } from '../../../service/notification';
import HorizontalFormDropdownField from '../../components/horizontal-form/HorizontalFormDropdownField';
import { inviteColleagueEnabledOptions } from '../../common/util/dropdownUtil';
import TrashIcon from './TrashIcon';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

type Props = {
  signupPageModel: SignupPageFrontendModel,
  coord: any,
  setCoord: Function,
  locationValidationResult: LocationValidationResult,
  locationValidationError: ?string,
  offers: MembershipOffer[],
  editMode: boolean,
  createSelfSignupPage?: Function,
  updateSelfSignupPage?: Function,
  linkSignupPageAndOffer?: Function,
  unlinkSignupPageAndOffer?: Function,
  offerIdToAdd?: string,
  setOfferIdToAdd?: Function,
  validateLocation?: Function,
  multiEntitySignupPages?: MultiEntitySignupPage[],
};

/**
 * Component used to Create/Read and Update 'Self Sign Up pages for a company'.
 */
const SelfSignupForm = ({
  signupPageModel,
  coord,
  setCoord,
  locationValidationResult,
  locationValidationError,
  offers,
  editMode = false,
  createSelfSignupPage,
  updateSelfSignupPage,
  linkSignupPageAndOffer,
  unlinkSignupPageAndOffer,
  offerIdToAdd,
  setOfferIdToAdd,
  validateLocation,
  multiEntitySignupPages,
}: Props) => {
  const [showLocationValidationResult, setShowLocationValidationResult] = useState(false);

  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  const formRef = useRef();

  const getSignUpPageLink = token =>
    `${window._env_.REACT_APP_QUALITRAIN_USER_FRONTEND_BASE_URL}/signup?companyToken=${token}`;
  const copyTextToClipboard = text => {
    navigator.clipboard.writeText(text);
    notificationService.push({
      message: 'Copied link to clipboard!',
      variant: 'success',
      autoClose: 5000,
    });
  };

  const handleOnDeleteLogo = () => {
    formRef.current.mutators.clearFileUploadField('logo.url', null);
    formRef.current.mutators.clearFileUploadField('logo.file', null);
  };

  return (
    <div className="mt-4">
      <Form
        onSubmit={editMode ? updateSelfSignupPage : createSelfSignupPage}
        validate={values => validateNew(values, coord)}
        initialValues={{
          ...signupPageModel,
          inviteColleagueEnabled: editMode ? signupPageModel.inviteColleagueEnabled : true,
          signUpPageExpirationDate: dateToCET(signupPageModel.expiration),
        }}
        mutators={{
          clearFileUploadField: ([fieldName, fieldVal], state, { changeValue }) => {
            changeValue(state, fieldName, () => fieldVal);
          },
        }}
        render={({ submitError, handleSubmit, form, submitting, valid, values }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit}>
              <div className="form-row text">
                <h4 className="text-secondary md-2">
                  {editMode
                    ? 'Update personalized Landingpage'
                    : 'Create a personalized Landingpage'}
                </h4>
              </div>
              {/* Name (i.e. company alias) */}
              <div className="form-row text">
                <div className="input-group-append col-12 row-cols-md-3 mb-2">
                  <label className="col-md-4">Company Alias (name shown on the signup page)</label>
                  <div className="col-md-6">
                    <TextFormField controlId="name" disabled={!isEditable} />
                  </div>
                </div>
              </div>
              {/* Logo */}
              <div className="form-row text">
                <div className="input-group-append col-12 row-cols-md-3 mb-2">
                  <label className="col-md-4">Company Logo</label>
                  <div className="col-md-6">
                    <FileUploadFormField
                      controlId="logo"
                      helpTextAfter="Please check that the logo matches the given requirements: The logo should
                        be white with transparent background and min. 360 pixels wide (.png .svg or .jpg format)"
                      accept="image/jpeg, image/jpg, image/svg+xml, image/png"
                      onupdatefiles={event => {
                        const files = event.target.files;
                        if (files.length > 0) {
                          form.change('logo.url', null);
                          form.change('logo.file', files[0]);
                          signupPageModel.logo.url = null;
                          signupPageModel.logo.file = files[0];
                        } else {
                          form.change('logo.url', null);
                          form.change('logo.file', null);
                          signupPageModel.logo.url = null;
                          signupPageModel.logo.file = null;
                        }
                      }}
                      disabled={!isEditable}
                    />
                  </div>
                  <div title="Delete logo" className="col-md-2">
                    {isEditable && <TrashIcon onClick={handleOnDeleteLogo} />}
                  </div>
                </div>
              </div>
              {/* Company Address Search */}
              <div className="form-row text">
                <div className="input-group-append col-12 row-cols-md-3 mb-2">
                  <label className="col-md-4">Company Address Search</label>
                  <div className="col-md-6">
                    <TextFormField
                      controlId="searchTerm"
                      helpTextAfter={
                        coord
                          ? `Your location search is converted to the following coordinates: ${coord.lat}, ${coord.lng}`
                          : ''
                      }
                      disabled={!isEditable}
                    />
                  </div>
                  <div className="col-md-2">
                    {isEditable && (
                      <Link
                        className="btn btn-secondary"
                        to="#"
                        onClick={() => {
                          validateLocation(values.searchTerm);
                          setShowLocationValidationResult(true);
                        }}
                      >
                        Validate
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              {/* Custom Offer Description */}
              <div className="form-row text">
                <div className="input-group-append col-12 row-cols-md-3 mb-2">
                  <label className="col-md-4">Special Offer (Optional)</label>
                  <div className="col-md-8">
                    <TextAreaFormField
                      controlId="customDescription"
                      helpTextAfter="Formatting can be done using HTML e.g. <strong>, <h1> and </br>"
                      disabled={!isEditable}
                    />
                  </div>
                </div>
              </div>
              {/* Invite colleague switch */}
              <div className="form-row text">
                <div className="input-group-append col-12 row-cols-md-3 mb-2">
                  <HorizontalFormLabel controlLabel="Invite a colleague flow" columnWidth={4} />
                  <HorizontalFormDropdownField
                    controlId="inviteColleagueEnabled"
                    controlLabel="Request user payment details"
                    options={inviteColleagueEnabledOptions()}
                    columnWidth={8}
                    disabled={!isEditable}
                  />
                </div>
              </div>
              {/* Expiration Date */}
              <div className="form-row text">
                <div className="input-group-append col-12 row-cols-md-3 mb-2">
                  <label className="col-md-4">Set expiration date</label>
                  <div className="alert alert-warning mt-3 col-md-8">
                    <HorizontalFormRow>
                      <HorizontalFormLabel
                        controlLabel="Expiration date CET of this signup page"
                        columnWidth="4"
                      />
                      <HorizontalFormTextField
                        controlId="signUpPageExpirationDate"
                        controlLabel="Expiration Date"
                        placeholder="DD-MM-YYYY HH:mm"
                        columnWidth={6}
                        disabled={!isEditable}
                      />
                    </HorizontalFormRow>
                    <p className="mb-0">
                      <strong>IMPORTANT!</strong>
                    </p>
                    <p>
                      This date does not specify the sign-up deadline of each month. After this
                      date, the sign-up page will show an error and no one will be able to sign-up
                      until you manually re-activate it again.
                    </p>
                  </div>
                </div>
              </div>
              {/* Link to the Landing Page (Sign Up Page) for the company */}
              {signupPageModel.token && (
                <div className="form-row text">
                  <div className="input-group-append col-12 row-cols-md-3 mb-2">
                    <label className="col-md-4">Sign Up Page Link & Token</label>
                    <div className="alert alert-success form-row col-md-8 mt-3 flex-column">
                      <div className="my-2">
                        <div className="row col-md-8">
                          <a
                            className="d-block mr-2"
                            target="blank"
                            href={getSignUpPageLink(signupPageModel.token)}
                          >
                            {`Link to Self Sign Up Page`}
                          </a>
                          <i
                            className="far fa-copy"
                            role="button"
                            onClick={() =>
                              copyTextToClipboard(getSignUpPageLink(signupPageModel.token))
                            }
                          />
                        </div>
                        <span>Token: {signupPageModel.token}</span>
                      </div>
                      {multiEntitySignupPages &&
                        multiEntitySignupPages.map(mesp => (
                          <div className="my-2 flex-row">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${window._env_.REACT_APP_QUALITRAIN_USER_FRONTEND_BASE_URL}/signup/${mesp.slug}`}
                              className="mr-1"
                            >
                              {`Link To Parent Self Singup Page`}
                            </a>
                            {`- to edit it click`}
                            <a
                              target="_blank"
                              className="ml-1"
                              rel="noreferrer"
                              href={`multi-entity-signup-pages/${mesp.id}`}
                            >
                              {'here'}
                            </a>
                            <div className="d-block" />
                            <span>Slug: {mesp.slug}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
              <button
                className="btn btn-secondary mr-2"
                type="submit"
                autoFocus
                disabled={submitting || !valid || !isEditable}
              >
                Save
              </button>
              {/* Membership Offers */}
              {(editMode || offerIdToAdd) && (
                <div className="mt-4">
                  <div className="text-primary col-md-2">
                    <h5>Linked offers</h5>
                  </div>
                  <div className="text-right">
                    {isEditable && (
                      <Link
                        className="btn btn-primary col-2"
                        to={`/companies/offer-manager/new/details`}
                      >
                        Create new offer
                      </Link>
                    )}
                  </div>
                  <div>
                    <table className="table mt-2">
                      <thead className="thead-light">
                        <tr>
                          <th>Offer ID</th>
                          <th>SF ACC ID</th>
                          <th>SC ACC Name</th>
                          <th>Price</th>
                          <th>Offer Type</th>
                          <th>Available From</th>
                          <th>Available To</th>
                          <th />
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              onChange={e => setOfferIdToAdd(e.target.value)}
                              value={offerIdToAdd}
                              disabled={!isEditable}
                            />
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => linkSignupPageAndOffer()}
                              disabled={!isEditable}
                            >
                              Add this offer
                            </button>
                          </td>
                          <td />
                        </tr>
                        {offers &&
                          offers.map(offer => (
                            <tr key={offer.id}>
                              <td>{offer.id}</td>
                              <td>{offer.sfAccountCanonicalId}</td>
                              <td>{offer.sfAccountCanonicalName}</td>
                              <td>
                                {offer.priceAmount} {offer.priceCurrency}
                              </td>
                              <td>{offer.type}</td>
                              <td>{dateToCET(offer.availableFrom)}</td>
                              <td>{dateToCET(offer.availableTo)}</td>
                              <td>
                                <Link
                                  className="btn btn-primary"
                                  to={`/companies/offer-manager/${offer.id}/details`}
                                >
                                  {isEditable ? 'Edit Offer' : 'View Offer'}
                                </Link>
                              </td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => unlinkSignupPageAndOffer(offer.id)}
                                  disabled={!isEditable}
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {/* Errors */}
              {submitError && (
                <div className="alert alert-danger">{JSON.stringify(submitError, null, 2)}</div>
              )}
            </form>
          );
        }}
      />
      {showLocationValidationResult && (
        <Modal
          isOpen={true}
          modalTitle="Address Validation Result"
          onRequestClose={() => setShowLocationValidationResult(false)}
        >
          {locationValidationResult && (
            <div>
              <div className="row text">
                <div className="col-12 row-cols-md-3 mb-2">
                  <label className="col-md-4">Address: </label>
                  <label className="col-md-4">{locationValidationResult.formattedAddress}</label>
                </div>
              </div>
              <div className="row text">
                <div className="col-12 row-cols-md-3 mb-2">
                  <label className="col-md-4">Latitude: </label>
                  <label className="col-md-4">{locationValidationResult.latitude}</label>
                </div>
              </div>
              <div className="row text">
                <div className="col-12 row-cols-md-3 mb-2">
                  <label className="col-md-4">Longitude: </label>
                  <label className="col-md-4">{locationValidationResult.longitude}</label>
                </div>
              </div>
            </div>
          )}
          {locationValidationError && (
            <div className="alert alert-primary">{locationValidationError}</div>
          )}
          {locationValidationResult &&
            locationValidationResult.latitude &&
            locationValidationResult.longitude && (
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setCoord({
                    searchTerm: locationValidationResult.searchTerm,
                    lat: locationValidationResult.latitude,
                    lng: locationValidationResult.longitude,
                  });
                  setShowLocationValidationResult(false);
                }}
              >
                Confirm
              </button>
            )}
          <button
            className="btn btn-primary ml-2"
            onClick={() => setShowLocationValidationResult(false)}
          >
            Cancel
          </button>
        </Modal>
      )}
    </div>
  );
};

export default SelfSignupForm;
