// @flow
import React from 'react';
import MembershipOfferModal from 'app/ui/common/offer-modal/MembershipOfferModal';

type Props = {
  onRequestClose: Function,
  onSelectOffer: Function,
};

const SelectOfferForNewMembershipModal = ({ onRequestClose, onSelectOffer }: Props) => (
  <MembershipOfferModal
    modalTitle="Create New Membership"
    confirmButtonText="Select"
    offerSelectionWarningText="IMPORTANT: Please reach our to your manager, to make sure you follow the ops process for this case so that the payment charge request will work."
    allowSelectingOfferWithB2CPayment={true}
    onRequestClose={onRequestClose}
    onSelectOffer={onSelectOffer}
    wasB2COffer={false}
  />
);

export default SelectOfferForNewMembershipModal;
