import React, { useContext } from 'react';
import { closeEditChildMembershipModal } from 'app/ui/user-manager/user/memberships/actions';
import UserMembershipsContext from 'app/ui/user-manager/user/memberships/context/UserMembershipsContext';
import ConfirmationModal from 'app/ui/common/modal/ConfirmationModal';

type Props = {
  childMembershipUserId: string,
};

const EditChildMembershipModal = ({ childMembershipUserId }: Props) => {
  const { dispatch } = useContext(UserMembershipsContext);
  const childMembershipUrl = `${window.location.origin}/users/user/${childMembershipUserId}/memberships`;

  const goToChildMembership = () => {
    window.open(childMembershipUrl, '_blank').focus();
  };

  return (
    <ConfirmationModal
      isOpen={true}
      modalTitle="Update linked Plus1 membership"
      yesButtonText="Update Plus1 membership"
      noButtonText="Close"
      onConfirm={() => goToChildMembership()}
      onCancel={() => dispatch(closeEditChildMembershipModal())}
    >
      <div>
        <div className="row text">
          <div className="col-12 row-cols-md-3 mb-2">
            <label className="col-md-12">
              Please{' '}
              <a href={childMembershipUrl} target="blank">
                click here
              </a>{' '}
              to also update the start date of the linked Plus1 membership
            </label>
          </div>
        </div>
      </div>
    </ConfirmationModal>
  );
};

export default EditChildMembershipModal;
