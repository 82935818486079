// @flow
import React, { useState } from 'react';
import { httpGet } from 'app/service/http/index';
import { formatISOZonedDateTimeToUTCDateTime } from 'app/utils/format/dateTimeFormatter';
import { isPositiveInteger } from 'app/utils/number/numberUtils';
import { exportQrcodesToCsv } from 'app/utils/csv/qrcodesCsvExporter';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import QrCodeGenerator from './generator/QRCodeGenerator';
import { SUPPORTED_QR_LANGUAGES } from '../../../utils/qrCodeTranslation/constants';

const GetQRCodes = () => {
  const [gymIds, setGymIds] = useState('');
  const [result, setResult] = useState(null);
  const [errorResult, setErrorResult] = useState(null);

  const getQRCodes = (gymIds: string) => {
    setResult(null);
    setErrorResult(null);

    const filteredGymIds = gymIds
      .split(',')
      .map(gymId => gymId.trim())
      .filter(gymId => isPositiveInteger(gymId)); // the server accepts only Integers

    const getQrcodePromises = filteredGymIds.map(gymId => {
      return httpGet(`/v1/admin/qrcode?gymId=${gymId}`);
    });

    // fixme bobi 2018-12-06: What should we show if the request fails for some of the Gyms?
    // fixme ...: Promise all will fail even if only one of the request fails.
    Promise.all(getQrcodePromises)
      .then(getQrcodeResponses => {
        const combinedQrcodeResponses = getQrcodeResponses.flatMap(
          getQrcodeResponse => getQrcodeResponse
        );
        setResult(combinedQrcodeResponses);
      })
      .catch(error => {
        console.log(`Error getting the QR Codes: ${error}`);
        setErrorResult(error && error.message);
      });
  };

  return (
    <>
      <div className="form-row">
        <div className="col-12">
          <div className="form-group">
            <label>Find QR Codes for Gyms:</label>
            <div className="input-group">
              <input
                id="gymIds"
                type="text"
                className="form-control"
                value={gymIds}
                placeholder="Please enter EGYM gym IDs comma separated"
                onChange={event => setGymIds(event.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => getQRCodes(gymIds)}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <h6 className="mt-3 mb-4">List of QR Codes</h6>
        {errorResult ? (
          <div className="alert alert-primary">{errorResult}</div>
        ) : (
          result && (
            <table className="table">
              <thead>
                <tr>
                  <th>EGYM Gym ID</th>
                  <th>QR Code ID</th>
                  <th>Creation Date</th>
                  {Object.keys(SUPPORTED_QR_LANGUAGES).map((language, index) => (
                    <th key={`th-${language}-${index}`}></th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {result.map(r => (
                  <tr key={r.qrCodeUUID}>
                    <td>{r.egymGymId}</td>
                    <td>{r.qrCodeUUID}</td>
                    <td>{formatISOZonedDateTimeToUTCDateTime(r.creationTimestamp)}</td>
                    {Object.keys(SUPPORTED_QR_LANGUAGES).map((language, index) => (
                      <td key={`${r.qrCodeUUID}-${index}`}>
                        <QrCodeGenerator
                          qrValue={r.qrCodeUUID}
                          gymId={r.egymGymId}
                          creationTimestamp={r.creationTimestamp}
                          language={language}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )
        )}
      </>
      <div className="row my-4">
        <div className="col col-12 col-sm-6 col-xl-4">
          <button
            className="btn btn-secondary btn-block"
            type="button"
            onClick={() => exportQrcodesToCsv(result)}
            disabled={!result || result.length === 0}
          >
            Export to CSV
          </button>
        </div>
      </div>
    </>
  );
};

export default withPageTemplate(GetQRCodes, { headerTitle: 'QR Code Viewer' });
