import type { UserMembershipsState } from '../../user-manager/user/memberships/reducer';
import {
  combineValidators,
  validateDate,
  validateRequired,
} from '../../../validation/common/commonValidators';
import {
  UPLOAD_STATUS_ERROR,
  UPLOAD_STATUS_SUCCESS,
} from '../../gyms/gym/paperlist-checkin/validator';

export const validateCancelMembership = (values: any, state: UserMembershipsState) => {
  const errors = {};

  errors.membershipEndTimestamp = combineValidators(values.membershipEndTimestamp, true)(
    validateDate(),
    validateRequired
  );

  errors.membershipCancellationReason = combineValidators(
    values.membershipCancellationReason,
    true
  )(validateRequired);

  return errors;
};

export const hasUploadError = membership => {
  return membership.uploadResult && membership.uploadResult.status === UPLOAD_STATUS_ERROR;
};

export const isSuccessfullyUploaded = membership => {
  return membership.uploadResult && membership.uploadResult.status === UPLOAD_STATUS_SUCCESS;
};
