export type MembershipOfferType = 'FREE' | 'STANDARD' | 'PLUS_ONE';

export type MembershipOffer = {|
  id?: number,
  b2cPayment?: boolean,
  customTermsDescription?: string,
  employeeInternalIdentifierLabel?: string,
  employeeInternalIdentifierValidationRegex?: string,
  employeeInternalIdentifierValidationDescription?: string,
  priceAmount?: number,
  priceCurrency?: string,
  signupDeadline?: string,
  created?: string,
  availableFrom?: string,
  availableTo?: string,
  sfAccountCanonicalId?: string,
  type?: MembershipOfferType,
  sfAccountCountryCode?: string,
  integrationScopeId?: string,
|};

/**
 * Returns the active offer with the most recent available from
 */
export const getActiveOffer = (signupPageOffers: MembershipOffer[]) => {
  if (signupPageOffers) {
    const signupPageOffersCopy = [...signupPageOffers];
    signupPageOffersCopy.sort(function (a, b) {
      return (b?.id || 0) - (a?.id || 0);
    });
    return signupPageOffersCopy[0];
  }
};
