import React from 'react';
import SepaPaymentMethod from './SepaPaymentMethod';
import StripePaymentMethod from './StripePaymentMethod';
import type { StripePaymentDetails, SepaPaymentDetails } from './types';

type PaymentDetailsProps = {
  paymentDetails: SepaPaymentDetails | StripePaymentDetails,
};

const PaymentMethod = ({ paymentDetails }: PaymentDetailsProps) => {
  const paymentDetailsComponent = {
    sepadirectdebit: SepaPaymentMethod,
    stripe: StripePaymentMethod,
  };

  const PaymentDetailsComponent = paymentDetailsComponent[paymentDetails.type];
  return <PaymentDetailsComponent paymentDetails={paymentDetails} />;
};

export default PaymentMethod;
