// @flow
import React from 'react';
import AsyncSelect from 'react-select/async';
import FirstLastNameOption from 'app/ui/gyms/gym/paperlist-checkin/components/FirstLastNameOption';
import { dateToISODate, isValidDate, toDate } from 'app/utils/date/dateUtil';
import { httpGet } from 'app/service/http';
import type { PaperlistCheckinType } from 'app/ui/gyms/gym/paperlist-checkin/types';
import useDebouncedCallback from 'app/ui/common/hooks/useDebouncedCallback';

type Props = {
  paperlistCheckin: PaperlistCheckinType,
  isDisabled: boolean,
  handleFirstLastNameOnInputChange: Function,
  handleFirstLastNameOnChange: Function,
};

const FirstLastNameDropdown = React.forwardRef(
  (
    {
      paperlistCheckin,
      isDisabled,
      handleFirstLastNameOnInputChange,
      handleFirstLastNameOnChange,
    }: Props,
    ref
  ) => {
    const requestDelay = 1000;

    const [defaultMembershipOptions, setDefaultMembershipOptions] = React.useState([]);
    const [loadMembershipsByFirstLastName] = useDebouncedCallback(
      async (inputValue, callback) => {
        let queryParams = '';
        if (paperlistCheckin.selectedCompanyAlias) {
          queryParams += `&signupPageToken=${paperlistCheckin.selectedCompanyAlias.value}`;
        } else if (
          paperlistCheckin.inputCompanyAlias &&
          paperlistCheckin.inputCompanyAlias.length > 0
        ) {
          queryParams += `&signupPageAlias=${paperlistCheckin.inputCompanyAlias}`;
        }
        const { inputDay: day, inputMonth: month, inputYear: year } = paperlistCheckin;
        const dateString = toDate(day, month, year);
        if (isValidDate(dateString)) {
          queryParams += `&date=${dateToISODate(dateString)}`;
        }
        const membershipWrappers = await httpGet(
          `/v1/membership/search?name=${inputValue}${queryParams}`
        );
        const membershipOptions = membershipWrappers.map(membershipWrapper => ({
          value: membershipWrapper.membership.uuid,
          label: `${membershipWrapper.user.firstName} ${membershipWrapper.user.lastName}`,
          membershipWrapper: membershipWrapper,
        }));

        setDefaultMembershipOptions(membershipOptions);
        callback(membershipOptions);
      },
      requestDelay,
      [
        paperlistCheckin.selectedCompanyAlias,
        paperlistCheckin.inputCompanyAlias,
        paperlistCheckin.inputDay,
        paperlistCheckin.inputMonth,
        paperlistCheckin.inputYear,
      ]
    );

    const loadSuggestedMemberships = (inputValue, callback) => {
      if (!inputValue || inputValue.length < 3) {
        loadMembershipsByFirstLastName.clear();
        return callback([]);
      }
      loadMembershipsByFirstLastName(inputValue, callback);
    };

    return (
      <AsyncSelect
        ref={ref}
        inputValue={paperlistCheckin.inputFirstLastName}
        value={paperlistCheckin.selectedMembership}
        isDisabled={isDisabled || !!paperlistCheckin.selectedMembership}
        defaultOptions={defaultMembershipOptions}
        loadOptions={loadSuggestedMemberships}
        components={{ Option: FirstLastNameOption }}
        isClearable={true}
        onInputChange={(newValue, options) =>
          handleFirstLastNameOnInputChange(newValue, options, paperlistCheckin)
        }
        onChange={(newValue, options) =>
          handleFirstLastNameOnChange(newValue, options, paperlistCheckin)
        }
        styles={{
          menu: (provided, state) => ({
            ...provided,
            width: 'max-content',
          }),
          control: (provided, state) => ({
            ...provided,
            minWidth: '250px',
            boxShadow: 'none',
            border: '1px solid #dcdcdc',
            '&:hover': {
              border: '1px solid #dcdcdc',
            },
          }),
          placeholder: (provided, state) => ({
            ...provided,
            color: '#cccccc',
          }),
        }}
      />
    );
  }
);

export default FirstLastNameDropdown;
