// @flow
import type { GetQRCodeResponse } from 'app/ui/gyms/qrcodes/types';
import { formatISOZonedDateTimeToUTCDateTime } from 'app/utils/format/dateTimeFormatter';
import { generateCsv } from 'app/utils/csv/csvUtil';
import { downloadCsvFile } from 'app/utils/file/fileUtil';

const COLUMN_DEFINITIONS = [
  { headerLabel: 'EGYM Gym Id', properties: ['egymGymId'] },
  { headerLabel: 'QR Code', properties: ['qrCodeUUID'] },
  {
    headerLabel: 'Creation Timestamp',
    properties: ['creationTimestamp'],
    transform: formatISOZonedDateTimeToUTCDateTime,
  },
];

export const generateQrcodesCsv = (result: Array<GetQRCodeResponse>): string => {
  return generateCsv(COLUMN_DEFINITIONS, result);
};

export const exportQrcodesToCsv = (result: Array<GetQRCodeResponse>) => {
  const csv = generateQrcodesCsv(result);
  downloadCsvFile(csv, 'qrcodes.csv');
};
