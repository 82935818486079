// @flow
import * as React from 'react';
import { initialValue } from 'app/ui/user-manager/user/memberships/reducer';
import type { User } from 'app/ui/user-manager/types';

type Props = {
  children: any,
};
type State = {
  user: User,
};

const UserContext = React.createContext<State>({ state: initialValue });

const UserContextProvider = ({ children }: Props) => {
  const [user, setUser] = React.useState(undefined);

  return (
    <UserContext.Provider
      value={{
        user: user,
        setUser: setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContextProvider };
export default UserContext;
