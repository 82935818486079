import moment from 'moment';

export const parseBirthDateField = field => {
  const parseTwoDigitDefault = moment.parseTwoDigitYear;
  moment.parseTwoDigitYear = function (yearString) {
    return yearString <= moment().year() - 2000
      ? parseInt(yearString) + 2000
      : parseInt(yearString) + 1900;
  };

  const returnValue = normaliseValidateDate(field);
  moment.parseTwoDigitYear = parseTwoDigitDefault;

  return returnValue;
};

export const normaliseValidateDate = field => {
  const validDateFormats = [
    'DD-MM-YYYY',
    'DD.MM.YYYY',
    'DD/MM/YYYY',
    'DD-MM-YY',
    'DD.MM.YY',
    'DD/MM/YY',
  ];

  //Invalid field data returning input field
  return moment(field, validDateFormats).isValid()
    ? moment(field, validDateFormats).format('DD.MM.YYYY')
    : field;
};
