// @flow
import JsPDF from 'jspdf';
import type { Gym } from '../../ui/types';
import mergeImages from 'merge-images';
import { SUPPORTED_QR_LANGUAGES } from '../qrCodeTranslation/constants';
import { getTranslationsByKey, translateQRKey } from '../qrCodeTranslation/utils';

export const createGymQRCode = (
  gym: Gym,
  qrCodeImage: string,
  wellpassLogo,
  language = SUPPORTED_QR_LANGUAGES.GERMAN
) => {
  const pdf = new JsPDF({ format: 'a5' });
  pdf.addImage(qrCodeImage, 'PNG', 0, 0, 148, 210);
  return appendGuidelinesPage(pdf, gym, wellpassLogo, language);
};

const appendGuidelinesPage = (pdf, gym: Gym, wellpassLogo, language) => {
  pdf.addPage();

  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  const pageCenterX = pageWidth / 2;
  const pageLeftMargin = 20;
  const logoAspectRatio = 140 / 589; // Dimensions of wellpass-logo.png
  const logoWidth = 65;
  const lineHeight = 7;

  return mergeImages([wellpassLogo]).then(imageData => {
    const logoX = (pageWidth - logoWidth) / 2;
    pdf.addImage(imageData, 'PNG', logoX, 20, logoWidth, logoWidth * logoAspectRatio);

    const headerSectionY = 60;
    pdf.setFont('Helvetica', 'bold');
    pdf.setFontSize(28);
    pdf.text('App', pageCenterX, headerSectionY, 'center');
    pdf.text('Check-In', pageCenterX, headerSectionY + 10, 'center');

    pdf.setFont('Helvetica', 'normal');
    pdf.setFontSize(12);
    pdf.text(
      translateQRKey('HEADER_SUBTITLE_SECTION', language),
      pageCenterX,
      headerSectionY + 20,
      'center'
    );

    const guidelinesSectionY = headerSectionY + 45;
    pdf.setFont('Helvetica', 'bold');
    pdf.setFontSize(16);
    pdf.text(translateQRKey('GUIDELINE_SECTION', language), pageLeftMargin, guidelinesSectionY);

    let listSectionY = guidelinesSectionY + 10;
    const innerListLeftMargin = pageLeftMargin + 8;
    pdf.setFont('Helvetica', 'normal');
    pdf.setFontSize(14);

    // Build lines
    const instructionsTranslations = getTranslationsByKey('INSTRUCTIONS');
    if (instructionsTranslations) {
      instructionsTranslations.forEach((instruction, index) => {
        let splitText = pdf.splitTextToSize(
          instruction[language],
          pdf.internal.pageSize.width - pageLeftMargin * 2
        );
        pdf.text(`${index + 1}.`, pageLeftMargin, listSectionY);
        pdf.text(splitText, innerListLeftMargin, listSectionY);
        listSectionY += lineHeight * splitText.length;

        if (instruction.subItems) {
          instruction.subItems.forEach(subItem => {
            const subItemValue = subItem[language];
            splitText = pdf.splitTextToSize(
              subItemValue,
              pdf.internal.pageSize.width - pageLeftMargin * 2
            );
            pdf.text(splitText, innerListLeftMargin, listSectionY);
            listSectionY += lineHeight * splitText.length;
          });
        }
      });
    }

    const gymAlias = `${gym?.alias || ''}`;
    const addressLine1 = `${gym?.address?.street || '-'} ${gym?.address?.streetNumber || ''}`;
    const addressLine2 = `${gym?.address?.zipCode || '-'} ${gym?.address?.city || ''}`;

    const footerText = `${gymAlias}\n${addressLine1}\n${addressLine2}`;
    const footerTextArray = pdf.splitTextToSize(footerText, pageWidth - pageLeftMargin * 2);

    const addressPositionY = pageHeight - lineHeight * footerTextArray.length;
    footerTextArray.forEach((text, index) => {
      pdf.text(text, pageLeftMargin, addressPositionY + lineHeight * index);
    });

    return pdf;
  });
};
