// @flow
import { notificationService } from 'app/service/notification';

/**
 * Downloads the given data in Blob as a file.
 */
export const downloadBlob = (file: Blob, name: string) => {
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(file, name);
  } else {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(file);
    downloadLink.download = name;
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};

export const downloadCsvFile = (fileContent, fileName) => {
  const blob = new Blob([fileContent], { type: 'text/csv' });
  downloadBlob(blob, fileName);
};

export const downloadFileFromUrl = (downloadUrl, name) => {
  const Headers = {
    Accept: 'text/csv',
  };
  fetch(downloadUrl, { method: 'GET', headers: Headers }).then(response => {
    if (!response.ok) {
      notificationService.push({
        message: `Could not download report: ${response.status} ${response.statusText}`,
        variant: 'error',
      });

      return;
    }

    response.blob().then(blob => downloadBlob(blob, name));
  });
};
