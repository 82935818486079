// @flow
const actionPrefix = 'LIST_MEMBERSHIPS@';

/*** Action Types ***/
export const GET_MEMBERSHIPS_SUCCESS = `${actionPrefix}GET_MEMBERSHIPS_SUCCESS`;
export const GET_MEMBERSHIPS_FAILURE = `${actionPrefix}GET_MEMBERSHIPS_FAILURE`;
export const GET_MEMBERSHIPS_EMPTY = `${actionPrefix}GET_MEMBERSHIPS_EMPTY`;

/*** Action Creators ***/
export const getMembershipsSuccess = (memberships: Array<Object>) => ({
  type: GET_MEMBERSHIPS_SUCCESS,
  memberships: memberships,
});

export const getMembershipsFailure = (errorMessage: string) => ({
  type: GET_MEMBERSHIPS_FAILURE,
  errorMessage: errorMessage,
});

export const getMembershipsEmpty = (emptyMembershipsMessage: string) => ({
  type: GET_MEMBERSHIPS_EMPTY,
  emptyMembershipsMessage: emptyMembershipsMessage,
});
