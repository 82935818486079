import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import {
  currencyDropdownOptions,
  daysDropdownOptions,
  monthsDropdownOptions,
  offerTypeOptions,
  requestUserPaymentDetailsOptions,
} from '../../../common/util/dropdownUtil';
import HorizontalFormLabel from '../../../components/horizontal-form/HorizontalFormLabel';
import HorizontalFormTextField from '../../../components/horizontal-form/HorizontalFormTextField';
import HorizontalFormRow from '../../../components/horizontal-form/HorizontalFormRow';
import HorizontalFormDropdownField from '../../../components/horizontal-form/HorizontalFormDropdownField';
import HorizontalFormTextAreaField from '../../../components/horizontal-form/HorizontalFormTextAreaField';
import type { MembershipOfferFrontendModel } from '../../../types';
import {
  dateToCET,
  getNextPossibleMembershipStartDate,
  getNextSignupDeadline,
  isUtcDateInPast,
} from '../../../../utils/date/dateUtil';
import moment from 'moment';
import { validate } from './validator';
import { offerDecorator } from './decorator';
import { OfferSignupPageList } from './OfferSignupPageList.jsx';
import { isSfAccountCanonicalIdValid } from 'app/validation/common/sfIdValidator';
import type { AuthenticatedUser } from '../../../common/authentication/types';
import { SFCompany } from './types';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

type Props = {
  saveOffer?: Function,
  membershipOffer: MembershipOfferFrontendModel,
  company?: SFCompany,
  loadCompany?: Function,
  editMode: boolean,
  user: AuthenticatedUser,
};

let timeout = 0;
let lastSfAccountCanonicalValue = '';

const regexValidationExternalWebsiteUrl = 'https://www.regextester.com';
const regexValidationSamplesUrl =
  'https://egym.atlassian.net/wiki/spaces/WA/pages/1161199964/RegEx+validation+of+personal+ID+field';

const isUserOpsMaster = (user: AuthenticatedUser) => {
  return user ? user.roles.some(role => role.authority === QUALITRAIN_OPS_MASTER) : false;
};

const getCurrency = (company: SFCompany, countryCode) => {
  const contract = company?.closestContract;
  if (!contract?.currency) {
    switch (countryCode) {
      case 'US':
        return 'USD';
      default:
        return 'EUR';
    }
  }

  return contract.currency;
};
const OfferForm = ({ saveOffer, membershipOffer, company, loadCompany, editMode, user }: Props) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  lastSfAccountCanonicalValue = company?.canonicalId || '';
  const formRef = useRef();
  const [companyLoading, setCompanyLoading] = useState(false);

  useEffect(() => {
    if (editMode && membershipOffer.sfAccountCanonicalId === company.canonicalId) {
      return;
    }

    formRef.current.mutators.setCompanyDetails(company);
  }, [company, membershipOffer, editMode]);

  const gymlibCountryCodes = window._env_.REACT_APP_GYMLIB_COUNTRY_CODES.split(',');
  const showLinkedSingupPages =
    editMode &&
    membershipOffer &&
    !gymlibCountryCodes.includes(membershipOffer.sfAccountCountryCode);

  const setCompanyDetails = (company, state) => {
    const newCompany = company?.[0];
    const currentSfAccountCanonicalValue =
      formRef.current.getFieldState('sfAccountCanonicalId').value;
    const companyNameField = state.fields['sfAccountCompanyName'];
    const countryCodeField = state.fields['sfAccountCountryCode'];
    const availableFromField = state.fields['availableFrom'];
    const b2cPaymentField = state.fields['b2cPayment'];
    const deadlineMonth = state.fields['deadlineMonth'];
    const deadlineDay = state.fields['deadlineDay'];
    const amountField = state.fields['amount'];

    if (!newCompany && currentSfAccountCanonicalValue) {
      companyNameField.change('');
      countryCodeField.change('');
      availableFromField.change('');
      b2cPaymentField.change('');
      amountField.change('');
    } else if (currentSfAccountCanonicalValue) {
      companyNameField.change(newCompany.name);
      const countryCode = newCompany.billingAddress && newCompany.billingAddress.countryCode;
      if (countryCode) {
        countryCodeField.change(countryCode);
      }

      const closestContract = newCompany.closestContract;
      const isB2C = closestContract ? closestContract.isB2C : undefined;
      b2cPaymentField.change(isB2C !== undefined ? isB2C : '');
      amountField.change(closestContract?.b2cPrice);
      availableFromField.change(dateToCET(newCompany.startOfContract));
      if (countryCode && gymlibCountryCodes.includes(countryCode)) {
        deadlineMonth.change('0');
        deadlineDay.change('0');
        b2cPaymentField.change(true);
      }
    }
  };

  const reloadCompany = useCallback(
    sfAccountCanonicalId => {
      if (sfAccountCanonicalId && sfAccountCanonicalId !== lastSfAccountCanonicalValue) {
        lastSfAccountCanonicalValue = sfAccountCanonicalId;
        if (timeout) {
          clearTimeout(timeout);
          setCompanyLoading(false);
        }

        if (isSfAccountCanonicalIdValid(sfAccountCanonicalId)) {
          timeout = setTimeout(() => {
            setCompanyLoading(true);
            loadCompany(sfAccountCanonicalId, !editMode).finally(() => setCompanyLoading(false));
          }, 1000);
        }
      }
    },
    [loadCompany, setCompanyLoading, editMode]
  );

  return (
    <div className="mt-4">
      <Form
        onSubmit={saveOffer}
        validate={values => validate(values, membershipOffer)}
        initialValuesEqual={() => true}
        initialValues={{
          ...membershipOffer,
          sfAccountCompanyName: company?.name || '',
          sfAccountCountryCode:
            company?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode,
          nextPossibleMembershipStartDate: getNextPossibleMembershipStartDate(
            moment(),
            parseInt(membershipOffer.deadlineMonth),
            parseInt(membershipOffer.deadlineDay)
          ).format('DD-MM-YYYY'),
          nextSignupDeadline: getNextSignupDeadline(
            moment(),
            parseInt(membershipOffer.deadlineDay)
          ).format('DD-MM-YYYY'),
          signUpPageExpirationDate: dateToCET(membershipOffer.expiration),
          availableFrom: editMode ? dateToCET(membershipOffer.availableFrom) : '',
          availableTo: dateToCET(membershipOffer.availableTo),
          isOfferEditable: membershipOffer.type === 'PLUS_ONE' ? isUserOpsMaster(user) : true,
          viewOnlyOfferId: editMode && membershipOffer ? membershipOffer.id : '',
          currency: getCurrency(
            company,
            company?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode
          ),
        }}
        decorators={[offerDecorator]}
        mutators={{ setCompanyDetails }}
        render={({ submitError, handleSubmit, submitting, pristine, values, form }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit}>
              <h4 className="mt-3 mb-3">{editMode ? 'Update offer' : 'Create a offer'}</h4>
              {editMode && (
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Offer id" />
                  <HorizontalFormTextField
                    controlId="viewOnlyOfferId"
                    controlLabel="viewOnlyOfferId"
                    placeholder="Offer Id"
                    columnWidth={4}
                    disabled={true}
                    default
                  />
                </HorizontalFormRow>
              )}
              <h6>Company Information</h6>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Salesforce account name" />
                <HorizontalFormTextField
                  controlId="sfAccountCompanyName"
                  controlLabel="sfAccountCompanyName"
                  placeholder="Company Name"
                  columnWidth={4}
                  disabled={true}
                  loading={companyLoading}
                />
              </HorizontalFormRow>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Salesforce country code" />
                <HorizontalFormTextField
                  controlId="sfAccountCountryCode"
                  controlLabel="sfAccountCountryCode"
                  placeholder="SalesForce country code"
                  columnWidth={4}
                  disabled={true}
                  loading={companyLoading}
                />
              </HorizontalFormRow>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Salesforce account canonical ID" />
                <HorizontalFormTextField
                  controlId="sfAccountCanonicalId"
                  controlLabel="sfAccountCanonicalId"
                  placeholder="SalesForce Account ID"
                  columnWidth={4}
                  autoFocus={true}
                  disabled={!values.isOfferEditable || editMode}
                  helpTextAfter={'Example: ACC-123456'}
                />
                <FormSpy
                  subscription={{ dirtyFields: true, values: true }}
                  onChange={props => reloadCompany(props.values.sfAccountCanonicalId)}
                />
              </HorizontalFormRow>
              <br />
              <h6>Offer Settings</h6>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Offer Type" />
                <HorizontalFormDropdownField
                  controlId="type"
                  controlLabel="type"
                  options={offerTypeOptions(isUserOpsMaster(user), values.isOfferEditable)}
                  columnWidth={4}
                  disabled={!values.isOfferEditable || !isEditable}
                />
              </HorizontalFormRow>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Price Incl VAT & currency" />
                <HorizontalFormTextField
                  controlId="amount"
                  controlLabel="amount"
                  placeholder="price"
                  columnWidth={2}
                  disabled={!values.isOfferEditable || values.type === 'FREE' || !isEditable}
                  loading={companyLoading}
                />
                <HorizontalFormDropdownField
                  controlId="currency"
                  controlLabel="currency"
                  options={currencyDropdownOptions()}
                  columnWidth={2}
                  disabled={true}
                />
                <small className="price.currency-help-text-after offset-md-3 col-sm-4">
                  Please be aware that the price is the amount that is paid by the employee. It
                  should not be changed after the company has had first signups. Please double check
                  before editing.
                </small>
              </HorizontalFormRow>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Request user payment details" />
                <HorizontalFormDropdownField
                  controlId="b2cPayment"
                  controlLabel="Request user payment details"
                  options={requestUserPaymentDetailsOptions()}
                  columnWidth={4}
                  disabled={!values.isOfferEditable || values.type === 'FREE' || !isEditable}
                  loading={companyLoading}
                />
              </HorizontalFormRow>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Company specific Personal Identifier (optional)" />
                <HorizontalFormTextField
                  controlId="employeeInternalIdentifierLabel"
                  controlLabel="Company specific Personal Identifier (optional)"
                  placeholder=""
                  columnWidth={4}
                  helpTextAfter="If empty, the field asking for personal identifier will not appear on the landing page"
                  disabled={!values.isOfferEditable || !isEditable}
                />
              </HorizontalFormRow>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="RegEx validation for Personal Identifier (optional)" />
                <HorizontalFormTextField
                  controlId="employeeInternalIdentifierValidationRegex"
                  controlLabel="RegEx validation for Personal Identifier (optional)"
                  placeholder=""
                  columnWidth={4}
                  helpTextAfter={
                    <div>
                      <span>If empty, the personal identifier field will not be validated.</span>
                      <br />
                      <span>Please test your validation here </span>
                      <a target="_blank" rel="noreferrer" href={regexValidationExternalWebsiteUrl}>
                        {regexValidationExternalWebsiteUrl}
                      </a>
                      <br />
                      <span>Please find </span>
                      <a target="_blank" rel="noreferrer" href={regexValidationSamplesUrl}>
                        some examples here
                      </a>
                    </div>
                  }
                  disabled={!values.isOfferEditable || !isEditable}
                />
              </HorizontalFormRow>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="RegEx validation Description (optional)" />
                <HorizontalFormTextAreaField
                  controlId="employeeInternalIdentifierValidationDescription"
                  controlLabel="RegEx validation Description (optional)"
                  placeholder=""
                  columnWidth={6}
                  rows={3}
                  disabled={!values.isOfferEditable || !isEditable}
                />
              </HorizontalFormRow>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Special Conditions (optional)" />
                <HorizontalFormTextAreaField
                  controlId="customTermsDescription"
                  controlLabel="Special Offer (optional)"
                  columnWidth={6}
                  rows={3}
                  disabled={!values.isOfferEditable || !isEditable}
                />
              </HorizontalFormRow>
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Signup deadline" />
                <HorizontalFormDropdownField
                  controlId="deadlineMonth"
                  controlLabel="deadlineMonth"
                  options={monthsDropdownOptions()}
                  columnWidth={2}
                  disabled={!values.isOfferEditable || !isEditable}
                />
                <HorizontalFormDropdownField
                  controlId="deadlineDay"
                  controlLabel="deadlineDay"
                  options={daysDropdownOptions()}
                  columnWidth={2}
                  disabled={!values.isOfferEditable || !isEditable}
                />
                <div className="col-sm-2 form-group justify-content-center d-flex flex-column">
                  <div className=" ">before membership starts</div>
                </div>
              </HorizontalFormRow>
              <div className="alert alert-info text-black-50 offset-md-3 col-sm-6">{`Next signup deadline will be ${values.nextSignupDeadline} to signup for ${values.nextPossibleMembershipStartDate}`}</div>
              {/* Submit Errors */}
              {submitError && (
                <div className="alert alert-danger">{JSON.stringify(submitError, null, 2)}</div>
              )}
              <HorizontalFormRow>
                <HorizontalFormLabel controlLabel="Availability" />
                <div className="form-row mt-sm-2 mr-1">From</div>
                <HorizontalFormTextField
                  controlId="availableFrom"
                  controlLabel="availableFrom"
                  placeholder="DD-MM-YYYY HH:mm"
                  disabled={!values.isOfferEditable || !isEditable}
                  loading={companyLoading}
                />
                <div className="form-row mt-sm-2 ml-3 mr-1">To</div>
                <HorizontalFormTextField
                  controlId="availableTo"
                  controlLabel="availableTo"
                  placeholder="DD-MM-YYYY HH:mm"
                  disabled={
                    !values.isOfferEditable ||
                    (membershipOffer.availableTo && isUtcDateInPast(membershipOffer.availableTo)) ||
                    !isEditable
                  }
                />
              </HorizontalFormRow>
              {isEditable && (
                <button
                  className="btn btn-block btn-secondary col-sm-3"
                  type="submit"
                  disabled={!values.isOfferEditable || submitting || pristine}
                >
                  Save
                </button>
              )}
              {showLinkedSingupPages && (
                <OfferSignupPageList offerId={membershipOffer.id} company={company} />
              )}
            </form>
          );
        }}
      />
    </div>
  );
};

export default OfferForm;
