// @flow
import * as React from 'react';
import validator from 'validator';
import Modal from 'app/ui/common/modal/Modal';
import { httpPut } from 'app/service/http';
import SpinnerContext from '../../common/spinner/SpinnerContext';

type Props = {
  isOpen: boolean,
  onRequestClose: Function,
  user: Object,
};

const NewEmailModal = ({ isOpen, onRequestClose, onSetNewEmailToUserSuccess, user }: Props) => {
  const [newEmail, setNewEmail] = React.useState(user.newEmail);
  const [error, setError] = React.useState();
  const { executeWithSpinner } = React.useContext(SpinnerContext);

  const updateNewEmail = newEmail => setNewEmail(newEmail);

  const isSaveButtonEnabled = () => {
    return newEmail && user.email !== newEmail && validator.isEmail(newEmail);
  };

  const saveNewEmail = event => {
    const requestBody = {
      newEmail: newEmail,
    };

    executeWithSpinner(
      httpPut(`/v1/user/${user.id}/email`, requestBody)
        .then(() => {
          onSetNewEmailToUserSuccess(newEmail);
          onRequestClose();
        })
        .catch(error => {
          setError(error && error.message);
        })
    );

    event.preventDefault();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} modalTitle="Change e-mail address">
      {user && (
        <>
          <form onSubmit={saveNewEmail}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input type="text" id="email" value={user.email} className="form-control" disabled />
            </div>
            <div className="form-group">
              <label htmlFor="newEmail">
                New Email Address - Please be aware this also affects EGYM
              </label>
              <input
                type="text"
                id="newEmail"
                value={newEmail}
                className="form-control"
                onChange={e => updateNewEmail(e.target.value)}
              />
            </div>
            <p className="text-black-50">
              When you change the email address, an double opt in Email is send to new email
              address. Only after the Link in this double opt in Email is clicked by the user the
              new Email is saved. Also an email is sent to the old Email address that informs, that
              email address change request was made.
            </p>
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="form-group form-row">
              <div className="col-sm-9" />
              <button
                className="btn btn-secondary col-sm-3"
                type="submit"
                disabled={!isSaveButtonEnabled()}
              >
                Save
              </button>
            </div>
          </form>
        </>
      )}
    </Modal>
  );
};

export default NewEmailModal;
