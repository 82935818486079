import { useReducer } from 'react';
export const ELIGIBILITY_RULE_OPTION_ACTIONS = {
  INVERT_LEGAL_ENTITY_DROPDOWN: 'INVERT_LEGAL_ENTITY_DROPDOWN',
  INVERT_WORK_LOCATION_DROPDOWN: 'INVERT_WORK_LOCATION_DROPDOWN',
  INVERT_EMPLOYMENT_TYPE_DROPDOWN: 'INVERT_EMPLOYMENT_TYPE_DROPDOWN',
  SET_LEGAL_ENTITIES: 'legalEntities',
  SET_WORK_LOCATIONS: 'workLocations',
  SET_EMPLOYMENT_TYPES: 'employmentTypes',
  SET_ALL_ELIGIBILITY_OPTIONS: 'SET_ALL_ELIGIBILITY_OPTIONS',
};

const eligibilityRuleOptionReducer = (state, modal) => {
  switch (modal.action) {
    case ELIGIBILITY_RULE_OPTION_ACTIONS.INVERT_LEGAL_ENTITY_DROPDOWN:
      return { ...state, isLegalEntitySectionOpen: !state.isLegalEntitySectionOpen };
    case ELIGIBILITY_RULE_OPTION_ACTIONS.INVERT_WORK_LOCATION_DROPDOWN:
      return { ...state, isWorkLocationSectionOpen: !state.isWorkLocationSectionOpen };
    case ELIGIBILITY_RULE_OPTION_ACTIONS.INVERT_EMPLOYMENT_TYPE_DROPDOWN:
      return { ...state, isEmploymentTypeSectionOpen: !state.isEmploymentTypeSectionOpen };
    case ELIGIBILITY_RULE_OPTION_ACTIONS.SET_LEGAL_ENTITIES:
      return { ...state, legalEntities: modal.payload };
    case ELIGIBILITY_RULE_OPTION_ACTIONS.SET_WORK_LOCATIONS:
      return { ...state, workLocations: modal.payload };
    case ELIGIBILITY_RULE_OPTION_ACTIONS.SET_EMPLOYMENT_TYPES:
      return { ...state, employmentTypes: modal.payload };
    case ELIGIBILITY_RULE_OPTION_ACTIONS.SET_ALL_ELIGIBILITY_OPTIONS:
      return {
        ...state,
        workLocations: modal.payload.workLocations,
        employmentTypes: modal.payload.employmentTypes,
        legalEntities: modal.payload.legalEntities,
      };
    default:
      return state;
  }
};

export const useEligibilityRuleOption = initialState =>
  useReducer(eligibilityRuleOptionReducer, initialState);
