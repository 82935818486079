// @flow
import React from 'react';
import AsyncSelect from 'react-select/async';
import { httpGet } from 'app/service/http';
import type { PaperlistCheckinType } from 'app/ui/gyms/gym/paperlist-checkin/types';

type Props = {
  paperlistCheckin: PaperlistCheckinType,
  isDisabled: boolean,
  handleCompanyAliasOnInputChange: Function,
  handleCompanyAliasOnChange: Function,
};

const CompanyAliasDropdown = React.forwardRef(
  (
    {
      paperlistCheckin,
      isDisabled,
      handleCompanyAliasOnInputChange,
      handleCompanyAliasOnChange,
    }: Props,
    ref
  ) => {
    const [defaultCompanyAliasOptions, setDefaultCompanyAliasOptions] = React.useState([]);

    const loadSignupPagesByAlias = async inputValue => {
      if (!inputValue || inputValue.length < 3) {
        return [];
      }
      const signupPages = await httpGet(`/v1/signup-page?alias=${inputValue}`);
      const companyAliasOptions = signupPages.map(signupPage => ({
        value: signupPage.token,
        label: signupPage.name,
      }));
      setDefaultCompanyAliasOptions(companyAliasOptions);
      return companyAliasOptions;
    };

    return (
      <AsyncSelect
        ref={ref}
        inputValue={paperlistCheckin.inputCompanyAlias}
        value={paperlistCheckin.selectedCompanyAlias}
        isDisabled={isDisabled || !!paperlistCheckin.selectedMembership}
        defaultOptions={defaultCompanyAliasOptions}
        loadOptions={loadSignupPagesByAlias}
        autoFocus={true}
        isClearable={true}
        onInputChange={(newValue, options) =>
          handleCompanyAliasOnInputChange(newValue, options, paperlistCheckin)
        }
        onChange={(newValue, options) =>
          handleCompanyAliasOnChange(newValue, options, paperlistCheckin)
        }
        styles={{
          control: (provided, state) => ({
            ...provided,
            minWidth: '250px',
            boxShadow: 'none',
            border: '1px solid #dcdcdc',
            '&:hover': {
              border: '1px solid #dcdcdc',
            },
          }),
          placeholder: (provided, state) => ({
            ...provided,
            color: '#cccccc',
          }),
        }}
      />
    );
  }
);

export default CompanyAliasDropdown;
