import React from 'react';
import { formatISOZonedDateTimeToCETDate } from 'app/utils/format/dateTimeFormatter';
import type { SepaPaymentDetails } from './types';

type SepaPaymentDetailProps = {
  paymentDetails: SepaPaymentDetails,
};

const SepaPaymentMethod = ({ paymentDetails }: SepaPaymentDetailProps) => {
  const { ibanNumber, ownerName, createdAt, status } = paymentDetails;

  return (
    <>
      <div className="card mb-3">
        {status === 'ACTIVE' && <div className="card-header font-weight-bold">Active</div>}
        <div className="row card-body">
          <div className="col-12 col-md-4">
            <label className="row font-weight-bold">IBAN:</label>
            <label className="row">{ibanNumber}</label>
          </div>
          <div className="col-12 col-md-4">
            <label className="row font-weight-bold">Bank Account Holder:</label>
            <label className="row">{ownerName}</label>
          </div>
          <div className="col-12 col-md-4">
            <label className="row font-weight-bold">Creation Date:</label>
            <label className="row">{formatISOZonedDateTimeToCETDate(createdAt)}</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default SepaPaymentMethod;
