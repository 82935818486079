import * as React from 'react';
import { useCallback, useEffect, useState, useContext } from 'react';
import OfferForm from './OfferForm';
import SpinnerContext from '../../../common/spinner/SpinnerContext';
import { httpGet, httpPatch, httpPost } from '../../../../service/http';
import { convertMembershipOfferFrontendModelToMembershipOffer } from './converter';
import { isUtcDateInPast, nowInUTC } from '../../../../utils/date/dateUtil';
import AuthenticationContext from '../../../common/authentication/AuthenticationContext';
import { SFCompany } from './types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import OfferContext from '../context/OfferContext';

const OfferManager = () => {
  const { executeWithSpinner } = useContext(SpinnerContext);
  const { user } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const { offer, setOffer } = React.useContext(OfferContext);
  const [company, setCompany] = useState(null);
  const [errorResult] = useState(null);
  const [editMode, setEditMode] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const paramsOfferId = useParams().offerId;
  const paramsSfAccountId = location.state?.sfAccountId;

  const updateOffer = useCallback(
    (updatedOffer, form) => {
      const backendModel = convertMembershipOfferFrontendModelToMembershipOffer(updatedOffer);
      const updateOfferPage = async () => {
        const shouldEndExistingOffer =
          form.getState().dirtyFields['amount'] ||
          form.getState().dirtyFields['currency'] ||
          form.getState().dirtyFields['b2cPayment'] ||
          form.getState().dirtyFields['customTermsDescription'] ||
          form.getState().dirtyFields['employeeInternalIdentifierLabel'] ||
          form.getState().dirtyFields['deadlineDay'] ||
          form.getState().dirtyFields['type'] ||
          form.getState().dirtyFields['deadlineMonth'];

        try {
          await httpPatch(
            `/v1/membership-offer/${backendModel.id}`,
            buildUpdateOfferBody(offer, backendModel, shouldEndExistingOffer)
          );

          if (shouldEndExistingOffer) {
            const newMembership = await httpPost(
              `/v1/membership-offer/membershipOfferSignupPageUpdate`,
              backendModel
            );
            //Reload the path but with the new offer id so ops have access to this offer url
            window.location.href = `${window.location.protocol}//${window.location.host}/companies/offer-manager/${newMembership.id}/details`;
          }
        } catch (error) {
          setError(error);
        }
      };
      return executeWithSpinner(updateOfferPage());
    },
    [offer, setError, executeWithSpinner]
  );

  const createOffer = useCallback(
    offer => {
      const backendModel = convertMembershipOfferFrontendModelToMembershipOffer(offer);
      const createOfferPage = async () => {
        try {
          const createdOffer = await httpPost(`/v1/membership-offer`, backendModel);

          const gymlibOffer = window._env_.REACT_APP_GYMLIB_COUNTRY_CODES.split(',').includes(
            backendModel.sfAccountCountryCode
          );
          if (gymlibOffer) {
            //Reload the path but with the new offer id so ops have access to this offer url
            window.location.href = `${window.location.protocol}//${window.location.host}/companies/offer-manager/${createdOffer.id}/details`;
          } else {
            //continue with singup page creation
            navigate('/companies/self-signup-manager', {
              state: {
                name: company?.name,
                address: company?.billingAddress,
                offerId: createdOffer.id,
              },
            });
          }
        } catch (error) {
          setError(error);
        }
      };
      return executeWithSpinner(createOfferPage());
    },
    [setError, executeWithSpinner, company, navigate]
  );

  const getCompanyInformation = useCallback(
    (sfAccountCanonicalId, refreshCache = false) => {
      if (!sfAccountCanonicalId) {
        return;
      }

      return httpGet(
        `/v1/salesforce/company?sfAccountCanonicalId=${sfAccountCanonicalId}&refreshCache=${refreshCache}`
      )
        .then(response => {
          const company = SFCompany.parse(response).find(
            c => c.canonicalId === sfAccountCanonicalId
          );
          if (company) {
            setCompany(company);
          }
        })
        .catch(() => {
          setCompany({ canonicalId: sfAccountCanonicalId });
        });
    },
    [setCompany]
  );

  const buildUpdateOfferBody = (offer, backendModel, shouldTerminate) => {
    const updateOfferBody = {
      // Offer ID is necessary for BE validation
      offerId: offer.id,
      sfAccountCanonicalId: backendModel.sfAccountCanonicalId,
      sfAccountCountryCode: backendModel.sfAccountCountryCode,
      employeeInternalIdentifierValidationRegex:
        backendModel.employeeInternalIdentifierValidationRegex
          ? backendModel.employeeInternalIdentifierValidationRegex
          : null,
      employeeInternalIdentifierValidationDescription:
        backendModel.employeeInternalIdentifierValidationDescription
          ? backendModel.employeeInternalIdentifierValidationDescription
          : null,
      availableFrom: backendModel.availableFrom,
      availableTo: !(offer && isUtcDateInPast(offer.availableTo))
        ? backendModel.availableTo
        : undefined,
    };

    if (shouldTerminate) {
      const now = nowInUTC();

      updateOfferBody.availableTo = now;
      updateOfferBody.availableFrom = !isUtcDateInPast(backendModel.availableFrom)
        ? now
        : backendModel.availableFrom;
    }

    return updateOfferBody;
  };

  const processGeneralErrors = error => {
    if (error?.userServiceGeneralErrors) {
      return (
        <ul className="list-group pb-2">
          {error.userServiceGeneralErrors.map(generalError => (
            <li key={`${generalError.code}`} className="list-group-item list-group-item-warning">
              {`${generalError.message || generalError.defaultMessage}`}
            </li>
          ))}{' '}
        </ul>
      );
    }
    return <div className="alert alert-warning">{JSON.stringify(error)}</div>;
  };

  useEffect(() => {
    if (
      offer !== undefined &&
      paramsOfferId &&
      errorResult == null &&
      offer.sfAccountCanonicalId !== undefined
    ) {
      getCompanyInformation(offer.sfAccountCanonicalId);
    } else if (
      offer !== undefined &&
      errorResult == null &&
      editMode === true &&
      offer.sfAccountCanonicalId === undefined
    ) {
      //Move in to context
      if (paramsSfAccountId) {
        executeWithSpinner(getCompanyInformation(paramsSfAccountId, true));
        offer.sfAccountCanonicalId = paramsSfAccountId;
        setOffer(offer);
      }
      setEditMode(false);
    }
  }, [
    offer,
    setOffer,
    paramsOfferId,
    errorResult,
    getCompanyInformation,
    setEditMode,
    editMode,
    executeWithSpinner,
    paramsSfAccountId,
  ]);

  const saveOffer = editMode ? updateOffer : createOffer;
  const companyLoading = (paramsSfAccountId || editMode) && !company;

  return (
    <>
      {error && (
        <>
          {processGeneralErrors(error)}
          <div className="alert alert-info">
            There was an unexpected error. Please start over in the search dialog.
          </div>
        </>
      )}
      {offer && !companyLoading && (
        <OfferForm
          saveOffer={saveOffer}
          membershipOffer={offer}
          company={company}
          loadCompany={getCompanyInformation}
          editMode={editMode}
          user={user}
        />
      )}
    </>
  );
};

export default OfferManager;
