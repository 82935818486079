// @flow
import {
  GET_MEMBERSHIPS_FAILURE,
  GET_MEMBERSHIPS_SUCCESS,
  GET_MEMBERSHIPS_EMPTY,
} from 'app/ui/membership/list-memberships/actions';
import type { Membership } from 'app/ui/membership/types';
import type { BaseAction } from 'app/ui/types';

type State = {
  members: Array<Membership>,
  errorMessage: any,
  emptyMembershipsMessage: any,
};

type Action = {
  memberships: Array<Membership>,
  errorMessage: any,
  emptyMembershipsMessage: any,
};

export const initialState = {
  memberships: [],
  errorMessage: undefined,
  emptyMembershipsMessage: undefined,
};

export const reducer = (state: State, action: BaseAction | Action) => {
  switch (action.type) {
    case GET_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        memberships: action.memberships,
        errorMessage: undefined,
        emptyMembershipsMessage: undefined,
      };
    case GET_MEMBERSHIPS_FAILURE:
      return {
        ...state,
        memberships: [],
        errorMessage: action.errorMessage,
        emptyMembershipsMessage: undefined,
      };
    case GET_MEMBERSHIPS_EMPTY:
      return {
        ...state,
        memberships: [],
        errorMessage: undefined,
        emptyMembershipsMessage: action.emptyMembershipsMessage,
      };
    default:
      return initialState;
  }
};
