// @flow
import { isDev } from 'app/utils/env/envUtils';
import {
  CSRF_TOKEN_COOKIE_NAME,
  CSRF_TOKEN_HEADER_NAME,
  USER_LOCAL_STORAGE_KEY,
} from 'app/ui/common/authentication/AuthenticationContext';
import { getCookie } from 'app/service/http/cookieUtil';
import { notificationService } from 'app/service/notification';

type RequestHeaders = {
  [key: string]: string,
};

/** HTTP Get with error handling. */
export const httpGet = (
  url: string,
  headers: RequestHeaders = {},
  handleResponseFunc: Function = handleResponse
): Promise<any> => {
  const promise = fetch(url, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      ...headers,
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
  }).then(handleResponseFunc);

  if (isDev()) {
    promise
      .then(() => console.log('HTTP GET request succeeded for endpoint:', url))
      .catch(error => console.log('HTTP GET request failed with error:', error, 'endpoint:', url));
  }
  return promise;
};

export const httpPatch = (
  url: string,
  body: Object,
  headers: RequestHeaders = {}
): Promise<any> => {
  const promise = fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      ...headers,
      'Content-Type': 'application/json; charset=utf-8',
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
  }).then(handleResponse);

  if (isDev()) {
    promise
      .then(() => console.log('HTTP PATCH request succeeded for endpoint:', url))
      .catch(error =>
        console.log('HTTP PATCH request failed with error:', error, 'endpoint:', url)
      );
  }
  return promise;
};

export const httpPost = (url: string, body: Object, headers: RequestHeaders = {}): Promise<any> => {
  const promise = fetch(url, {
    method: 'post',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      ...headers,
      'Content-Type': 'application/json; charset=utf-8',
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
  }).then(handleResponse);

  if (isDev()) {
    promise
      .then(() => console.log('HTTP POST request succeeded for endpoint:', url))
      .catch(error => console.log('HTTP POST request failed with error:', error, 'endpoint:', url));
  }
  return promise;
};

export const httpPostUpload = (
  url: string,
  file: File,
  headers: RequestHeaders = {}
): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);
  const promise = fetch(url, {
    method: 'POST',
    headers: {
      ...headers,
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
    body: formData,
  }).then(handleResponse);

  if (isDev()) {
    promise
      .then(() => console.log('HTTP POST upload request succeeded for endpoint:', url))
      .catch(error =>
        console.log('HTTP POST upload request failed with error:', error, 'endpoint:', url)
      );
  }
  return promise;
};

export const httpPut = (url: string, body: Object, headers: RequestHeaders = {}): Promise<any> => {
  const promise = fetch(url, {
    method: 'put',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      ...headers,
      'Content-Type': 'application/json; charset=utf-8',
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
  }).then(handleResponse);

  if (isDev()) {
    promise
      .then(() => console.log('HTTP PUT request succeeded for endpoint:', url))
      .catch(error => console.log('HTTP PUT request failed with error:', error, 'endpoint:', url));
  }
  return promise;
};

export const httpDelete = (url: string, headers: RequestHeaders = {}): Promise<any> => {
  const promise = fetch(url, {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      ...headers,
      'Content-Type': 'application/json; charset=utf-8',
      [CSRF_TOKEN_HEADER_NAME]: getCookie(CSRF_TOKEN_COOKIE_NAME) || '',
    },
  }).then(handleResponse);

  if (isDev()) {
    promise
      .then(() => console.log('HTTP DELETE request succeeded for endpoint:', url))
      .catch(error =>
        console.log('HTTP DELETE request failed with error:', error, 'endpoint:', url)
      );
  }
  return promise;
};

const responseHandler = {
  'text/csv': response => response.blob(),
  'text/plain': response => response.text(),
  default: response => response.json(),
  getHandler: response => {
    const contentType = response.headers.get('Content-Type');
    if (contentType) {
      const objectKey = Object.keys(responseHandler).find(key => contentType.startsWith(key));
      return objectKey ? responseHandler[objectKey] : responseHandler.default;
    }
    return responseHandler.default;
  },
};

const handleResponse = response => {
  if (response.ok) {
    return responseHandler
      .getHandler(response)(response)
      .catch(() => Promise.resolve());
  } else {
    return handleErrorResponse(response);
  }
};

export const handleErrorResponse = response => {
  if (response.status === 401 && window.location.pathname !== '/login') {
    //Removing cookie is not needed, because it is expired, and will be overwritten on next login.
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    window.location.href = '/login';
  } else {
    return response
      .json()
      .then(err => {
        if (response.status === 500) {
          const msg = err.message ? err.message : JSON.stringify(err);
          const errorMessage = `<br/>URL: ${response.url}<br/>HTTP error code: ${response.status}.<br/>HTTP error message: ${msg}.<br/>Please contact QT dev team and show them this error`;
          notificationService.push({ message: errorMessage, variant: 'error' });
        } else {
          return Promise.reject(err);
        }
      })
      .catch(error => {
        const err = JSON.stringify(error);
        notificationService.push({
          message: `URL: ${response.url}<br />HTTP error code: ${response.status}<br /> Error message: ${err}<br/>Please contact QT dev team and show them this error`,
          variant: 'error',
        });
        throw error;
      });
  }
};
