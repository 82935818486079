// @flow

const actionsPrefix = 'USER_MANAGER@USER@CHECKIN@';

// Delete Checkin
export const OPEN_DELETE_CHECKIN_MODAL = `${actionsPrefix}OPEN_DELETE_CHECKIN_MODAL`;
export const CLOSE_DELETE_CHECKIN_MODAL = `${actionsPrefix}CLOSE_DELETE_CHECKIN_MODAL`;
export const DELETE_CHECKIN_SUCCESS = `${actionsPrefix}DELETE_USER_CHECKIN_SUCCESS`;
export const DELETE_CHECKIN_FAILURE = `${actionsPrefix}DELETE_USER_CHECKIN_FAILURE`;

/* Delete Checkin */
export const openDeleteCheckinModal = (checkinId: number) => ({
  type: OPEN_DELETE_CHECKIN_MODAL,
  checkinId: checkinId,
});

export const closeDeleteCheckinModal = () => ({
  type: CLOSE_DELETE_CHECKIN_MODAL,
});

export const deleteCheckinSuccess = (checkinId: number) => ({
  type: DELETE_CHECKIN_SUCCESS,
  checkinId: checkinId,
});

export const deleteCheckinFailure = (error: any) => ({
  type: DELETE_CHECKIN_FAILURE,
  error: error,
});
