// @flow
import * as React from 'react';

type Props = {
  children: any,
};
type State = {
  isSpinnerShown: boolean,
};

const SpinnerContext = React.createContext<State>({ isSpinnerShown: false });

const SpinnerProvider = ({ children }: Props) => {
  const [isSpinnerShown, setSpinnerShown] = React.useState(false);

  /**
   * Executes the promise and shows the spinner while the promise is being executed.
   */
  const executeWithSpinner = React.useCallback((promise: Promise<any>): Promise<any> => {
    if (process.env.NODE_ENV !== 'production' && (!promise || typeof promise.then !== 'function')) {
      throw new Error('The argument to withSpinner() should be a promise');
    }
    if (promise && typeof promise.then === 'function') {
      setSpinnerShown(true);
      // hide the spinner in all cases: when the promise resolves or rejects
      promise.then(
        () => setSpinnerShown(false),
        () => setSpinnerShown(false)
      );
    }
    return promise;
  }, []);

  return (
    <SpinnerContext.Provider
      value={{
        isSpinnerShown: isSpinnerShown,
        setSpinnerShown: setSpinnerShown,
        executeWithSpinner: executeWithSpinner,
      }}
    >
      {children}
    </SpinnerContext.Provider>
  );
};

export { SpinnerProvider };
export default SpinnerContext;
