//@flow
import * as React from 'react';
import type { MembershipOfferFrontendModel } from '../../../types';

type Props = {
  children: any,
};
type State = {
  offer: MembershipOfferFrontendModel,
};

const OfferContext = React.createContext<State>({ state: {} });

const OfferContextProvider = ({ children }: Props) => {
  const [offer, setOffer] = React.useState(undefined);
  const contextValue = React.useMemo(() => {
    return {
      offer,
      setOffer,
    };
  }, [offer]);

  return <OfferContext.Provider value={contextValue}>{children}</OfferContext.Provider>;
};

export { OfferContextProvider };
export default OfferContext;
