// @flow
import type { DropdownOption } from '../../components/types';

export const QUALITRAIN_OPS_MASTER = 'QUALITRAIN_OPS_MASTER';
export const QUALITRAIN_OPS_ADMIN = 'QUALITRAIN_OPS_ADMIN';
export const COMPANY_ADMIN = 'COMPANY_ADMIN';
export const COMPANY_EDITOR = 'COMPANY_EDITOR';
export const QUALITRAIN_OPS_VIEWER = 'QUALITRAIN_OPS_VIEWER';

const addRolesToOptions = (options: Array<DropdownOption>, roles: string[]) => {
  options.push(...roles.map(role => ({ value: role, label: role })));
  return options;
};

export const roleOptionsForOpsMasterDropdown = () => {
  return addRolesToOptions(roleOptionsForBaseDropdown(), [
    QUALITRAIN_OPS_ADMIN,
    QUALITRAIN_OPS_MASTER,
  ]);
};

export const roleOptionsForBaseDropdown = () => {
  return addRolesToOptions(
    [{ value: '', label: 'Select role...' }],
    [COMPANY_ADMIN, COMPANY_EDITOR, QUALITRAIN_OPS_VIEWER]
  );
};

export const userHasRole = (user: AuthenticatedUser, role: string) => {
  return user && user.roles && user.roles.find(r => r.authority === role);
};

export const userHasAnyRole = (user: AuthenticatedUser, roles: string[]) => {
  return user && user.roles && user.roles.some(r => roles.includes(r.authority));
};

export const isOpsUser = (user: AuthenticatedUser) =>
  userHasAnyRole(user, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER, QUALITRAIN_OPS_VIEWER]);

export const isCompanyRole = (role: string) => role === COMPANY_ADMIN || role === COMPANY_EDITOR;
