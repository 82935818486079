// @flow
import { generateCsv } from 'app/utils/csv/csvUtil';
import { downloadCsvFile } from 'app/utils/file/fileUtil';
import type { AdjustedCompanyRevenueInfo } from '../../types';

const COLUMN_DEFINITIONS = [
  {
    headerLabel: 'Salesforce Account ID',
    properties: ['adjustedRevenue.companyRevenue.sfCompanyAccountID'],
  },
  {
    headerLabel: 'Calculation Run ID',
    properties: ['calculationRunId'],
  },
  {
    headerLabel: 'Company Name',
    properties: ['adjustedRevenue.companyRevenue.sfCompanyName'],
  },
  {
    headerLabel: 'Revenue',
    properties: ['adjustedRevenue.companyRevenue.revenue'],
  },
  {
    headerLabel: 'Adjusted Revenue',
    properties: ['adjustedRevenue.companyRevenue.adjustedRevenue'],
  },
  { headerLabel: 'Comment', properties: ['adjustedRevenue.companyRevenue.comment'] },
  {
    headerLabel: 'Revenue Last Month',
    properties: ['adjustedRevenue.revenueLastMonth'],
  },
  {
    headerLabel: 'Comment Last Month',
    properties: ['adjustedRevenue.commentLastMonth'],
  },
];

export const exportAdjustedRevenues = (
  adjustedCompanyRevenuesInfo: Array<AdjustedCompanyRevenueInfo>,
  calculationRunId: string
) => {
  const adjusterRevenuesWithRunId = adjustedCompanyRevenuesInfo.map(adjustedRevenue => ({
    adjustedRevenue,
    calculationRunId,
  }));
  const csv = generateCsv(COLUMN_DEFINITIONS, adjusterRevenuesWithRunId);
  downloadCsvFile(csv, 'adjustedRevenue.csv');
};
