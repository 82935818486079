import React from 'react';
import type { CancelMembershipType } from './types';
import { isSuccessfullyUploaded } from './validator';
import { hasUploadError } from './validator';

type Props = {
  membership: CancelMembershipType,
};

const CancelAllMembershipUploadStatus = ({ membership }: Props) => (
  <>
    {isSuccessfullyUploaded(membership) && (
      <div className="alert alert-success m-0">
        <i className="fas fa-check-circle fa-lg text-success" />
        &nbsp;
        {membership.uploadResult.message}
      </div>
    )}
    {hasUploadError(membership) && (
      <div className="alert alert-danger m-0">
        <i className="fas fa-times-circle fa-lg text-danger" />
        &nbsp;
        {membership.uploadResult.message}
      </div>
    )}
  </>
);

export default CancelAllMembershipUploadStatus;
