// @flow
import React from 'react';

type Props = {
  controlLabel: string,
  columnWidth?: number,
};

const HorizontalFormLabel = ({ controlLabel, columnWidth = 3 }: Props) => (
  <label className={`col-form-label col-md-${columnWidth}`}>{controlLabel}</label>
);

export default HorizontalFormLabel;
