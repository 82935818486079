// @flow
import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

const ProtectedRoute = ({ securityConfig, children }) => {
  const { isAuthenticated, isAuthorized } = React.useContext(AuthenticationContext);
  const location = useLocation();

  if (!isAuthenticated()) {
    return (
      <Navigate
        to={{
          pathname: '/login',
          // Pass the current location when doing the redirect.
          // This way, after login, the user can be redirected back to the requested page.
          options: {
            state: { redirectUrl: location },
          },
        }}
      />
    );
  } else if (!isAuthorized(securityConfig)) {
    return (
      <div className="mt-5 alert alert-warning">You are not authorized to view this page.</div>
    );
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
