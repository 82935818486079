// @flow
import React from 'react';
import DatePartNumberInput from 'app/ui/gyms/gym/paperlist-checkin/components/DatePartNumberInput';
import DeleteIcon from 'app/ui/gyms/gym/paperlist-checkin/components/DeleteIcon';
import CloneIcon from 'app/ui/gyms/gym/paperlist-checkin/components/CloneIcon';
import {
  hasUploadError,
  isSuccessfullyUploaded,
  validatePaperlistCheckin,
} from 'app/ui/gyms/gym/paperlist-checkin/validator';
import CompanyAliasDropdown from 'app/ui/gyms/gym/paperlist-checkin/components/CompanyAliasDropdown';
import FirstLastNameDropdown from 'app/ui/gyms/gym/paperlist-checkin/components/FirstLastNameDropdown';
import { WORKFLOW_STATE_INITIAL } from 'app/ui/gyms/gym/paperlist-checkin/PaperlistCheckinPage';
import PaperlistCheckinUploadStatus from 'app/ui/gyms/gym/paperlist-checkin/components/PaperlistCheckinUploadStatus';
import PaperlistCheckinValidationStatus from 'app/ui/gyms/gym/paperlist-checkin/components/PaperlistCheckinValidationStatus';
import type { PaperlistCheckinType } from 'app/ui/gyms/gym/paperlist-checkin/types';

type Props = {
  paperlistCheckin: PaperlistCheckinType,
  paperlistCheckins: Array<PaperlistCheckinType>,
  index: number,
  workflowState: string,
  updatePaperlistCheckinField: Function,
  appendPaperlistCheckin: Function,
  clonePaperlistCheckin: Function,
  deletePaperlistCheckin: Function,
  validateAllPaperlistCheckins: Function,
};

const PaperlistCheckinRow = ({
  paperlistCheckin,
  paperlistCheckins,
  index,
  workflowState,
  updatePaperlistCheckinField,
  appendPaperlistCheckin,
  clonePaperlistCheckin,
  deletePaperlistCheckin,
  validateAllPaperlistCheckins,
}: Props) => {
  const companyAliasRef = React.useRef(null);
  const firstLastNameRef = React.useRef(null);
  const dayRef = React.useRef(null);
  const monthRef = React.useRef(null);
  const yearRef = React.useRef(null);

  const isLastRow = () => index === paperlistCheckins.length - 1;

  const handleCompanyAliasOnInputChange = (newValue, options) => {
    switch (options.action) {
      case 'input-change':
      case 'set-value':
        updateField('inputCompanyAlias', newValue);
        return;
      default:
        return;
    }
  };

  const handleCompanyAliasOnChange = (newValue, options) => {
    updateField('selectedCompanyAlias', newValue);
    if (options.action === 'clear' || options.action === 'select-option') {
      firstLastNameRef.current.clearValue();
    }
    if (options.action === 'select-option') {
      firstLastNameRef.current.focus();
    }
  };

  const handleFirstLastNameOnInputChange = (newValue, options) => {
    switch (options.action) {
      case 'input-change':
      case 'set-value':
        updateField('inputFirstLastName', newValue);
        return;
      default:
        return;
    }
  };

  const handleFirstLastNameOnChange = (newValue, options) => {
    updateField('selectedMembership', newValue);
    if (options.action === 'select-option') {
      companyAliasRef.current.setValue({
        value: null,
        label: newValue.membershipWrapper.relatedSignupPageNames,
      });
      dayRef.current.focus();
    }
  };

  const handleDayChange = event => {
    updateField('inputDay', event.target.value);
    if (event.target.value.length === 2) {
      monthRef.current.focus();
      monthRef.current.select();
    }
  };
  const handleMonthChange = event => {
    updateField('inputMonth', event.target.value);
    if (event.target.value.length === 2) {
      yearRef.current.focus();
      yearRef.current.select();
    }
  };
  const handleYearChange = event => updateField('inputYear', event.target.value);

  const updateField = (fieldName, newValue) => {
    updatePaperlistCheckinField(paperlistCheckin.id, fieldName, newValue);
  };

  const handleYearKeyDown = event => {
    if ((event.key === 'Tab' && !event.shiftKey) || event.key === 'Enter') {
      const validationResult = validatePaperlistCheckin(paperlistCheckin, paperlistCheckins);
      if (validationResult) {
        event.preventDefault();
      } else {
        if (isLastRow()) {
          event.preventDefault();
          appendPaperlistCheckin();
        }
      }
      updateField('validationResult', validationResult);
    }
  };

  const uploadStatusClass = () => {
    if (hasUploadError(paperlistCheckin)) {
      return 'alert-danger';
    } else if (isSuccessfullyUploaded(paperlistCheckin)) {
      return 'alert-success';
    }

    return '';
  };

  return (
    <tr className={uploadStatusClass()}>
      <td className="align-middle">{index + 1}</td>
      <td className="align-middle">
        <CompanyAliasDropdown
          ref={companyAliasRef}
          paperlistCheckin={paperlistCheckin}
          isDisabled={workflowState !== WORKFLOW_STATE_INITIAL}
          handleCompanyAliasOnInputChange={handleCompanyAliasOnInputChange}
          handleCompanyAliasOnChange={handleCompanyAliasOnChange}
        />
      </td>

      <td className="align-middle">
        <FirstLastNameDropdown
          ref={firstLastNameRef}
          paperlistCheckin={paperlistCheckin}
          isDisabled={workflowState !== WORKFLOW_STATE_INITIAL}
          handleFirstLastNameOnInputChange={handleFirstLastNameOnInputChange}
          handleFirstLastNameOnChange={handleFirstLastNameOnChange}
        />
      </td>
      <td className="align-middle text-center">
        <DatePartNumberInput
          ref={dayRef}
          placeholder="dd"
          value={paperlistCheckin.inputDay}
          handleOnChange={handleDayChange}
          isDisabled={workflowState !== WORKFLOW_STATE_INITIAL}
        />
      </td>
      <td className="align-middle text-center">
        <DatePartNumberInput
          ref={monthRef}
          placeholder="mm"
          value={paperlistCheckin.inputMonth}
          handleOnChange={handleMonthChange}
          isDisabled={workflowState !== WORKFLOW_STATE_INITIAL}
        />
      </td>
      <td className="align-middle text-center">
        <DatePartNumberInput
          ref={yearRef}
          minWidth={60}
          maxWidth={100}
          placeholder="yyyy"
          value={paperlistCheckin.inputYear}
          handleOnChange={handleYearChange}
          handleOnKeyDown={handleYearKeyDown}
          isDisabled={workflowState !== WORKFLOW_STATE_INITIAL}
        />
      </td>
      <td className="text-center align-middle">
        {workflowState === WORKFLOW_STATE_INITIAL && (
          <CloneIcon
            onClick={() => {
              validateAllPaperlistCheckins();
              clonePaperlistCheckin(paperlistCheckin.id);
            }}
          />
        )}
      </td>
      <td className="text-center align-middle">
        {workflowState === WORKFLOW_STATE_INITIAL && (
          <DeleteIcon
            onClick={() => {
              deletePaperlistCheckin(paperlistCheckin.id);
              validateAllPaperlistCheckins();
            }}
          />
        )}
      </td>
      <td className="align-middle">
        <PaperlistCheckinValidationStatus paperlistCheckin={paperlistCheckin} />
      </td>
      <td className="align-middle">
        <PaperlistCheckinUploadStatus paperlistCheckin={paperlistCheckin} />
      </td>
    </tr>
  );
};

export default PaperlistCheckinRow;
