import React from 'react';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import { getCountryFlag } from '../../../utils/country/flagGenerator';

type Props = {
  onCancel: Function,
  newSfAccountCountryCode: string,
  oldSfAccountCountryCode: string,
};

const CountryDifferenceModal = ({
  onCancel,
  newSfAccountCountryCode,
  oldSfAccountCountryCode,
}: Props) => {
  return (
    <>
      <ConfirmationModal
        isOpen={true}
        modalTitle="ERROR: Different Country"
        noButtonText="Back"
        onCancel={onCancel}
        yesButtonHidden
      >
        <div className="form-group">
          <p className="text-black-50">
            The old offer ID belongs to a different country than the new offer ID!
          </p>
          <p className="text-black-50">
            <strong>new offer country: </strong> {newSfAccountCountryCode}{' '}
            {getCountryFlag(newSfAccountCountryCode)}
            <br />
            <strong>old offer SF account: </strong> {oldSfAccountCountryCode}{' '}
            {getCountryFlag(oldSfAccountCountryCode)}
          </p>
          <p className="text-black-50">
            You can’t move members into an offer that is linked to a different country.
          </p>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default CountryDifferenceModal;
