// @flow
import React from 'react';

type Props = {
  onClick: Function,
};

const DeleteIcon = ({ onClick }: Props) => (
  <button className="text-primary border-0 py-0" onClick={onClick}>
    <i className="fas fa-times fa-lg" />
  </button>
);

export default DeleteIcon;
