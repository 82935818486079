// @flow
import React from 'react';

type Props = {
  onClick: Function,
};

const CloneIcon = ({ onClick, isDisabled }: Props) => (
  <button
    disabled={isDisabled}
    className="text-primary border-0 py-0"
    style={{ opacity: isDisabled ? '0.4' : '1' }}
    onClick={onClick}
  >
    <i className="far fa-copy fa-lg" />
  </button>
);

export default CloneIcon;
