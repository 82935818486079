// @flow
import type { SfCompany } from 'app/ui/types';

const actionsPrefix = 'USER_MANAGER@USER@ROLES@';

/** Action types. */
export const SEARCH_FOR_SF_COMPANY_SUCCESS = `${actionsPrefix}SEARCH_FOR_SF_COMPANY_SUCCESS`;
export const SEARCH_FOR_SF_COMPANY_FAILURE = `${actionsPrefix}SEARCH_FOR_SF_COMPANY_FAILURE`;
export const ADD_SF_COMPANY_TO_SELECTED_SF_COMPANIES = `${actionsPrefix}ADD_SF_COMPANY_TO_SELECTED_SF_COMPANIES`;
export const REMOVE_SF_COMPANY_FROM_SELECTED_SF_COMPANIES = `${actionsPrefix}REMOVE_SF_COMPANY_FROM_SELECTED_SF_COMPANIES`;
export const ADD_ROLE_TO_USER_FAILURE = `${actionsPrefix}ADD_ROLE_TO_USER_FAILURE`;

/** Action creators. */
export const searchForSfCompanySuccess = (company: any) => ({
  type: SEARCH_FOR_SF_COMPANY_SUCCESS,
  sfCompany: company,
});

export const searchForSfCompanyFailure = (errorMessage: any) => ({
  type: SEARCH_FOR_SF_COMPANY_FAILURE,
  errorMessage: errorMessage,
});

export const addSfCompanyToSelectedSfCompanies = (sfCompany: SfCompany) => ({
  type: ADD_SF_COMPANY_TO_SELECTED_SF_COMPANIES,
  sfCompany: sfCompany,
});

export const removeSfCompanyFromSelectedSfCompanies = (sfCompanyId: string) => ({
  type: REMOVE_SF_COMPANY_FROM_SELECTED_SF_COMPANIES,
  sfCompanyId: sfCompanyId,
});

export const addRoleToUserFailure = (errorMessage: any) => ({
  type: ADD_ROLE_TO_USER_FAILURE,
  errorMessage: errorMessage,
});
