// @flow
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import UserPageSubRoutes from 'app/ui/user-manager/user/layout/UserPageSubRoutes';
import UserContext, { UserContextProvider } from 'app/ui/user-manager/user/context/UserContext';
import { httpGet } from 'app/service/http';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import UserHeader from 'app/ui/user-manager/user/layout/UserHeader';

type SubRouteLinkProps = {
  path: string,
  text: string,
};

const SubRouteLink = ({ path, text }: SubRouteLinkProps) => (
  <NavLink className={({ isActive }) => `nav-item nav-link ${isActive ? ' active' : ''}`} to={path}>
    {text}
  </NavLink>
);

const UserPageTemplateWithSubRoutes = () => {
  const { user, setUser } = React.useContext(UserContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const { userId } = useParams();

  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await httpGet(`/v1/user/${userId}`);
        setUser(userResponse);
      } catch (error) {
        setUser(undefined);
      }
    };
    executeWithSpinner(fetchUserData());
  }, [userId, setUser, executeWithSpinner]);

  return (
    <>
      <UserHeader user={user} />
      <nav className="nav nav-tabs flex-column flex-sm-row justify-content-center">
        <SubRouteLink path={`/users/user/${userId}/details`} text="Member Details" />
        <SubRouteLink path={`/users/user/${userId}/memberships`} text="Memberships" />
        <SubRouteLink path={`/users/user/${userId}/paymentdetails`} text="Payment Details" />
        <SubRouteLink path={`/users/user/${userId}/checkins`} text="Checkins" />
        <SubRouteLink path={`/users/user/${userId}/activity`} text="Ops Activity" />
      </nav>
      <div className="mt-4">
        <UserPageSubRoutes />
      </div>
    </>
  );
};

const UserPageTemplateWithContext = props => (
  <UserContextProvider>
    <UserPageTemplateWithSubRoutes {...props} />
  </UserContextProvider>
);

export default withPageTemplate(UserPageTemplateWithContext, { headerTitle: 'User Manager' });
