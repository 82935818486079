// @flow
const actionPrefix = 'SELF_SIGNUP_PAGE_MANAGER@';

/*** Types of actions ***/
export const START_CREATING_NEW_SIGN_UP_PAGE = `${actionPrefix}START_CREATING_NEW_SIGN_UP_PAGE`;
export const SAVE_NEW_SIGN_UP_PAGE_SUCCESS = `${actionPrefix}SAVE_NEW_SIGN_UP_PAGE_SUCCESS`;

export const START_EDITING_EXISTING_SIGN_UP_PAGE = `${actionPrefix}START_EDITING_EXISTING_SIGN_UP_PAGE`;
export const SAVE_EXISTING_SIGN_UP_PAGE_SUCCESS = `${actionPrefix}SAVE_EXISTING_SIGN_UP_PAGE_SUCCESS`;

/*** Action creators ***/
export const startCreatingNewSignupPage = (signupPage: SignupPage) => ({
  type: START_CREATING_NEW_SIGN_UP_PAGE,
  signupPage: signupPage,
});

export const saveNewSignupPageSuccess = (signupPage: SignupPage) => ({
  type: SAVE_NEW_SIGN_UP_PAGE_SUCCESS,
  signupPage: signupPage,
});

export const startEditingExistingSignupPage = (signupPage: SignupPage) => ({
  type: START_EDITING_EXISTING_SIGN_UP_PAGE,
  signupPage: signupPage,
});

export const saveExistingSignupPageSuccess = (signupPage: SignupPage) => ({
  type: SAVE_EXISTING_SIGN_UP_PAGE_SUCCESS,
  signupPage: signupPage,
});
