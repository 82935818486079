import React from 'react';
import { formatISOZonedDateTimeToCETDate } from 'app/utils/format/dateTimeFormatter';
import type { StripePaymentDetails } from './types';

type StripePaymentMethodProps = {
  paymentDetails: StripePaymentDetails,
};

const StripePaymentMethod = ({ paymentDetails }: StripePaymentMethodProps) => {
  const { externalId, createdAt, status } = paymentDetails;
  const stripeCustomerLink = `${window._env_.REACT_APP_STRIPE_BASE_URL}/customers/${externalId}`;

  return (
    <>
      <div className="card mb-3">
        {status === 'ACTIVE' && <div className="card-header font-weight-bold">Active</div>}
        <div className="row card-body">
          <div className="col-12 col-md-4">
            <label className="row font-weight-bold">Customer:</label>
            <label className="row">
              <a href={stripeCustomerLink} target="_blank" rel="noopener noreferrer">
                {externalId}
              </a>
            </label>
          </div>
          <div className="col-12 col-md-4">
            <label className="row font-weight-bold">Creation Date:</label>
            <label className="row">{formatISOZonedDateTimeToCETDate(createdAt)}</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default StripePaymentMethod;
