import createDecorator from 'final-form-calculate';
import {
  getNextPossibleMembershipStartDate,
  getNextSignupDeadline,
} from '../../../../utils/date/dateUtil';
import moment from 'moment';

export const offerDecorator = createDecorator(
  {
    field: 'type', // when the type changes
    updates: {
      amount: (typeValue, allValues) => {
        return typeValue === 'FREE' ? null : allValues.amount;
      },
      b2cPayment: (typeValue, allValues) => {
        return typeValue === 'FREE' ? false : allValues.b2cPayment;
      },
    },
  },
  {
    field: 'deadlineMonth', // when the month changes
    updates: {
      nextPossibleMembershipStartDate: (deadlineMonth, allValues) =>
        getNextPossibleMembershipStartDate(
          moment(),
          parseInt(deadlineMonth),
          parseInt(allValues.deadlineDay)
        ).format('DD-MM-YYYY'),
      nextSignupDeadline: (deadlineMonth, allValues) =>
        getNextSignupDeadline(moment(), parseInt(allValues.deadlineDay)).format('DD-MM-YYYY'),
    },
  },
  {
    field: 'deadlineDay', // when the day changes
    updates: {
      nextPossibleMembershipStartDate: (deadlineDay, allValues) =>
        getNextPossibleMembershipStartDate(
          moment(),
          parseInt(allValues.deadlineMonth),
          parseInt(deadlineDay)
        ).format('DD-MM-YYYY'),
      nextSignupDeadline: deadlineDay =>
        getNextSignupDeadline(moment(), parseInt(deadlineDay)).format('DD-MM-YYYY'),
    },
  }
);
