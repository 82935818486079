// @flow
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import GymQRCodesPage from 'app/ui/gyms/gym/qrcodes/GymQRCodesPage';
import PaperlistCheckinPage from 'app/ui/gyms/gym/paperlist-checkin/PaperlistCheckinPage';

const GymSubRoutes = () => (
  <Routes>
    <Route path="qr-codes" element={<GymQRCodesPage />} />
    <Route path="add-checkins" element={<PaperlistCheckinPage />} />
    <Route index element={<Navigate to="qr-codes" />} />
  </Routes>
);

export default GymSubRoutes;
