// @flow
import { generateCsv } from 'app/utils/csv/csvUtil';
import { downloadCsvFile } from 'app/utils/file/fileUtil';

export type HrisMappingUserExport = {
  userProfile: {
    accountId: string,
    firstName: string,
    lastName: string,
    email: string,
    dateOfBirth: string,
  },
  corporateEmployee: {
    id: string,
    birthday: string,
    firstName: string,
    lastName: string,
    workEmail: string,
    workLocationId: string,
    employmentType: string,
    legalEntityId: string,
    startDate: string,
    terminationDate: string,
    personalEmail: string,
  },
  firstNameScore: string,
  lastNameScore: string,
  workEmailMatched: string,
  birthdayMatched: string,
  totalMatchingScore: string,
  bestMatch: string,
};

const COLUMN_DEFINITIONS = [
  { headerLabel: 'Egym_Account_Id', properties: ['userProfile.accountId'] },
  { headerLabel: 'Corporate_Employee_Id', properties: ['corporateEmployee.id'] },
  { headerLabel: 'Corporate_Work_Location_Id', properties: ['corporateEmployee.workLocationId'] },
  { headerLabel: 'Corporate_Employment_Type', properties: ['corporateEmployee.employmentType'] },
  { headerLabel: 'Corporate_Legal_Entity_Id', properties: ['corporateEmployee.legalEntityId'] },
  { headerLabel: 'Corporate_Start_Date', properties: ['corporateEmployee.startDate'] },
  { headerLabel: 'Corporate_Termination_Date', properties: ['corporateEmployee.terminationDate'] },

  { headerLabel: 'Egym_First_Name', properties: ['userProfile.firstName'] },
  { headerLabel: 'Corporate_First_Name', properties: ['corporateEmployee.firstName'] },
  { headerLabel: 'First_Name_Score', properties: ['firstNameScore'] },

  { headerLabel: 'Egym_Last_Name', properties: ['userProfile.lastName'] },
  { headerLabel: 'Corporate_Last_Name', properties: ['corporateEmployee.lastName'] },
  { headerLabel: 'Last_Name_Score', properties: ['lastNameScore'] },

  { headerLabel: 'Egym_Email', properties: ['userProfile.email'] },
  { headerLabel: 'Corporate_Email', properties: ['corporateEmployee.workEmail'] },
  { headerLabel: 'Corporate_Personal_Email', properties: ['corporateEmployee.personalEmail'] },
  { headerLabel: 'Work_Email_Matched', properties: ['workEmailMatched'] },

  { headerLabel: 'Egym_Birthday', properties: ['userProfile.dateOfBirth'] },
  { headerLabel: 'Corporate_Birthday', properties: ['corporateEmployee.birthday'] },
  { headerLabel: 'Birthday_Matched', properties: ['birthdayMatched'] },

  { headerLabel: 'Total_Matching_Score', properties: ['totalMatchingScore'] },
  { headerLabel: 'Best_Match', properties: ['bestMatch'] },
];

export const generateHrisMappingUsersCsv = (users: Array<HrisMappingUserExport>) => {
  return generateCsv(COLUMN_DEFINITIONS, users);
};

export const exportHrisMappingUsersToCsv = (
  users: Array<HrisMappingUserExport>,
  sfAccountCanonicalId: string
) => {
  const csv = generateHrisMappingUsersCsv(users);
  downloadCsvFile(csv, `UserCorporateEmployeeMatch ${sfAccountCanonicalId}.csv`);
};
