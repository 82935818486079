// @flow
import React from 'react';

type Props = {
  value: string,
  minWidth?: number,
  maxWidth?: number,
  placeholder: string,
  isDisabled: boolean,
  handleOnChange: Function,
  handleOnKeyDown: Function,
};

/**
 * Used for the parts of the Date (i.e. Day, Month and Year input).
 *
 * It uses React.forwardRef() in order to expose a reference to the <input> element.
 * The reference (ref) can then be used to focus or select the text of the <input> element.
 **/
const DatePartNumberInput = React.forwardRef(
  (
    {
      value,
      minWidth = 40,
      maxWidth = 50,
      placeholder,
      isDisabled = false,
      handleOnChange,
      handleOnKeyDown,
    }: Props,
    ref
  ) => (
    <input
      ref={ref}
      type="text"
      className="form-control d-inline pl-2 pr-1"
      style={{
        borderRadius: '4px',
        minWidth: `${minWidth}px`,
        maxWidth: `${maxWidth}px`,
        width: '100%',
      }}
      placeholder={placeholder}
      value={value}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      disabled={isDisabled}
    />
  )
);

export default DatePartNumberInput;
