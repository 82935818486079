// @flow
import {
  combineValidators,
  validateDate,
  validateDateInPast,
  validateLength,
  validateDateIsAfterMinimal,
} from 'app/validation/common/commonValidators';
import { validateEmail } from 'app/validation/common/emailValidator';
import { VALIDATION_INVALID_GENDER } from 'app/validation/common/errorCodes';
import type { SingleFieldValidationResult } from 'app/validation/types';

export const validateUser = (values: Object): any => {
  const errors = { user: {} };
  errors.user.firstName = combineValidators(values.user.firstName, true)(validateLength(1, 50));
  errors.user.lastName = combineValidators(values.user.lastName, true)(validateLength(1, 50));
  errors.user.birthday = combineValidators(values.user.birthday, false)(
    validateDate('DD-MM-YYYY'),
    validateDateInPast,
    validateDateIsAfterMinimal
  );
  errors.user.gender = combineValidators(values.user.gender, true)(validateGender);
  return errors;
};

export const validateCreateUser = (values: Object): any => {
  const errors = {};
  errors.firstName = combineValidators(values.firstName, true)(validateLength(1, 50));
  errors.lastName = combineValidators(values.lastName, true)(validateLength(1, 50));
  errors.email = validateEmail(values.email);
  errors.birthday = combineValidators(values.birthday, false)(
    validateDate('DD-MM-YYYY'),
    validateDateInPast,
    validateDateIsAfterMinimal
  );
  errors.gender = combineValidators(values.gender, true)(validateGender);
  return errors;
};

const validateGender = (value: string): ?SingleFieldValidationResult => {
  if (!(value === 'M' || value === 'F' || value === 'D')) {
    return { code: VALIDATION_INVALID_GENDER };
  }
  return undefined;
};
