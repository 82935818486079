// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { components } from 'react-select';
import { dateTimeToDateCET } from 'app/utils/date/dateUtil';
import type { MembershipWrapperType } from 'app/ui/types';

type Props = {
  data: {
    label: string,
    membershipWrapper: MembershipWrapperType,
  },
};

const FirstLastNameOption = (props: Props) => {
  const {
    data: {
      label,
      membershipWrapper: { user, relatedSignupPageNames, membership },
    },
  } = props;
  return (
    <div>
      <div>
        <Link
          className="mt-2 mr-2 float-right"
          target="_blank"
          to={`/users/user/${user.id}/checkins`}
        >
          <i className="fas fa-external-link-square-alt ml-5" />
        </Link>
      </div>
      <components.Option {...props}>
        <div className="row">
          <div className="col text-nowrap">{label}</div>
        </div>
        <div className="row">
          <div className="col text-muted text-nowrap">
            <small>
              {`${relatedSignupPageNames} | ${dateTimeToDateCET(
                membership.membershipStartTimestamp
              )} - ${dateTimeToDateCET(membership.membershipEndTimestamp) || '(no end date)'} `}
            </small>
          </div>
        </div>
      </components.Option>
    </div>
  );
};

export default FirstLastNameOption;
