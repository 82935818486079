// @flow
export const initialInput = {
  inputDay: '',
  inputMonth: '',
  inputYear: '',
  inputCompanyAlias: undefined,
  selectedCompanyAlias: undefined,
  inputFirstLastName: undefined,
  selectedMembership: undefined,
};
