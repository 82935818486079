// @flow
import React from 'react';
import ConfirmationModal from 'app/ui/common/modal/ConfirmationModal';

type Props = {
  onConfirm: Function,
  onCancel: Function,
};

const DeleteCheckinModal = ({ onConfirm, onCancel }: Props) => {
  return (
    <ConfirmationModal
      isOpen={true}
      modalTitle="Delete checkin"
      yesButtonText="Yes"
      noButtonText="No"
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <p className="text-black-50">Are you sure you this check-in should be deleted?</p>
    </ConfirmationModal>
  );
};

export default DeleteCheckinModal;
