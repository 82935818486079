//@flow
import React from 'react';
import CancelAllMembershipsModal from './CancelAllMembershipsModal';
import { httpPost } from '../../../service/http';
import SpinnerContext from '../../common/spinner/SpinnerContext';
import { formatISOZonedDateTimeToZonedDateTime } from '../../../utils/format/dateTimeFormatter';
import CancelAllMembershipUploadStatus from './CancelAllMembershipsUploadStatus';
import moment from 'moment';
import type { CancelMembershipType } from './types';
import {
  UPLOAD_STATUS_ERROR,
  UPLOAD_STATUS_SUCCESS,
} from '../../gyms/gym/paperlist-checkin/validator';
import MoveMembershipsToNewOffer from '../move-all-memberships/MoveMembershipsToNewOffer';

type CancelAllMembershipsHeaderRowType = {
  propertyName: string,
  salesForceValue: string,
};

type Props = {
  memberships: Array<CancelMembershipType>,
  sfAccountCanonicalID: string,
  updateMembership: Function,
  companyName: String,
  companyAddress: String,
};

const CancelAllMembershipsHeaderRow = ({
  propertyName,
  salesForceValue,
}: CancelAllMembershipsHeaderRowType) => (
  <div className="row">
    <div className="col-3 text-primary">{propertyName}</div>
    <div className="col-5">{salesForceValue}</div>
  </div>
);

const CancelAllMembershipsForm = ({
  memberships,
  sfAccountCanonicalID,
  updateMembership,
  companyName,
  companyAddress,
}: Props) => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);

  const [isCancelAllMembershipsModalOpen, setCancelAllMembershipsModalOpen] = React.useState(false);
  const [isMoveMembershipToNewOfferModalOpen, setMoveMembershipToNewOfferModalOpen] =
    React.useState(false);

  const openCancelAllMembershipsModal = () => {
    setCancelAllMembershipsModalOpen(true);
  };

  const closeCancelAllMembershipsModal = () => {
    setCancelAllMembershipsModalOpen(false);
  };

  const closeAcceptNewOfferModal = () => setMoveMembershipToNewOfferModalOpen(false);

  const checkEndDate = (startDate, endDate) => {
    const start = moment(startDate, 'YYYY-MM-DD');
    const end = moment(endDate, 'YYYY-MM-DD');
    return start.isBefore(end) ? endDate : startDate;
  };

  const cancelAllMemberships = values => {
    const patchMemberships = async () => {
      for (const membership of memberships) {
        try {
          const endDate = checkEndDate(
            membership.membershipWrapper.membership.membershipStartTimestamp,
            moment(values.membershipEndTimestamp, 'DD-MM-YYYY').format('YYYY-MM-DD')
          );
          const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;
          const requestBody = {
            endDate: formattedEndDate,
            cancellationReason: values.membershipCancellationReason,
          };
          await httpPost(
            `/admin/v1/membership/${membership.membershipWrapper.membership.uuid}/cancel`,
            requestBody
          ).then(membership => {
            updateMembership(membership.membership.uuid, 'membershipWrapper', membership);
          });
          updateMembership(membership.membershipWrapper.membership.uuid, 'uploadResult', {
            status: UPLOAD_STATUS_SUCCESS,
            message: 'success',
          });
        } catch (error) {
          updateMembership(membership.membershipWrapper.membership.uuid, 'uploadResult', {
            status: UPLOAD_STATUS_ERROR,
            message: error && error.message,
          });
        }
      }
    };
    executeWithSpinner(patchMemberships());
    closeCancelAllMembershipsModal();
  };

  return (
    <>
      <div className="mb-4">
        <div className="row">
          <div className="col-3">&nbsp;</div>
          <div className="col-5 text-primary">Salesforce</div>
        </div>
        <CancelAllMembershipsHeaderRow
          propertyName="Salesforce Canonical Account ID: "
          salesForceValue={sfAccountCanonicalID}
        />
        <CancelAllMembershipsHeaderRow
          propertyName="Company Name: "
          salesForceValue={companyName}
        />
        <CancelAllMembershipsHeaderRow propertyName="Address: " salesForceValue={companyAddress} />
      </div>
      <h3 className="mt-3 mb-4">
        Number of active memberships: {memberships && memberships.length}
      </h3>
      <div className="form-group form-row" style={{ justifyContent: 'flex-end' }}>
        <button
          className="btn btn-primary mb-3 ml-3"
          type="button"
          onClick={() => {
            openCancelAllMembershipsModal();
          }}
        >
          Cancel all of them
        </button>
        <button
          className="btn btn-secondary mb-3 ml-3"
          type="button"
          onClick={() => setMoveMembershipToNewOfferModalOpen(true)}
        >
          Move all to a new offer
        </button>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last name</th>
            <th>Membership start date</th>
            <th>Membership end date</th>
            <th>Offer ID</th>
            <th>Email</th>
            <th>Identifiers</th>
            <th />
          </tr>
        </thead>
        {memberships && (
          <tbody>
            {memberships.map(membership => (
              <tr key={membership.membershipWrapper.membership.uuid}>
                <td>{membership.membershipWrapper.user.firstName}</td>
                <td>{membership.membershipWrapper.user.lastName}</td>
                <td>
                  {formatISOZonedDateTimeToZonedDateTime(
                    membership.membershipWrapper.membership.membershipStartTimestamp,
                    membership.membershipWrapper.membership.timeZoneId,
                    'DD-MM-YYYY'
                  )}
                </td>
                <td>
                  {formatISOZonedDateTimeToZonedDateTime(
                    membership.membershipWrapper.membership.membershipEndTimestamp,
                    membership.membershipWrapper.membership.timeZoneId,
                    'DD-MM-YYYY'
                  )}
                </td>
                <td>{membership.membershipWrapper.membership.membershipOfferId}</td>
                <td>{membership.membershipWrapper.user.email}</td>
                <td>
                  <strong>UserId:</strong> {membership.membershipWrapper.membership.userId}
                  <br />
                  <strong>MembershipId:</strong> {membership.membershipWrapper.membership.uuid}
                </td>
                <td>
                  <CancelAllMembershipUploadStatus membership={membership} />
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {isCancelAllMembershipsModalOpen && (
        <CancelAllMembershipsModal
          countMemberships={memberships.length}
          companyName={companyName}
          companyAddress={companyAddress}
          sfAccountCanonicalId={sfAccountCanonicalID}
          onCancel={closeCancelAllMembershipsModal}
          onConfirm={cancelAllMemberships}
        />
      )}

      {isMoveMembershipToNewOfferModalOpen && (
        <MoveMembershipsToNewOffer
          companyName={companyName}
          companyAddress={companyAddress}
          sfAccountCanonicalId={sfAccountCanonicalID}
          onCancel={closeAcceptNewOfferModal}
        />
      )}
    </>
  );
};

export default CancelAllMembershipsForm;
