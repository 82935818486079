// @flow
import React from 'react';
import type { User } from 'app/ui/user-manager/types';

type Props = {
  user: User,
};

const UserHeader = ({ user }: Props) => (
  <div
    className="row justify-content-center w-100"
    style={{ marginTop: -45, position: 'absolute' }}
  >
    <h5 className="font-weight-normal">{user && `${user.firstName} ${user.lastName}`}</h5>
  </div>
);

export default UserHeader;
