// @flow
import * as React from 'react';
import type { AuthenticatedUser, RouteSecurityConfig } from 'app/ui/common/authentication/types';
import useLocalStorage from 'app/ui/common/hooks/useLocalStorage';

export const CSRF_TOKEN_COOKIE_NAME = 'XSRF-TOKEN';
export const CSRF_TOKEN_HEADER_NAME = 'X-XSRF-TOKEN';
export const USER_LOCAL_STORAGE_KEY = 'USER';

type Props = {
  children: any,
};

type State = {
  user: AuthenticatedUser,
};

const AuthenticationContext = React.createContext<State>({ user: undefined });

const AuthenticationProvider = ({ children }: Props) => {
  const [user, setUser, removeUser] = useLocalStorage(USER_LOCAL_STORAGE_KEY, undefined);

  const login = (user: Object) => {
    setUser(user);
  };

  const logout = () => {
    removeUser();
  };

  const isAuthenticated = () => {
    return user !== undefined;
  };

  const isAuthorized = (securityConfigs: Array<RouteSecurityConfig>) => {
    if (!securityConfigs || !securityConfigs.length) return true;
    let roles = [];
    if (user && user.roles) {
      roles = user.roles.map(role => role.authority);
    }

    return !!securityConfigs.find(config => config.role && roles.indexOf(config.role) !== -1);
  };

  return (
    <AuthenticationContext.Provider
      value={{
        user: user,
        isAuthenticated: isAuthenticated,
        isAuthorized: isAuthorized,
        login: login,
        logout: logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationProvider };
export default AuthenticationContext;
