// @flow
import * as React from 'react';
import { Field } from 'react-final-form';
import { resolveErrorCode } from 'app/validation/errorCodeResolver';

import type { FormFieldProps } from 'app/ui/components/types';

const HorizontalFormTextAreaField = ({
  controlId,
  controlLabel,
  helpTextAfter,
  placeholder,
  columnWidth,
  rows = 3,
  disabled,
  format,
}: FormFieldProps) => (
  <div key={controlId} className={`col-md-${columnWidth}`}>
    <Field name={controlId} type="text" format={format}>
      {({ input, meta }) => {
        const shouldDisplayError = meta.submitError || (meta.touched && meta.error);
        return (
          <>
            <textarea
              rows={rows}
              {...input}
              className={`form-control${shouldDisplayError ? ' is-invalid' : ''}`}
              id={controlId}
              type="text"
              placeholder={placeholder}
              aria-describedby={`${controlId}-help-text-after`}
              disabled={disabled}
            />
            {helpTextAfter && (
              <small className={`${controlId}-help-text-after`}>{helpTextAfter}</small>
            )}
            {shouldDisplayError && (
              <div className="invalid-feedback">
                {resolveErrorCode(meta.submitError || meta.error, controlLabel)}
              </div>
            )}
          </>
        );
      }}
    </Field>
  </div>
);

export default HorizontalFormTextAreaField;
