// @flow
import React, { createContext } from 'react';

type Props = {
  children: any,
};
type State = {
  moveMembershipsDto: any,
};

const MoveMembershipsContext = createContext<State>({ moveMembershipsDto: {} });

const MoveMembershipsProvider = ({ children }: Props) => {
  const [moveMembershipsDto, setMoveMembershipsDto] = React.useState({});

  return (
    <MoveMembershipsContext.Provider
      value={{
        moveMembershipsDto: moveMembershipsDto,
        setMoveMembershipsDto: setMoveMembershipsDto,
      }}
    >
      {children}
    </MoveMembershipsContext.Provider>
  );
};

export { MoveMembershipsProvider };
export default MoveMembershipsContext;
