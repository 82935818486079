export const SUPPORTED_QR_LANGUAGES = {
  ENGLISH: 'ENGLISH',
  GERMAN: 'GERMAN',
};

export const QR_TRANSLATIONS = {
  DOWNLOAD_QR_LABEL: {
    [SUPPORTED_QR_LANGUAGES.ENGLISH]: 'Download English QR',
    [SUPPORTED_QR_LANGUAGES.GERMAN]: 'Download German QR',
  },
  HEADER_SUBTITLE_SECTION: {
    [SUPPORTED_QR_LANGUAGES.ENGLISH]: '(Instructions for counter staff)',
    [SUPPORTED_QR_LANGUAGES.GERMAN]: '(Ansicht für Thekenpersonal)',
  },
  GUIDELINE_SECTION: {
    [SUPPORTED_QR_LANGUAGES.ENGLISH]: 'This is how it works:',
    [SUPPORTED_QR_LANGUAGES.GERMAN]: 'So funktioniert‘s:',
  },
  INSTRUCTIONS: [
    {
      [SUPPORTED_QR_LANGUAGES.ENGLISH]: 'Place QR code visibly on your counter',
      [SUPPORTED_QR_LANGUAGES.GERMAN]: 'Aufsteller gut sichtbar auf der Thekeplatzieren',
    },
    {
      [SUPPORTED_QR_LANGUAGES.ENGLISH]:
        'Please have Wellpass members scan the QR code using the EGYM Wellpass App',
      [SUPPORTED_QR_LANGUAGES.GERMAN]:
        'Mitglied mit der EGYM Wellpass App den QR-Code scannen lassen',
    },
    {
      [SUPPORTED_QR_LANGUAGES.ENGLISH]: 'Check digital ticket in the App:',
      [SUPPORTED_QR_LANGUAGES.GERMAN]: 'Digitales Ticket in der App prüfen:',
      subItems: [
        {
          [SUPPORTED_QR_LANGUAGES.ENGLISH]: '- Green flashing checked-in symbol',
          [SUPPORTED_QR_LANGUAGES.GERMAN]: '- blinkendes Check-In Symbol',
        },
        {
          [SUPPORTED_QR_LANGUAGES.ENGLISH]: '- Current date and time',
          [SUPPORTED_QR_LANGUAGES.GERMAN]: '- aktuelles Datum und Uhrzeit',
        },
      ],
    },
    {
      [SUPPORTED_QR_LANGUAGES.ENGLISH]: 'Grant access',
      [SUPPORTED_QR_LANGUAGES.GERMAN]: 'Mitglied Zutritt gewähren',
    },
  ],
};
