import React from 'react';

const SimpleCheckBox = ({ controlId, checked, onChange, className = '' }) => (
  <>
    <input
      checked={checked}
      onChange={onChange}
      id={controlId}
      type="checkbox"
      className={`simple-check-box ${className}`}
    />
  </>
);

export default SimpleCheckBox;
