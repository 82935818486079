import * as React from 'react';
import { formatMoneyAmount } from 'app/utils/format/currencyFormatter';

const countryNames = {
  DE: 'Germany',
  AT: 'Austria',
  CH: 'Switzerland',
  IT: 'Italy',
  FR: 'France',
};

type Props = {
  country: string,
  count: number,
  volume: number,
};

const ChargeStatsCard = ({ country, count, volume }: Props) => {
  return (
    <div className="card my-5 mr-3">
      <div className="card-header font-weight-bold">
        {countryNames[country] ? `${countryNames[country]} (${country})` : country}
      </div>
      <div className="row card-body">
        <div className="col-12">
          Number of transactions: <strong>{count}</strong>
        </div>
        <div className="col-12">
          Transaction volume: <strong>{formatMoneyAmount(volume)}</strong>
        </div>
      </div>
    </div>
  );
};

export default ChargeStatsCard;
