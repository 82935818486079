// @flow
import React from 'react';
import Sidebar from 'app/ui/layout/Sidebar';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import { NotificationToaster } from 'app/ui/common/notification/NotificationToaster';
import { Outlet } from 'react-router-dom';
import { isOpsUser } from 'app/ui/common/authentication/roles';

const MainLayoutWithRoutes = () => {
  const { isAuthenticated, user } = React.useContext(AuthenticationContext);

  return (
    <div className="container-fluid">
      <div className={isAuthenticated() ? 'row' : ''}>
        {isAuthenticated() && (
          <nav className="col-4 col-sm-3 col-xl-2 bg-dark pl-0 pr-0 pt-1 vh-100 fixed-top">
            {isOpsUser(user) && <Sidebar />}
          </nav>
        )}
        <main
          className={
            isAuthenticated() ? 'col-8 offset-4 col-sm-9 offset-sm-3 col-xl-10 offset-xl-2' : ''
          }
        >
          <NotificationToaster />
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default MainLayoutWithRoutes;
