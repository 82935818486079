// @flow
import React, { useEffect, useState } from 'react';
import Membership from 'app/ui/user-manager/user/memberships/Membership';
import EditMembership from 'app/ui/user-manager/user/memberships/EditMembership';

import type { MembershipWrapperTypeWithUIFlags } from 'app/ui/types';
import moment from 'moment';

type Props = {
  title: string,
  membershipWrappers: Array<MembershipWrapperTypeWithUIFlags>,
  chronology: string,
};

const FilteredMembershipsSection = ({
  title,
  membershipWrappers,
  chronology,
  isEditable,
}: Props) => {
  const [activeOrLatestStartMembershipId, setActiveOrLatestStartMembershipId] = useState('');

  useEffect(() => {
    const memberships = membershipWrappers && membershipWrappers.map(m => m.membership);
    const activeMembership = memberships && memberships.find(m => m.isActive);
    if (!memberships) return;
    if (activeMembership) {
      setActiveOrLatestStartMembershipId(activeMembership.uuid);
      return;
    }
    const latestStartingMembership = memberships.reduce(
      (mostRecentMembership, currentMembership) => {
        return moment(currentMembership.membershipStartDate).isAfter(
          mostRecentMembership.membershipStartDate
        )
          ? currentMembership
          : mostRecentMembership;
      },
      memberships[0]
    );
    setActiveOrLatestStartMembershipId(latestStartingMembership?.uuid);
  }, [membershipWrappers]);

  return (
    <>
      {membershipWrappers && membershipWrappers.length > 0 && (
        <h6 className="pt-4 text-uppercase">{title}</h6>
      )}
      {membershipWrappers.map(membershipWrapper =>
        membershipWrapper.editMode ? (
          <EditMembership
            key={membershipWrapper.membership.uuid || 'new-membership'}
            {...membershipWrapper}
            chronology={chronology}
          />
        ) : (
          <Membership
            key={membershipWrapper.membership.uuid}
            {...membershipWrapper}
            chronology={chronology}
            isEditable={isEditable}
            corporateEmployees={membershipWrapper.corporateEmployees}
            showUnlinkHrisButton={
              membershipWrapper.membership.uuid === activeOrLatestStartMembershipId
            }
          />
        )
      )}
    </>
  );
};

export default FilteredMembershipsSection;
