// @flow
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import UserDetailsPage from 'app/ui/user-manager/user/details/UserDetailsPage';
import UserMembershipsPage from 'app/ui/user-manager/user/memberships/UserMembershipsPage';
import { UserMembershipsContextProvider } from 'app/ui/user-manager/user/memberships/context/UserMembershipsContext';
import ListChangelogPage from 'app/ui/user-manager/user/changelog/ListChangelogPage';
import PaymentDetailsPage from 'app/ui/user-manager/user/payment/PaymentDetailsPage';
import CheckinsPage from 'app/ui/user-manager/user/checkins/CheckinsPage';

const UserPageSubRoutes = () => (
  <Routes>
    <Route path="details" element={<UserDetailsPage />} />
    <Route path="checkins" element={<CheckinsPage />} />
    <Route path="activity" element={<ListChangelogPage />} />
    <Route path="paymentdetails" element={<PaymentDetailsPage />} />
    <Route
      path="memberships"
      element={
        <UserMembershipsContextProvider>
          <UserMembershipsPage />
        </UserMembershipsContextProvider>
      }
    />
    <Route index element={<Navigate to="details" />} />
  </Routes>
);

export default UserPageSubRoutes;
