import React, { useEffect, useState, useContext } from 'react';
import { httpGet } from 'app/service/http';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import LinkedSignupPage from '../../setting-manager/LinkedSignupPage';

export const OfferSignupPageList = ({ offerId, company }) => {
  const [signupPages, setSignupPages] = useState([]);
  const [errorResult, setErrorResult] = useState(null);

  const { executeWithSpinner } = useContext(SpinnerContext);

  useEffect(() => {
    executeWithSpinner(
      httpGet(`/v2/signup-page?offerId=${encodeURIComponent(offerId)}`)
        .then(signupPages => {
          setSignupPages(signupPages);
        })
        .catch(error => {
          setErrorResult(error?.message);
        })
    );
  }, [executeWithSpinner, offerId]);
  return (
    <LinkedSignupPage
      signupPages={signupPages}
      company={company}
      offerId={offerId}
      error={errorResult}
    />
  );
};
