// @flow
import React from 'react';
import type { UserServiceFieldError, UserServiceGeneralError } from 'app/ui/types';

type Props = {
  message: string,
  userServiceFieldErrors: Array<UserServiceFieldError>,
  userServiceGeneralErrors: Array<UserServiceGeneralError>,
};

const SubmitErrors = ({ message, userServiceFieldErrors, userServiceGeneralErrors }: Props) => (
  <ul className="list-group pb-2">
    {message && (
      <li key="message" className="list-group-item list-group-item-danger">
        {message}
      </li>
    )}
    {/*Field Errors*/}
    {userServiceFieldErrors &&
      userServiceFieldErrors.map(fieldError => (
        <li
          key={`${fieldError.code}-${fieldError.field}`}
          className="list-group-item list-group-item-danger"
        >
          {`${fieldError.message || fieldError.defaultMessage}`}
        </li>
      ))}
    {/*General Errors*/}
    {userServiceGeneralErrors &&
      userServiceGeneralErrors.map(generalError => (
        <li key={`${generalError.code}`} className="list-group-item list-group-item-danger">
          {`${generalError.message || generalError.defaultMessage}`}
        </li>
      ))}
  </ul>
);

export default SubmitErrors;
