// @flow
import * as React from 'react';
import OverlayContext from 'app/ui/common/modal/OverlayContext';

type Props = {
  closeButtonText?: string,
};

/**
 * Simple Overlay that just displays a message.
 * The message is associated with a type, such as: Success or Error.
 * The type is used to display the message differently (i.e. green success message vs. red error message).
 *
 * The Overlay has only one button that closes the Overlay and does nothing else.
 */
const Overlay = ({ closeButtonText = 'Close' }: Props) => {
  const { isOpen, message, messageType, hideOverlay } = React.useContext(OverlayContext);
  const modalClassName = `modal fade ${isOpen ? 'show' : ''}`;
  const modalStyle = {
    display: isOpen ? 'block' : 'none',
    backgroundColor: 'rgba(0,0,0,0.3)',
    overflowX: 'hidden',
    overflowY: 'auto',
  };
  const messageClassName = `alert alert-${messageType}`;
  return (
    <div className={modalClassName} tabIndex="-1" role="dialog" style={modalStyle}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body text-center pt-4">
            <div className={messageClassName}>{message}</div>
            <button type="button" className="btn btn-secondary" onClick={() => hideOverlay()}>
              {closeButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overlay;
