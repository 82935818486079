// @flow
import {
  combineValidators,
  validateDate,
  validateLength,
} from 'app/validation/common/commonValidators';
import moment from 'moment';
import {
  VALIDATION_CANCELLATION_WITHOUT_API_CANCELLATION_REASON,
  VALIDATION_CANCELLATION_WITHOUT_END_DATE,
  VALIDATION_INVALID_DATE_ORDER,
  VALIDATION_OVERLAPPING_MEMBERSHIPS,
  VALIDATION_OVERLAPPING_REFERRER_MEMBERSHIPS,
} from 'app/validation/common/errorCodes';
import { isDev } from 'app/utils/env/envUtils';
import type { UserMembershipsState } from 'app/ui/user-manager/user/memberships/reducer';
import { formatISOZonedDateTimeToCETDate } from 'app/utils/format/dateTimeFormatter';
import { httpGet } from 'app/service/http';

const beginningOfTime = () => moment('0001-01-01');

const endOfTime = () => moment('9999-12-12');

/**
 * Checks whether these two memberships overlap.
 *
 * NOTE:
 * The "existingMembership" is an object from the backend having start/end in UTC format: e.g. "2025-11-01T22:59:59Z".
 * The "updatedMembership" is the object currently being edited and has the date in a different format: "dd-mm-yyyy".
 */
const isOverlapping = (existingMembership, updatedMembership) => {
  const start1 = existingMembership.membershipStartTimestamp
    ? moment.tz(existingMembership.membershipStartTimestamp, existingMembership.timeZoneId).utc()
    : beginningOfTime();
  const end1 = existingMembership.membershipEndTimestamp
    ? moment.tz(existingMembership.membershipEndTimestamp, existingMembership.timeZoneId).utc()
    : endOfTime();
  const start2 = updatedMembership.membershipStartTimestamp
    ? moment
        .tz(updatedMembership.membershipStartTimestamp, 'DD-MM-YYYY', existingMembership.timeZoneId)
        .utc()
    : beginningOfTime();
  const end2 = updatedMembership.membershipEndTimestamp
    ? moment
        .tz(updatedMembership.membershipEndTimestamp, 'DD-MM-YYYY', existingMembership.timeZoneId)
        .format()
    : endOfTime();

  const isOk = start2.isAfter(end1) || start1.isAfter(end2);
  return !isOk;
};

export const isMembershipStartTooFarInPast = (dateValue: Object) => {
  if (dateValue) {
    const minValue = moment().add(-2, 'M');
    return minValue.isAfter(dateValue);
  }
  return false;
};

export const isDateTooFarInTime = (dateValue: Object) => {
  if (dateValue) {
    const minValue = moment().add(-3, 'M');
    const maxValue = moment().add(3, 'M');
    return minValue.isAfter(dateValue) || maxValue.isBefore(dateValue);
  }
  return false;
};

/**
 * Validates a membership.
 */
export const validateMembership = (values: any, state: UserMembershipsState) => {
  const errors = { membership: {} };

  // membershipStartTimestamp: required && valid date
  errors.membership.membershipStartTimestamp = combineValidators(
    values.membership.membershipStartTimestamp,
    true
  )(validateDate('DD-MM-YYYY'));

  // membershipEndTimestamp: not required && valid date
  errors.membership.membershipEndTimestamp = combineValidators(
    values.membership.membershipEndTimestamp,
    false
  )(validateDate('DD-MM-YYYY'));

  // employeeIdentifier: required for companies with that have membershipOffer.employeeInternalIdentifierLabel
  const isEmployeeIdentifierRequired = !!(
    values.membershipOfferDto && values.membershipOfferDto.employeeInternalIdentifierLabel
  );
  // employeeIdentifier: max length of 80 characters
  errors.membership.employeeIdentifier = combineValidators(
    values.membership.employeeIdentifier,
    isEmployeeIdentifierRequired
  )(validateLength(0, 80));

  // membershipStartTimestamp < membershipEndTimestamp
  if (!errors.membership.membershipStartTimestamp && !errors.membership.membershipEndTimestamp) {
    const start = moment(values.membership.membershipStartTimestamp, 'DD-MM-YYYY');
    const end = moment(values.membership.membershipEndTimestamp, 'DD-MM-YYYY');
    if (end.isBefore(start)) {
      errors.membership.membershipEndTimestamp = {
        code: VALIDATION_INVALID_DATE_ORDER,
        args: ['Start', 'End'],
      };
    } else {
      // At this point the membership itself is valid.
      // Now check if it overlaps with the other memberships of the user.
      const overlappingMemberships =
        state && state.memberships
          ? state.memberships
              .filter(
                membershipWrapper => membershipWrapper.membership.uuid !== values.membership.uuid
              )
              .filter(
                membershipWrapper =>
                  !membershipWrapper.membership.membershipStartTimestamp ||
                  !membershipWrapper.membership.membershipEndTimestamp ||
                  moment(membershipWrapper.membership.membershipEndTimestamp).isAfter(
                    membershipWrapper.membership.membershipStartTimestamp
                  )
              )
              .filter(membershipWrapper =>
                isOverlapping(membershipWrapper.membership, values.membership)
              )
          : [];

      if (overlappingMemberships && overlappingMemberships.length > 0) {
        errors.membership.membershipStartTimestamp = errors.membership.membershipEndTimestamp = {
          code: VALIDATION_OVERLAPPING_MEMBERSHIPS,
          args: [overlappingMemberships[0].membership.uuid],
        };
      }
    }
  }

  if (!errors.membership.membershipEndTimestamp) {
    const { membership: membershipToValidate } = values;

    if (membershipToValidate.referrerMembershipUuid) {
      const originalMembershipWrapper = state.memberships.find(
        mw => mw.membership.uuid === membershipToValidate.uuid
      );
      const referrerMembership = originalMembershipWrapper
        ? originalMembershipWrapper.referrerMembership
        : null;

      if (referrerMembership && !isReferralEndDateValid(referrerMembership, membershipToValidate)) {
        errors.membership.membershipEndTimestamp = {
          code: VALIDATION_OVERLAPPING_REFERRER_MEMBERSHIPS,
          args: [referrerMembership.uuid],
        };
      }
    }
  }

  if (!errors.membership.membershipCancellationReason) {
    const { membership: membershipToValidate } = values;

    if (
      membershipToValidate.membershipCancellationReason &&
      membershipToValidate.membershipCancellationReason !== 'EMPTY' &&
      !membershipToValidate.membershipEndTimestamp
    ) {
      errors.membership.membershipCancellationReason = {
        code: VALIDATION_CANCELLATION_WITHOUT_END_DATE,
      };
    } else if (
      membershipToValidate.membershipCancellationReason &&
      (membershipToValidate.membershipCancellationReason === 'API_END_OF_EMPLOYMENT' ||
        membershipToValidate.membershipCancellationReason === 'API_LOST_ELIGIBILITY')
    ) {
      errors.membership.membershipCancellationReason = {
        code: VALIDATION_CANCELLATION_WITHOUT_API_CANCELLATION_REASON,
      };
    }
  }

  return errors;
};

export const validateDates = async (values: any, state: UserMembershipsState) => {
  const result = [];
  try {
    for (let i = 0; i < state.memberships.length; i++) {
      const wrapper = state.memberships[i];
      if (wrapper.membership.uuid === values.membership.uuid) {
        if (await validateStartDateForCheckins(values, wrapper)) {
          result.push(
            'Moving memberships start date in future makes it after the date of an existing check-in'
          );
        }
        if (await validateEndDateForCheckins(values, wrapper)) {
          result.push(
            'Moving memberships end date in past makes it before the date of an existing check-in'
          );
        }
      }
    }
  } catch (e) {
    if (isDev) {
      console.error(e);
    }
  }

  return result;
};

const isReferralEndDateValid = (referrerMembership: any, membershipToValidate: any) => {
  if (!membershipToValidate.membershipEndTimestamp && referrerMembership.membershipEndTimestamp) {
    return false;
  }

  return referrerMembership.membershipEndTimestamp
    ? moment(referrerMembership.membershipEndTimestamp, 'YYYY-MM-DD').isSameOrAfter(
        moment(membershipToValidate.membershipEndTimestamp, 'DD-MM-YYYY')
      )
    : true;
};

const validateStartDateForCheckins = async (values: any, wrapper: any) => {
  if (!validateDate()(values.membership.membershipStartTimestamp)) {
    const newStartDate = values.membership.membershipStartTimestamp
      ? values.membership.membershipStartTimestamp
      : '02-01-1970';
    const oldStartDate = wrapper.membership.membershipStartTimestamp
      ? wrapper.membership.membershipStartTimestamp
      : '1970-01-02T15:04:05Z';
    if (
      moment(newStartDate, 'DD-MM-YYYY').isAfter(
        moment(formatISOZonedDateTimeToCETDate(oldStartDate), 'DD-MM-YYYY')
      )
    ) {
      const start = moment(newStartDate, 'DD-MM-YYYY').tz('CET').format('YYYY-MM-DDTHH:mm:ss');
      const checkins = await fetchCheckins(wrapper.user.id, oldStartDate, `${start}Z`);
      return checkins && checkins.size > 0;
    }
  }
  return false;
};

const validateEndDateForCheckins = async (values: any, wrapper: any) => {
  if (!validateDate()(values.membership.membershipEndTimestamp)) {
    const newEndDate = values.membership.membershipEndTimestamp
      ? values.membership.membershipEndTimestamp
      : '02-01-2200';
    const oldEndDate = wrapper.membership.membershipEndTimestamp
      ? wrapper.membership.membershipEndTimestamp
      : '2200-01-02T15:04:05Z';
    if (
      moment(newEndDate, 'DD-MM-YYYY').isBefore(
        moment(formatISOZonedDateTimeToCETDate(oldEndDate), 'DD-MM-YYYY')
      )
    ) {
      const end = moment(newEndDate, 'DD-MM-YYYY').tz('CET').format('YYYY-MM-DDTHH:mm:ss');
      const checkins = await fetchCheckins(wrapper.user.id, `${end}Z`, oldEndDate);
      return checkins && checkins.size > 0;
    }
  }
  return false;
};

export const fetchCheckins = async (userId, startDate, endDate) => {
  const params = [];
  if (startDate) {
    params.push(`start=${startDate}`);
  }

  if (endDate) {
    params.push(`end=${endDate}`);
  }

  const queryParams = params.length ? `?${params.join('&')}` : '';
  return httpGet(`/v1/user/${userId}/checkin/history${queryParams}`);
};

/**
 * Checks whether the input value is a valid 32 bit integer, greater or equal to zero.
 */
export const isPositiveInteger = (value: string): boolean => {
  return value && value >= 0 && value <= 2147483647;
};
