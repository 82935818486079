// @flow
import React from 'react';
import MembershipOfferModal from 'app/ui/common/offer-modal/MembershipOfferModal';

type Props = {
  onRequestClose: Function,
  onSelectOffer: Function,
  wasB2COffer: boolean,
  sfAccountCountryCode: string,
};

const ChangeOfferForMembershipModal = ({
  onRequestClose,
  onSelectOffer,
  wasB2COffer,
  sfAccountCountryCode,
}: Props) => (
  <MembershipOfferModal
    modalTitle="Change Offer"
    confirmButtonText="Save"
    offerSelectionWarningText="IMPORTANT: Please reach our to your manager, to make sure you follow the ops process for this case so that the payment charge request will work."
    offerSelectionInfoText="Are you sure you want to change the offer of this membership?"
    offerSelectionCountryErrorText="You can’t move an existing membership into a different country."
    allowSelectingOfferWithB2CPayment={true}
    onRequestClose={onRequestClose}
    onSelectOffer={onSelectOffer}
    wasB2COffer={wasB2COffer}
    sfAccountCountryCode={sfAccountCountryCode}
  />
);

export default ChangeOfferForMembershipModal;
