// @flow
import * as React from 'react';
import { useCallback, useState } from 'react';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { initialState, reducer } from 'app/ui/payment/list-payments/reducer';
import {
  getChargeStatsSuccess,
  getChargeStatsFailure,
  getFailedChargesSuccess,
  getFailedChargesFailure,
} from 'app/ui/payment/list-payments/actions';
import { DATE_VALIDATION_FAILURE_MESSAGE } from 'app/ui/payment/list-payments/paymentsAlertMessages';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import { formatMoneyAmount } from 'app/utils/format/currencyFormatter';
import moment from 'moment';
import { httpGet, httpPost } from 'app/service/http';
import ChargeStatsCard from './components/ChargeStatsCard';
import FailedChargesTable from './components/FailedChargesTable';
import NewPaymentLinkButton from '../../user-manager/user/NewPaymentLinkButton';
import { notificationService } from '../../../service/notification';
import styles from './ListPaymentsPage.module.scss';

const statusClasses = {
  SUCCEEDED: 'text-success',
  FAILED: 'text-danger',
  CHARGED_BACK: 'text-warning',
};

const ListPaymentsPage = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [period, setPeriod] = useState('');
  const [paymentProvider, setPaymentProvider] = useState('ADYEN');
  const [chargesStatus, setChargesStatus] = useState('');
  const { executeWithSpinner } = React.useContext(SpinnerContext);

  const getCharges = useCallback(
    (period, chargeStatus, provider) => {
      setChargesStatus(chargeStatus);
      httpGet(
        `/v1/payment/charge?period=${period}&status=${chargeStatus}&provider=${provider}&limit=500`
      )
        .then(charges => {
          dispatch(getFailedChargesSuccess(charges.content));
        })
        .catch(error => {
          dispatch(getFailedChargesFailure(error));
        });
    },
    [setChargesStatus]
  );

  const getPayments = useCallback(
    (period, status, provider) => {
      if (period && !moment(period, 'YYYY-MM', true).isValid()) {
        dispatch(getChargeStatsFailure(DATE_VALIDATION_FAILURE_MESSAGE));
        return;
      }

      executeWithSpinner(
        httpGet(`/v1/reporting/charges/stats?period=${period}&provider=${provider}`)
          .then(chargeStats => {
            dispatch(
              getChargeStatsSuccess({
                period,
                ...chargeStats,
              })
            );
          })
          .catch(error => {
            dispatch(getChargeStatsFailure(error));
          }),

        getCharges(period, status, provider)
      );
    },
    [executeWithSpinner, getCharges]
  );

  const onProviderChange = useCallback(
    selectedProvider => {
      setPaymentProvider(selectedProvider);

      if (!period) return;

      if (selectedProvider !== paymentProvider) {
        const formattedPeriod = moment(period, 'MM-YYYY').format('YYYY-MM');
        getPayments(formattedPeriod, 'ERROR', selectedProvider);
      }
    },
    [getPayments, period, paymentProvider]
  );

  const submit = useCallback(
    event => {
      event.preventDefault();
      const formattedPeriod = moment(period, 'MM-YYYY').format('YYYY-MM');
      getPayments(formattedPeriod, 'ERROR', paymentProvider);
    },
    [period, getPayments, paymentProvider]
  );

  const retryChargeActions = {
    ERROR: ({ charge }) => (
      <button
        className="btn btn-primary"
        onClick={() => {
          if (charge) {
            httpPost(`/v1/payment/charge/${charge.chargeId}/trigger`).then(() => {
              getPayments(charge.chargePeriod.month, 'ERROR', paymentProvider);
            });
          }
        }}
        disabled={paymentProvider === 'STRIPE'}
      >
        Retry
      </button>
    ),
    CHARGED_BACK: ({ charge }) => (
      <NewPaymentLinkButton
        chargeRequest={charge}
        onComplete={() => {
          getPayments(charge.chargePeriod.month, 'CHARGED_BACK', paymentProvider);
          notificationService.push({
            message: `Payment link for charge ${charge.chargeId} has been created`,
            variant: 'info',
          });
        }}
      />
    ),
  };

  return (
    <>
      <form onSubmit={submit}>
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <div className="input-group">
                <input
                  id="date"
                  type="text"
                  className="form-control"
                  value={period}
                  placeholder="Period MM-YYYY"
                  onChange={event => setPeriod(event.target.value)}
                />
                <div className="input-group-append">
                  <select
                    id="provider"
                    className={`form-control ${styles.provider}`}
                    onChange={event => onProviderChange(event.target.value)}
                  >
                    <option value={'ADYEN'}>{'Adyen'}</option>
                    <option value={'STRIPE'}>{'Stripe'}</option>
                  </select>
                </div>
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="submit" disabled={!period}>
                    Show B2C Transaction Sums
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      {state.chargeStats && (
        <div className="my-3">
          <h5>Transaction volumes charged in {state.chargeStats.period}: </h5>
          <div className="my-1">
            Total number of transactions:&nbsp;<strong>{state.chargeStats.total.count}</strong>
          </div>
          <div className="my-1">
            Total transaction volume:&nbsp;
            <strong>{formatMoneyAmount(state.chargeStats.total.volume)} </strong>
          </div>
          Status:&nbsp;
          {Object.entries(state.chargeStats.statusCounts).map(([status, count], idx) => (
            <span className="mr-2" key={idx}>
              <strong>{count}</strong> <span className={statusClasses[status]}>{status}</span>
            </span>
          ))}
          <div className="d-flex">
            {Object.entries(state.chargeStats.countries).map(
              ([country, { count, volume }], idx) => (
                <ChargeStatsCard key={idx} country={country} count={count} volume={volume} />
              )
            )}
          </div>
        </div>
      )}
      <div className="mb-3">
        <h5 className="d-inline">Failed payments: </h5>
        {period && (
          <div className="btn-group btn-group-toggle d-inline" data-toggle="buttons">
            <label className={`btn btn-secondary ${chargesStatus === 'ERROR' ? 'active' : ''}`}>
              <input
                type="radio"
                name="options"
                id="errorCharges"
                autoComplete="off"
                onClick={() =>
                  getCharges(moment(period, 'MM-YYYY').format('YYYY-MM'), 'ERROR', paymentProvider)
                }
              />{' '}
              Errors
            </label>
            <label
              className={`btn btn-secondary ${chargesStatus === 'CHARGED_BACK' ? 'active' : ''}`}
            >
              <input
                type="radio"
                name="options"
                id="chargeBack"
                autoComplete="off"
                checked={true}
                onClick={() =>
                  getCharges(
                    moment(period, 'MM-YYYY').format('YYYY-MM'),
                    'CHARGED_BACK',
                    paymentProvider
                  )
                }
              />{' '}
              Charged Back
            </label>
          </div>
        )}
      </div>
      {state.charges && (
        <FailedChargesTable charges={state.charges} action={retryChargeActions[chargesStatus]} />
      )}

      {state.errorMessage && (
        <div className="alert alert-danger mt-4">
          {state.errorMessage.message ? state.errorMessage.message : state.errorMessage}
        </div>
      )}
      {state.emptyMembershipsMessage && (
        <div className="alert alert-dark mt-4">{state.emptyMembershipsMessage}</div>
      )}
    </>
  );
};

export default withPageTemplate(ListPaymentsPage, { headerTitle: 'B2C Payment Overview' });
