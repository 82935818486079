// @flow
import * as React from 'react';
import { Field } from 'react-final-form';
import { resolveErrorCode } from 'app/validation/errorCodeResolver';

import type { UploadFileFieldProps } from 'app/ui/components/types';

const FileUploadFormField = ({
  controlId,
  controlLabel,
  helpTextAfter,
  accept,
  onupdatefiles,
  disabled = false,
}: UploadFileFieldProps) => (
  <Field name={controlId} type="text">
    {({ input, meta }) => {
      const shouldDisplayError = meta.submitError || meta.error;
      return (
        <div className="form-group">
          {controlLabel && (
            <label className="text-primary" htmlFor={controlId}>
              {controlLabel}
            </label>
          )}
          {(input.value.url || input.value.file) && (
            <>
              <div className="company-logo-wrapper">
                <img
                  id={`${controlId}_img`}
                  className="company-logo"
                  src={input.value.url ? input.value.url : URL.createObjectURL(input.value.file)}
                  alt="No files"
                />
              </div>
              {input.value.url && (
                <a href={input.value.url} rel="noopener noreferrer" target="_blank">
                  {input.value.url}
                </a>
              )}
            </>
          )}
          <input
            id={controlId}
            className={`form-control${shouldDisplayError ? ' is-invalid' : ''}`}
            type="file"
            accept={accept}
            onChange={onupdatefiles}
            disabled={disabled}
          />
          {helpTextAfter && (
            <small className={`${controlId}-help-text-after`}>{helpTextAfter}</small>
          )}
          {shouldDisplayError && (
            <div className="invalid-feedback">
              {resolveErrorCode(meta.submitError || meta.error, controlLabel)}
            </div>
          )}
        </div>
      );
    }}
  </Field>
);

export default FileUploadFormField;
