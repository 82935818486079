// @flow
import {
  START_CREATING_NEW_SIGN_UP_PAGE,
  SAVE_NEW_SIGN_UP_PAGE_SUCCESS,
  START_EDITING_EXISTING_SIGN_UP_PAGE,
  SAVE_EXISTING_SIGN_UP_PAGE_SUCCESS,
} from 'app/ui/companies/self-signup-manager/actions';
import type { SignupPage, BaseAction, SignupPageFrontendModel } from 'app/ui/types';
import { convertSignupPageToSignupPageFrontendModel } from 'app/ui/companies/self-signup-manager/converter';

export const INIT_STATE = 'INIT_STATE';
export const CREATE_SIGN_UP_PAGE_STATE = 'CREATE_SIGN_UP_PAGE_STATE';
export const EDIT_SIGN_UP_PAGE_STATE = 'EDIT_SIGN_UP_PAGE_STATE';

type State = {
  pageState: string,
  signupPageModel: ?SignupPageFrontendModel,
  errorMessage?: string,
};

type Action = {
  signupPage?: SignupPage,
  errorMessage?: string,
};

export const initialState = {
  pageState: INIT_STATE,
};

export const reducer = (state: State, action: BaseAction & Action): State => {
  if (action.type === START_CREATING_NEW_SIGN_UP_PAGE) {
    return {
      ...state,
      pageState: CREATE_SIGN_UP_PAGE_STATE,
      signupPageModel: {
        ...state.signupPageModel,
        ...convertSignupPageToSignupPageFrontendModel(action.signupPage),
      },
    };
  } else if (action.type === SAVE_NEW_SIGN_UP_PAGE_SUCCESS) {
    return {
      ...state,
      pageState: EDIT_SIGN_UP_PAGE_STATE,
      signupPageModel: {
        ...state.signupPageModel,
        ...convertSignupPageToSignupPageFrontendModel(action.signupPage),
      },
    };
  } else if (action.type === START_EDITING_EXISTING_SIGN_UP_PAGE) {
    //try to clear the input if it exists
    if (document.getElementById('logo')) {
      document.getElementById('logo').value = '';
    }
    return {
      ...state,
      pageState: EDIT_SIGN_UP_PAGE_STATE,
      signupPageModel: {
        ...state.signupPageModel,
        ...convertSignupPageToSignupPageFrontendModel(action.signupPage),
      },
    };
  } else if (action.type === SAVE_EXISTING_SIGN_UP_PAGE_SUCCESS) {
    return {
      ...state,
      pageState: EDIT_SIGN_UP_PAGE_STATE,
      signupPageModel: {
        ...state.signupPageModel,
        ...convertSignupPageToSignupPageFrontendModel(action.signupPage),
      },
    };
  } else {
    // default (initial state)
    return {
      ...state,
      pageState: INIT_STATE,
      signupPageModel: undefined,
      errorMessage: undefined,
    };
  }
};
