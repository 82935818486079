// @flow
/**
 * Extracts the value for the given request parameter.
 * @param parameterName the name of the parameter
 * @returns {string} the value of the parameter, or null if there is no such request parameter
 */
export const extractRequestParameter = (parameterName: string): ?string => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(parameterName);
};

export const simpleMemoize = fn => {
  let lastResult;
  return () => {
    if (!lastResult) {
      lastResult = fn();
    }
    return lastResult;
  };
};
