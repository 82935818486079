import React, { useContext, useEffect, useCallback } from 'react';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import { formatISOZonedDateTimeToCETDate } from 'app/utils/format/dateTimeFormatter';
import { exportMembershipsMovedInfo } from './MembershipsMovedCsvExporter';
import MoveMembershipsContext from './MoveMembershipsContext';

type CancelAllMembershipsHeaderRowType = {
  propertyName: string,
  salesForceValue: string,
};

const CancelAllMembershipsHeaderRow = ({
  propertyName,
  salesForceValue,
}: CancelAllMembershipsHeaderRowType) => (
  <div className="row">
    <div className="col-3 text-primary">{propertyName}</div>
    <div className="col-5">{salesForceValue}</div>
  </div>
);

const MoveMembershipsExportPage = () => {
  const { moveMembershipsDto } = useContext(MoveMembershipsContext);
  const movedMemberships = moveMembershipsDto?.moveMembershipOfferInfo || [];

  const exportCSV = useCallback(movedMemberships => {
    movedMemberships.length && exportMembershipsMovedInfo(movedMemberships);
  }, []);

  useEffect(() => {
    exportCSV(movedMemberships);
  });

  return (
    <>
      <div className="mb-4">
        <div className="row">
          <div className="col-3">&nbsp;</div>
          <div className="col-5 text-primary">Salesforce</div>
        </div>
        <CancelAllMembershipsHeaderRow
          propertyName="Salesforce Canonical Account ID: "
          salesForceValue={moveMembershipsDto?.sfAccountCanonicalID}
        />
        <CancelAllMembershipsHeaderRow
          propertyName="Company Name: "
          salesForceValue={moveMembershipsDto?.companyName}
        />
        <CancelAllMembershipsHeaderRow
          propertyName="Address: "
          salesForceValue={moveMembershipsDto?.companyAddress}
        />
      </div>

      <h2 className="text-primary">
        Number of moved memberships: {moveMembershipsDto?.totalMembershipsMoved}
      </h2>
      <br />
      <div className="form-group form-row" style={{ justifyContent: 'flex-end' }}>
        <button
          className=" btn btn-secondary mb-3"
          type="button"
          onClick={() => exportCSV(movedMemberships)}
        >
          Export list as csv
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last name</th>
            <th>Old Membership start date</th>
            <th>Old Membership end date</th>
            <th>Old Offer ID</th>
            <th>New Membership start date</th>
            <th>New Membership end date</th>
            <th>New Offer ID</th>
            <th>Email</th>
            <th>Identifiers</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {moveMembershipsDto &&
            movedMemberships.map(membership => (
              <tr key={membership.newMembershipId}>
                <td>{membership.firstName}</td>
                <td>{membership.lastName}</td>
                <td>{formatISOZonedDateTimeToCETDate(membership.oldMembershipStartDate)}</td>
                <td>{formatISOZonedDateTimeToCETDate(membership.oldMembershipEndDate)}</td>
                <td>{membership.oldOfferId}</td>
                <td>{formatISOZonedDateTimeToCETDate(membership.newMembershipStartDate)}</td>
                <td>{formatISOZonedDateTimeToCETDate(membership.newMembershipEndDate)}</td>
                <td>{membership.newOfferId}</td>
                <td>{membership.email}</td>
                <td>
                  <strong>UserId: </strong>
                  {membership.userId} <strong>MembershipId: </strong>
                  {membership.newMembershipId}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default withPageTemplate(MoveMembershipsExportPage, {
  headerTitle: 'Memberships Moved',
});
