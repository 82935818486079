// @flow
import type { MembershipOfferSignupPagesWrapperDto, MembershipWrapperType } from 'app/ui/types';

const actionsPrefix = 'USER_MANAGER@USER@MEMBERSHIP@';

/** Action types. */
export const MEMBERSHIPS_LOADING = `${actionsPrefix}MEMBERSHIPS_LOADING`;
export const MEMBERSHIPS_LOAD_SUCCESS = `${actionsPrefix}MEMBERSHIPS_LOAD_SUCCESS`;
export const MEMBERSHIPS_LOAD_FAILURE = `${actionsPrefix}MEMBERSHIPS_LOAD_FAILURE`;

export const START_EDITING_MEMBERSHIP = `${actionsPrefix}START_EDITING_MEMBERSHIP`;
export const CANCEL_EDITING_MEMBERSHIP = `${actionsPrefix}CANCEL_EDITING_MEMBERSHIP`;

export const SAVING_MEMBERSHIP = `${actionsPrefix}SAVING_MEMBERSHIP`;
export const MEMBERSHIP_SAVE_SUCCESS = `${actionsPrefix}MEMBERSHIP_SAVE_SUCCESS`;
export const MEMBERSHIP_OFFER_SAVE_SUCCESS = `${actionsPrefix}MEMBERSHIP_OFFER_SAVE_SUCCESS`;

export const START_CHANGING_MEMBERSHIP_OFFER = `${actionsPrefix}START_CHANGING_MEMBERSHIP_OFFER`;
export const FINISH_CHANGING_MEMBERSHIP_OFFER = `${actionsPrefix}FINISH_CHANGING_MEMBERSHIP_OFFER`;

// New Membership
export const START_SELECTING_OFFER_FOR_NEW_MEMBERSHIP = `${actionsPrefix}START_SELECTING_OFFER_FOR_NEW_MEMBERSHIP`;
export const FINISH_SELECTING_OFFER_FOR_NEW_MEMBERSHIP = `${actionsPrefix}FINISH_SELECTING_OFFER_FOR_NEW_MEMBERSHIP`;
export const CANCEL_SELECTING_OFFER_FOR_NEW_MEMBERSHIP = `${actionsPrefix}CANCEL_SELECTING_OFFER_FOR_NEW_MEMBERSHIP`;
export const NEW_MEMBERSHIP_SAVE_SUCCESS = `${actionsPrefix}NEW_MEMBERSHIP_SAVE_SUCCESS`;
export const CANCEL_NEW_MEMBERSHIP = `${actionsPrefix}CANCEL_NEW_MEMBERSHIP`;

// Delete Membership
export const MEMBERSHIP_REVOKE_FAILURE = `${actionsPrefix}MEMBERSHIP_REVOKE_FAILURE`;
export const EMPLOYEE_UNLINKING_FAILURE = `${actionsPrefix}EMPLOYEE_UNLINKING_FAILURE`;

export const OPEN_EDIT_CHILD_MEMBERSHIP_MODAL = `${actionsPrefix}OPEN_EDIT_CHILD_MEMBERSHIP_MODAL`;
export const CLOSE_EDIT_CHILD_MEMBERSHIP_MODAL = `${actionsPrefix}CLOSE_EDIT_CHILD_MEMBERSHIP_MODAL`;

/** Actions creators. */
export const membershipsLoading = userId => ({
  type: MEMBERSHIPS_LOADING,
  userId: userId,
});

export const membershipsLoadSuccess = (memberships: Array<MembershipWrapperType>) => ({
  type: MEMBERSHIPS_LOAD_SUCCESS,
  memberships: memberships,
});

export const membershipsLoadFailure = (error: any) => ({
  type: MEMBERSHIPS_LOAD_FAILURE,
  error: error,
});

export const startEditingMembership = (membershipUUID: string) => ({
  type: START_EDITING_MEMBERSHIP,
  membershipUUID: membershipUUID,
});

export const cancelEditingMembership = (membershipUUID: string) => ({
  type: CANCEL_EDITING_MEMBERSHIP,
  membershipUUID: membershipUUID,
});

export const startChangingMembershipOffer = (
  membershipUUID: string,
  b2cPayment: boolean,
  sfAccountCountryCode: string
) => ({
  type: START_CHANGING_MEMBERSHIP_OFFER,
  membershipUUID: membershipUUID,
  b2cPayment: b2cPayment,
  sfAccountCountryCode: sfAccountCountryCode,
});

export const finishChangingMembershipOffer = (membershipUUID: string) => ({
  type: FINISH_CHANGING_MEMBERSHIP_OFFER,
});

export const savingMembership = () => ({
  type: SAVING_MEMBERSHIP,
});

export const membershipSaveSuccess = (membership: MembershipWrapperType) => ({
  type: MEMBERSHIP_SAVE_SUCCESS,
  membership: membership,
});

export const membershipOfferSaveSuccess = (membership: MembershipWrapperType) => ({
  type: MEMBERSHIP_OFFER_SAVE_SUCCESS,
  membership: membership,
});

/* Select Offer for the new Membership */

export const startSelectingOfferForNewMembership = () => ({
  type: START_SELECTING_OFFER_FOR_NEW_MEMBERSHIP,
});

export const finishSelectingOfferForNewMembership = (
  membershipOfferSignupPagesWrapperDto: MembershipOfferSignupPagesWrapperDto
) => ({
  type: FINISH_SELECTING_OFFER_FOR_NEW_MEMBERSHIP,
  membershipOfferSignupPagesWrapperDto: membershipOfferSignupPagesWrapperDto,
});

export const cancelSelectingOfferForNewMembership = () => ({
  type: CANCEL_SELECTING_OFFER_FOR_NEW_MEMBERSHIP,
});

/* New Membership */

export const cancelNewMembership = () => ({
  type: CANCEL_NEW_MEMBERSHIP,
});

export const newMembershipSaveSuccess = (membership: MembershipWrapperType) => ({
  type: NEW_MEMBERSHIP_SAVE_SUCCESS,
  membership: membership,
});

export const membershipRevokeFailure = (error: any) => ({
  type: MEMBERSHIP_REVOKE_FAILURE,
  error: error,
});

export const openEditChildMembershipModal = (childMembershipUserId: string) => ({
  type: OPEN_EDIT_CHILD_MEMBERSHIP_MODAL,
  childMembershipUserId: childMembershipUserId,
});

export const closeEditChildMembershipModal = () => ({
  type: CLOSE_EDIT_CHILD_MEMBERSHIP_MODAL,
});

export const employeeUnlinkingFailure = (error: any) => ({
  type: EMPLOYEE_UNLINKING_FAILURE,
  error: error,
});
