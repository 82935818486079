import React from 'react';
import wellpassLogoDark from 'app/styles/img/wellpass-logo-dark.svg';
import wellpassLogoLight from 'app/styles/img/wellpass-logo-white.svg';
import styles from './WellpassLogo.module.scss';

type Props = {
  variant?: 'light' | 'dark',
  centered?: boolean,
};
const WellpassLogo = ({ variant, centered = false }: Props) => (
  <div
    className={`${centered ? `d-flex align-items-center mx-auto ${styles.fitContent}` : ''} ${
      styles.logo
    }`}
  >
    <img src={variant === 'light' ? wellpassLogoLight : wellpassLogoDark} alt="Company Logo" />
  </div>
);

export default WellpassLogo;
