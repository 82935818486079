import React, { useEffect, useState } from 'react';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import { httpGet } from '../../../service/http';

type Props = {
  onCancel: Function,
  loadNextPage: Function,
  sfAccountCanonicalId: string,
  companyName: string,
};

const CompanyDifferenceModal = ({
  onCancel,
  loadNextPage,
  sfAccountCanonicalId,
  companyName,
  newSfAccountCanonicalId,
}: Props) => {
  const [newCompanyName, setNewCompanyName] = useState('');

  useEffect(() => {
    httpGet(`/v1/salesforce/company?sfAccountCanonicalId=${newSfAccountCanonicalId}&minified=true`)
      .then(companyInformation => setNewCompanyName(companyInformation.Name))
      .catch(() => {});
  }, [newSfAccountCanonicalId]);

  return (
    <>
      <ConfirmationModal
        isOpen={true}
        modalTitle="WARNING: Different SF Account ID"
        yesButtonText="This is correct"
        noButtonText="Back"
        onConfirm={loadNextPage}
        onCancel={onCancel}
      >
        <div className="form-group">
          <p className="text-black-50">
            The old offer ID belongs to a different Salesforce Account than the new offer ID!
          </p>
          <p className="text-black-50">
            <strong>new offer SF account: </strong> {newSfAccountCanonicalId} | {newCompanyName}{' '}
            <br />
            <strong>old offer SF account: </strong> {sfAccountCanonicalId} | {companyName}
          </p>
          <p className="text-black-50">
            Please verify that this is correct. Your action can't be undone.
          </p>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default CompanyDifferenceModal;
