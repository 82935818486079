// @flow
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { SpinnerProvider } from 'app/ui/common/spinner/SpinnerContext';
import Spinner from 'app/ui/common/spinner/Spinner';
import { AuthenticationProvider } from 'app/ui/common/authentication/AuthenticationContext';
import { OverlayProvider } from 'app/ui/common/modal/OverlayContext';
import Overlay from 'app/ui/common/modal/Overlay';
import DefaultErrorBoundary from './ui/error/DefaultErrorBoundary';
import appRouter from './ui/layout/AppRoutes';
import { EmojiRainProvider } from './ui/components/emoji-rain/EmojiRainContext';
import { MoveMembershipsProvider } from './ui/companies/move-all-memberships/MoveMembershipsContext';

const App = () => (
  <DefaultErrorBoundary>
    <AuthenticationProvider>
      <SpinnerProvider>
        <EmojiRainProvider>
          <MoveMembershipsProvider>
            <OverlayProvider>
              <RouterProvider router={appRouter} />
              <Overlay />
            </OverlayProvider>
          </MoveMembershipsProvider>
        </EmojiRainProvider>
        <Spinner />
      </SpinnerProvider>
    </AuthenticationProvider>
  </DefaultErrorBoundary>
);

export default App;
