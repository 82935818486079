// @flow
import React from 'react';
import debounce from 'debounce';

const useDebouncedCallback = (callback, wait, dependencies) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFunction = React.useCallback(debounce(callback, wait), dependencies);
  return [debouncedFunction];
};

export default useDebouncedCallback;
