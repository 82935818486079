// @flow
import {
  formatISOZonedDateTimeToCETDate,
  formatISOZonedDateTimeToUTCDateTime,
  formatISODateToDate,
} from 'app/utils/format/dateTimeFormatter';
import { generateCsv } from 'app/utils/csv/csvUtil';
import { downloadCsvFile } from 'app/utils/file/fileUtil';

export const MembershipsExportType = {
  COUNTS: 'COUNTS',
  LIST: 'LIST',
  LIST_WITH_HRIS: 'LIST_WITH_HRIS',
};

const membershipExportList = [
  { headerLabel: 'qualitrain user ID', properties: ['user.id'] },
  { headerLabel: 'gender', properties: ['user.gender'] },
  { headerLabel: 'user first name', properties: ['user.firstName'] },
  { headerLabel: 'last name', properties: ['user.lastName'] },
  { headerLabel: 'email address', properties: ['user.email'] },
  {
    headerLabel: 'birthday',
    properties: ['user.birthday'],
    transform: formatISODateToDate,
  },
  { headerLabel: 'street', properties: ['user.addressDto.street'] },
  { headerLabel: 'street number', properties: ['user.addressDto.houseNumber'] },
  { headerLabel: 'zip code', properties: ['user.addressDto.zipCode'] },
  { headerLabel: 'city', properties: ['user.addressDto.city'] },
  {
    headerLabel: 'personal identifier label',
    properties: ['membershipOfferDto.employeeInternalIdentifierLabel'],
  },
  { headerLabel: 'user personal identifier', properties: ['membership.employeeIdentifier'] },
  { headerLabel: 'plus1 referrer', properties: ['membership.referrerMembershipUuid'] },
  { headerLabel: 'activation code', properties: ['user.activationCode'] },
  { headerLabel: 'membership ID', properties: ['membership.uuid'] },
  {
    headerLabel: 'membership start date',
    properties: ['membership.membershipStartTimestamp'],
    transform: formatISOZonedDateTimeToCETDate,
  },
  {
    headerLabel: 'membership end date',
    properties: ['membership.membershipEndTimestamp'],
    transform: formatISOZonedDateTimeToCETDate,
  },
  { headerLabel: 'cancellation reason', properties: ['membership.membershipCancellationReason'] },
  {
    headerLabel: 'cancellation timestamp',
    properties: ['membership.membershipEndChangeTimestamp'],
    transform: formatISOZonedDateTimeToUTCDateTime,
  },
  {
    headerLabel: 'membership creation timestamp',
    properties: ['membership.creationTimestamp'],
    transform: formatISOZonedDateTimeToUTCDateTime,
  },
  { headerLabel: 'offer ID', properties: ['membershipOfferDto.id'] },
  {
    headerLabel: 'salesforce canonical ID',
    properties: ['membershipOfferDto.sfAccountCanonicalId'],
  },
  { headerLabel: 'related signup pages', properties: ['relatedSignupPageNames'] },
  {
    headerLabel: 'membership offer price',
    properties: ['membershipOfferDto.priceAmount', 'membershipOfferDto.priceCurrency'],
    transform: (a, b) => `${a} ${b}`,
  },
  { headerLabel: 'B2C payment', properties: ['membershipOfferDto.b2cPayment'] },
  { headerLabel: 'offer type', properties: ['membershipOfferDto.type'] },
];

const membershipExportWithHrisList = membershipExportList.concat([
  { headerLabel: 'HRIS user ID', properties: ['corporateEmployee.id'] },
  { headerLabel: 'HRIS First Name', properties: ['corporateEmployee.firstName'] },
  { headerLabel: 'HRIS Last Name', properties: ['corporateEmployee.lastName'] },
  {
    headerLabel: 'HRIS Date of Birth',
    properties: ['corporateEmployee.birthday'],
    transform: formatISODateToDate,
  },
  { headerLabel: 'HRIS Employment Type', properties: ['corporateEmployee.employmentType'] },
  { headerLabel: 'HRIS Employment Status', properties: ['corporateEmployee.employmentStatus'] },
  { headerLabel: 'HRIS Legal Entity ID', properties: ['corporateEmployee.legalEntityId'] },
  { headerLabel: 'HRIS Work Location ID', properties: ['corporateEmployee.workLocationId'] },
]);

const membershipExportWithCountsList = [
  { headerLabel: 'salesforce canonical ID', properties: ['sfAccountCanonicalId'] },
  { headerLabel: '# of active members', properties: ['totalMembershipCount'] },
];

const COLUMN_DEFINITIONS = {
  [MembershipsExportType.LIST]: membershipExportList,
  [MembershipsExportType.LIST_WITH_HRIS]: membershipExportWithHrisList,
  [MembershipsExportType.COUNTS]: membershipExportWithCountsList,
};

export const generateMembershipsCsv = (type: string, memberships: Array<Object>) => {
  const columns = COLUMN_DEFINITIONS[type];
  if (!columns) {
    throw new Error(`export type ${type} is not defined`);
  }
  return generateCsv(COLUMN_DEFINITIONS[type], memberships);
};

export const exportMembershipsToCsv = (
  type: string,
  memberships: Array<Object>,
  fileNameSuffix: string
) => {
  const csv = generateMembershipsCsv(type, memberships);
  downloadCsvFile(csv, `members_${fileNameSuffix}.csv`);
};
