// @flow
import * as React from 'react';
import { initialValue, reducer } from 'app/ui/user-manager/user/memberships/reducer';
import type { UserMembershipsState } from 'app/ui/user-manager/user/memberships/reducer';

type Props = {
  children: any,
};
type State = {
  state: UserMembershipsState,
  dispatch: Function,
};

const UserMembershipsContext = React.createContext<State>({ state: initialValue });

const UserMembershipsContextProvider = ({ children }: Props) => {
  const [state, dispatch] = React.useReducer(reducer, initialValue);

  return (
    <UserMembershipsContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </UserMembershipsContext.Provider>
  );
};

export { UserMembershipsContextProvider };
export default UserMembershipsContext;
