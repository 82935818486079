// @flow
import * as React from 'react';
import type { Gym, SalesForceGym } from 'app/ui/types';

type Props = {
  children: any,
};
type State = {
  gym: Gym,
  salesForceGym: SalesForceGym,
  sfErrorMsg: string,
};

const GymContext = React.createContext<State>({ state: {} });

const GymContextProvider = ({ children }: Props) => {
  const [gym, setGym] = React.useState(undefined);
  const [salesForceGym, setSalesForceGym] = React.useState(undefined);
  const [sfErrorMsg, setSfErrorMsg] = React.useState(undefined);

  return (
    <GymContext.Provider
      value={{
        gym: gym,
        salesForceGym: salesForceGym,
        sfErrorMsg: sfErrorMsg,
        setGym: setGym,
        setSalesForceGym: setSalesForceGym,
        setSfErrorMsg: setSfErrorMsg,
      }}
    >
      {children}
    </GymContext.Provider>
  );
};

export { GymContextProvider };
export default GymContext;
