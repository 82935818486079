// @flow
export const getCookie = (name: string): ?string => {
  const namePrefix = `${name}=`;
  const cookiesArray = document.cookie.split(';');
  const filteredCookies = cookiesArray
    .map(cookie => cookie.trim())
    .filter(cookie => cookie.indexOf(namePrefix) === 0);

  return filteredCookies && filteredCookies.length > 0
    ? filteredCookies[0].substring(namePrefix.length)
    : undefined;
};
