// @flow
import * as React from 'react';
import { Field } from 'react-final-form';
import { resolveErrorCode } from 'app/validation/errorCodeResolver';

import type { FormFieldProps } from 'app/ui/components/types';

const DropdownFormField = ({
  controlId,
  controlLabel,
  helpTextAfter,
  options,
  disabled = false,
}: FormFieldProps) => (
  <Field name={controlId}>
    {({ input, meta }) => {
      const shouldDisplayError = meta.submitError || (meta.touched && meta.error);
      return (
        <div className="form-group">
          {controlLabel && (
            <label className="text-primary" htmlFor={controlId}>
              {controlLabel}
            </label>
          )}
          <select
            {...input}
            className={`form-control${shouldDisplayError ? ' is-invalid' : ''}`}
            aria-describedby={`${controlId}-help-text-after`}
            disabled={disabled}
          >
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          {helpTextAfter && (
            <small className={`${controlId}-help-text-after`}>{helpTextAfter}</small>
          )}
          {shouldDisplayError && (
            <div className="invalid-feedback d-block">
              {resolveErrorCode(meta.submitError || meta.error, controlLabel)}
            </div>
          )}
        </div>
      );
    }}
  </Field>
);

export default DropdownFormField;
