// @flow
import React from 'react';

type Props = {
  countSuccessfulUploads: number,
  countErrorUploads: number,
  getTotalUploads: Function,
};

const PaperlistCheckinsUploadProgress = ({
  countSuccessfulUploads,
  countErrorUploads,
  getTotalUploads,
}: Props) => (
  <div className="row">
    <div className="col col-sm-6 col-xl-4">
      <table className="table table-sm table-bordered">
        <tbody>
          <tr>
            <td>Upload Progress</td>
            <td className="text-black-50 text-right">
              {countSuccessfulUploads + countErrorUploads} / {getTotalUploads()}
            </td>
          </tr>
          <tr>
            <td>Successfully uploaded Check-ins </td>
            <td className="text-success text-right">{countSuccessfulUploads}</td>
          </tr>
          <tr>
            <td>Failed Check-ins</td>
            <td className="text-danger text-right">{countErrorUploads}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default PaperlistCheckinsUploadProgress;
