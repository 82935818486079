/**
 * Extracts last segment from url path
 *
 * @param fullPath
 * @returns {string}
 * @constructor
 */
export const ExtractLastPathSegment = (fullPath: string) => {
  const path = fullPath.replace(/\/$/, ''); // Replace trailing slash from path
  return path.substring(path.lastIndexOf('/') + 1);
};
