// @flow
const actionPrefix = 'LIST_MEMBERSHIPS@';

/*** Action Types ***/
export const GET_CHARGE_STATS_SUCCESS = `${actionPrefix}GET_PAYMENTS_SUCCESS`;
export const GET_CHARGE_STATS_FAILURE = `${actionPrefix}GET_PAYMENTS_FAILURE`;
export const GET_FAILED_CHARGES_SUCCESS = `${actionPrefix}GET_CHARGES_SUCCESS`;
export const GET_FAILED_CHARGES_FAILURE = `${actionPrefix}GET_CHARGES_FAILURE`;

/*** Action Creators ***/
export const getChargeStatsSuccess = (chargeStats: Object) => ({
  type: GET_CHARGE_STATS_SUCCESS,
  chargeStats: chargeStats,
});

export const getChargeStatsFailure = (errorMessage: string) => ({
  type: GET_CHARGE_STATS_FAILURE,
  errorMessage: errorMessage,
});

export const getFailedChargesSuccess = (charges: Object) => ({
  type: GET_FAILED_CHARGES_SUCCESS,
  charges: charges,
});

export const getFailedChargesFailure = (errorMessage: string) => ({
  type: GET_FAILED_CHARGES_FAILURE,
  errorMessage: errorMessage,
});
