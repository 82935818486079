// @flow
import React from 'react';

type Props = {
  onClick?: Function,
};

const PlusIcon = ({ onClick }: Props) => (
  <button className="text-primary border-0 py-0 w-100" onClick={onClick}>
    <i className="fas fa-plus fa-lg" />
  </button>
);

export default PlusIcon;
