// @flow
import * as React from 'react';
import { useCallback, useState } from 'react';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import { httpGet } from 'app/service/http/index';
import { notificationService } from 'app/service/notification';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { Link } from 'react-router-dom';
import MultiEntitySignupPagesResult from './MultiEntitySignupPagesResult';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

const MultiEntitySignupPagesSearch = () => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [limit] = useState(10);

  const [multiEntitySignupPages, setMultiEntitySignupPages] = useState([]);
  const [term, setTerm] = useState('');
  const [previousSearchTerm, setPreviousSearchTerm] = useState('');
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [errorResult, setErrorResult] = useState(null);
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  const buildQueryParamString = (term, limit, offset) => {
    const queryParams = [];
    queryParams.push(`limit=${encodeURIComponent(limit)}`);

    if (term) {
      queryParams.push(`q=${encodeURIComponent(term)}`);
    }

    if (offset) {
      queryParams.push(`offset=${encodeURIComponent(offset)}`);
    }

    return queryParams.join('&');
  };

  const setErrorMessage = useCallback(errorMessage => {
    notificationService.push({
      message: errorMessage,
      variant: 'error',
    });
  }, []);

  const populateErrorMessage = useCallback(
    error => {
      error && error.message
        ? setErrorMessage(error.message)
        : setErrorMessage(JSON.stringify(error));
    },
    [setErrorMessage]
  );

  const searchByTerm = useCallback(
    (offset, pages) => {
      if (term.length === 0) {
        setErrorResult('Please fill the search criteria');
        return;
      }

      setShowLoadMore(true);

      if (previousSearchTerm !== term) {
        setOffset(0);
        setMultiEntitySignupPages([]);
      }

      setPreviousSearchTerm(term);
      const queryParameters = buildQueryParamString(term, limit, offset);

      executeWithSpinner(
        httpGet(`/v1/multi-entity-signup-page?${queryParameters}`)
          .then(responseData => {
            if (responseData.length === 0) {
              setShowLoadMore(false);
              return;
            }

            setOffset(offset + responseData.length);
            setMultiEntitySignupPages([...pages, ...responseData]);
          })
          .catch(error => {
            populateErrorMessage(error);
          })
      );
    },
    [executeWithSpinner, limit, populateErrorMessage, previousSearchTerm, term]
  );

  const onSubmit = useCallback(
    event => {
      event.preventDefault();
      searchByTerm(0, []);
    },
    [searchByTerm]
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-group form-row">
          <div className="col offset-8">
            {isEditable && (
              <Link className="btn btn-secondary" style={{ width: '100%' }} to={`..`}>
                Create new page
              </Link>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <div className="input-group">
                <input
                  id="term"
                  type="text"
                  className="form-control"
                  value={term}
                  placeholder="Page name"
                  onChange={event => setTerm(event.target.value)}
                />
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <MultiEntitySignupPagesResult pages={multiEntitySignupPages} errorResult={errorResult} />

      <div className="text-right mb-3">
        {showLoadMore && multiEntitySignupPages.length > 0 && (
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => searchByTerm(offset, multiEntitySignupPages)}
          >
            Load more
          </button>
        )}
      </div>
    </>
  );
};

export default withPageTemplate(MultiEntitySignupPagesSearch, {
  headerTitle: 'Multi-Entity Signup Pages Search',
});
