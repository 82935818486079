import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { httpGet, httpPost } from 'app/service/http/index';
import AddMembersForm from 'app/ui/companies/add-members/AddMembersForm';
import { parseBirthDateField, normaliseValidateDate } from './AddMembersUtils';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const AddMembersManager = () => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [offerInfo, setOfferInfo] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [signupPageUrls, setSignupPageUrls] = useState(null);
  const [signupPageNames, setSignupPageNames] = useState(null);
  const [members, setMembers] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [edited, setEdited] = useState(false);
  const [errorResult, setErrorResult] = useState(null);
  const [counter, setCounter] = useState({ total: 0, failure: 0 });
  const [isMultipleOffersUpload, setIsMultipleOffersUpload] = useState(false);
  const location = useLocation();

  const getSalesForceCompanyName = useCallback(async sfAccountCanonicalId => {
    try {
      const sailsForceResponse = await httpGet(
        `/v1/salesforce/company?sfAccountCanonicalId=${sfAccountCanonicalId}&minified=true`
      );
      setCompanyName(sailsForceResponse.Name);
    } catch (error) {
      setErrorResult(
        `${
          'Could not retrieve company from salesforce. ' +
          'The activation code letters cannot be generated. '
        }${error && error.message}`
      );
    }
  }, []);

  const getMembershipOfferSignupPageWrapper = useCallback(async offerId => {
    try {
      return await httpGet(`/v1/signup-page?offerId=${offerId}`, {});
    } catch (error) {
      setErrorResult(error && error.message);
    }
  }, []);

  const searchForOfferId = useCallback(
    async offerId => {
      const wrapper = await getMembershipOfferSignupPageWrapper(offerId);

      if (!errorResult && wrapper) {
        setOfferInfo(wrapper.membershipOfferDto);

        if (wrapper.signupPageDtos != null) {
          const signupPageUrlArray = wrapper.signupPageDtos.map(
            dto =>
              `${window._env_.REACT_APP_QUALITRAIN_USER_FRONTEND_BASE_URL}/signup?signupToken=${dto.token}`
          );
          setSignupPageUrls(signupPageUrlArray.join(', '));

          const signupPageNameArray = wrapper.signupPageDtos.map(dto => dto.name);
          setSignupPageNames(signupPageNameArray.join(', '));

          await getSalesForceCompanyName(wrapper.membershipOfferDto.sfAccountCanonicalId);
        }
      }
    },
    [errorResult, getMembershipOfferSignupPageWrapper, getSalesForceCompanyName]
  );

  useEffect(() => {
    const paramOfferId = new URLSearchParams(location.search.substring(1)).get('membershipOfferId');

    if (paramOfferId) {
      executeWithSpinner(searchForOfferId(paramOfferId));
    } else {
      if (!isMultipleOffersUpload) {
        setOfferInfo(null);
        setMembers(null);
        setErrorResult(null);
      }
      setIsMultipleOffersUpload(true);
    }
  }, [location.search, executeWithSpinner, isMultipleOffersUpload, searchForOfferId]);

  const membersOnChange = event => {
    setMembers(null);
    setCounter({ total: 0, failure: 0 });

    const files = event.target.files;
    const file = files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
      processMembers(event);
    };
    reader.readAsText(file);
  };

  const processMembers = event => {
    const lines = event.target.result.split('\n');

    if (!isMembersCsvValid) {
      return;
    }

    if (!isMultipleOffersUpload) {
      validateCompanyNameExistence();
    }

    const identifierLabel =
      !isMultipleOffersUpload && offerInfo && offerInfo.employeeInternalIdentifierLabel
        ? offerInfo.employeeInternalIdentifierLabel.trim()
        : null;
    const requiredNumberOfFields = isMultipleOffersUpload ? 8 : identifierLabel ? 7 : 6;
    const members = processMembersCsv(lines, requiredNumberOfFields, identifierLabel);

    setMembers(members);
    validate(members);
  };

  const isMembersCsvValid = lines => {
    if (lines.length === 0) {
      setErrorResult('You can not upload 0 rows');
      return false;
    }

    if (lines.length > 100) {
      setErrorResult(
        'There is a limit 100 rows per file. If you need to process more then 100 members, ' +
          'please split it on several files and upload them one by one.'
      );
      return false;
    }

    return true;
  };

  const validateCompanyNameExistence = () => {
    setErrorResult(
      companyName
        ? ''
        : 'Could not retrieve company from salesforce. The activation code letters cannot be generated.'
    );
  };

  const processMembersCsv = (lines, requiredNumberOfFields, identifierLabel) => {
    const members = [];
    let rowIndex = 0;

    lines
      .filter(line => line && line.trim().length !== 0)
      .forEach(line => {
        const fields = line.split(';');
        if (fields.length !== requiredNumberOfFields) {
          setErrorResult('Wrong fields number in .csv file.');
          return;
        }

        let genderValue = fields[2].trim().toUpperCase();
        genderValue = genderValue === 'W' ? 'F' : genderValue;

        if (identifierLabel && !fields[6].trim()) {
          setErrorResult('Personal Identifier in csv is mandatory for this membership offer.');
          return;
        }

        members.push({
          rowNumber: rowIndex++,
          firstName: fields[0].trim(),
          lastName: fields[1].trim(),
          gender: genderValue,
          dateOfBirth: parseBirthDateField(fields[3].trim()),
          email: fields[4].trim(),
          membershipStartDate: normaliseValidateDate(fields[5].trim()),
          ...((isMultipleOffersUpload || identifierLabel) && {
            identifierValue: fields[6].trim(),
          }),
          membershipOfferId: isMultipleOffersUpload ? fields[7].trim() : offerInfo.id,
        });
      });

    return members;
  };

  const removeMember = index => {
    const newMembers = members.filter(member => member.rowNumber !== index);
    setMembers(newMembers);
  };

  const validate = members => {
    for (const member of members) {
      executeWithSpinner(
        httpPost(`/v1/membership/validate`, member)
          .then(validateResponse => {
            if (validateResponse) {
              member.warnings = validateResponse.warnings;
              member.errors = validateResponse.errors;

              if (validateResponse.firstName) {
                member.firstName = validateResponse.firstName;
              }
              if (validateResponse.lastName) {
                member.lastName = validateResponse.lastName;
              }
              if (validateResponse.gender) {
                member.gender = validateResponse.gender;
              }
              if (validateResponse.dateOfBirth) {
                member.dateOfBirth = moment(validateResponse.dateOfBirth, 'YYYY-MM-DD').format(
                  'DD.MM.YYYY'
                );
              }

              setMembers([...members]);
            } else {
              member.errors = 'Unexpected error';
              setMembers([...members]);
              setErrorResult('Unexpected server error');
            }
          })
          .catch(error => {
            if (error && (error.status === 403 || error.status === 404)) {
              setErrorResult(`Error occurred during validation: ${error.message}`);
            } else {
              member.errors = [error];
              setMembers([...members]);
            }
          })
      );
    }
    setEdited(false);
  };

  const addMembers = sendEmail => {
    setErrorResult('');

    if (members.find(member => member.errors && member.errors.length > 0)) {
      setErrorResult('Can not proceed with errors in the list.');
      return;
    }

    for (const member of members) {
      executeWithSpinner(
        httpPost(`/v1/membership/add?sendEmail=${sendEmail}`, member)
          .then(response => {
            member.uploadErrors = response.errors;
            member.uploaded = true;
            member.emailSent = response.emailSent;
            member.activationCode = response.activationCode;
            memberUploaded(member);
          })
          .catch(error => {
            member.uploadErrors = [error];
            member.uploaded = true;
            memberUploaded(member);
          })
      );
    }
  };

  const memberUploaded = member => {
    counter.total = counter.total + 1;
    if (member.uploadErrors && member.uploadErrors.length > 0) {
      counter.failure = counter.failure + 1;
    }
    setCounter({ total: counter.total, failure: counter.failure });
    setMembers([...members]);
  };

  const updateMember = (rowNumber, fieldName, value) => {
    setMembers(prevMembers =>
      prevMembers.map(prevMember =>
        prevMember.rowNumber === rowNumber ? { ...prevMember, [fieldName]: value } : prevMember
      )
    );
    setEdited(true);
  };

  return (
    <>
      <AddMembersForm
        offerInfo={offerInfo}
        signupPageUrls={signupPageUrls}
        signupPageNames={signupPageNames}
        members={members}
        membersOnChange={membersOnChange}
        removeMember={removeMember}
        addMembers={addMembers}
        uploadedMembersTotalCount={counter.total}
        uploadedMembersFailureCount={counter.failure}
        errorResult={errorResult}
        companyName={companyName}
        setCurrentRow={setCurrentRow}
        currentRow={currentRow}
        updateMember={updateMember}
        validate={validate}
        edited={edited}
        isMultipleOffersUpload={isMultipleOffersUpload}
      />
    </>
  );
};

export default AddMembersManager;
