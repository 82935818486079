// @flow
import * as React from 'react';
import Modal from 'app/ui/common/modal/Modal';
import { formatISODateToDate } from 'app/utils/format/dateTimeFormatter';
import arrayMutators from 'final-form-arrays';
import DropdownFormField from 'app/ui/components/DropdownFormField';
import { validate } from 'app/ui/user-manager/user/roles/validator';
import {
  isCompanyRole,
  QUALITRAIN_OPS_MASTER,
  roleOptionsForBaseDropdown,
  roleOptionsForOpsMasterDropdown,
  userHasRole,
} from 'app/ui/common/authentication/roles';
import CompanyRoleDetails from 'app/ui/user-manager/user/roles/company-admin/CompanyRoleDetails';
import { httpPost } from 'app/service/http';
import { Form } from 'react-final-form';
import { initialValues, reducer } from 'app/ui/user-manager/user/roles/reducer';
import { addRoleToUserFailure } from 'app/ui/user-manager/user/roles/actions';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

type Props = {
  isOpen: boolean,
  onRequestClose: Function,
  onAddRoleToUserSuccess: Function,
  selectedUser: Object,
};

/**
 * The "Save" button is disabled if no role is selected.
 * For roles that need additional entityId(s), the button is still disabled until at least one entity is added.
 */
const isSaveButtonEnabled = (role: string, state: any) =>
  !!role && !(isCompanyRole(role) && (state.selectedSfCompanies?.length ?? 0) === 0);

const RolesModal = ({ isOpen, onRequestClose, onAddRoleToUserSuccess, selectedUser }: Props) => {
  const [state, dispatch] = React.useReducer(reducer, initialValues);
  // logged in user
  const { user: loggedInUser } = React.useContext(AuthenticationContext);

  const addRoleToUser = (values: any) => {
    let entityIds = [];
    if (isCompanyRole(values.role)) {
      entityIds = state.selectedSfCompanies.map(sfCompany => sfCompany.CanonicalId__c);
    }
    const postBody = {
      authority: values.role,
      entityIds: entityIds,
    };

    httpPost(`/v1/user/${selectedUser.id}/role`, postBody)
      .then(role => {
        onAddRoleToUserSuccess(role);
        onRequestClose();
      })
      .catch(error => {
        dispatch(addRoleToUserFailure(error && error.message));
      });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} modalTitle="Assign Role">
      {selectedUser && (
        <>
          <dl className="row">
            <dt className="col-sm-3">Name:</dt>
            <dd className="col-sm-9">{`${selectedUser.firstName}, ${selectedUser.lastName}`}</dd>
            <dt className="col-sm-3">Email:</dt>
            <dd className="col-sm-9">{selectedUser.email}</dd>
            <dt className="col-sm-3">Birth Date:</dt>
            <dd className="col-sm-9">{formatISODateToDate(selectedUser.birthday)}</dd>
          </dl>
          <Form
            onSubmit={addRoleToUser}
            mutators={{ ...arrayMutators }}
            initialValues={{}}
            validate={validate}
            render={({ submitError, handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <DropdownFormField
                  controlLabel="Role"
                  controlId="role"
                  options={
                    userHasRole(loggedInUser, QUALITRAIN_OPS_MASTER)
                      ? roleOptionsForOpsMasterDropdown()
                      : roleOptionsForBaseDropdown()
                  }
                />
                {values.role && isCompanyRole(values.role) && (
                  <CompanyRoleDetails state={state} dispatch={dispatch} />
                )}
                {state.errorMessage && (
                  <div className="alert alert-danger">{state.errorMessage}</div>
                )}
                <div className="form-group form-row">
                  <div className="col-sm-9" />
                  <button
                    className="btn btn-secondary col-sm-3"
                    type="submit"
                    disabled={!isSaveButtonEnabled(values.role, state)}
                  >
                    Save
                  </button>
                </div>
              </form>
            )}
          />
        </>
      )}
    </Modal>
  );
};

export default RolesModal;
