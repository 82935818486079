// @flow
import React, { useState } from 'react';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { httpGet } from 'app/service/http';
import ChargeRequestsTable from './ChargeRequestsTable';
import { useParams } from 'react-router-dom';
import PaymentMethod from './paymentmethod/PaymentMethod';

const PaymentDetailsPage = () => {
  const { userId } = useParams();
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState(undefined);
  const [chargeRequests, setChargeRequests] = useState(undefined);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const limitChargeRequests = 10;

  React.useEffect(() => {
    const loadPaymentDetails = async () => {
      try {
        const loaded = await httpGet(`/v1/user/${userId}/payment-method/history`);
        setPayments(loaded);
      } catch (error) {
        setError(error);
      }
    };
    const getLatestChargeRequestWithPspReference = async () => {
      try {
        const loaded = await httpGet(
          `/v1/payment/charge?userId=${userId}&pageSize=${limitChargeRequests}`
        );
        setChargeRequests(loaded.content);
      } catch (error) {
        setError(error);
      }
    };
    executeWithSpinner(loadPaymentDetails());
    executeWithSpinner(getLatestChargeRequestWithPspReference());
  }, [executeWithSpinner, userId]);

  return (
    <>
      <h5>Last Charge Requests</h5>
      <div className="mb-4">
        <i>
          Showing a maximum of <b>{limitChargeRequests}</b> most recent charge requests.
        </i>
      </div>
      {chargeRequests && chargeRequests.length > 0 && (
        <div className="mt-2 mb-5">
          <ChargeRequestsTable chargeRequests={chargeRequests} />
        </div>
      )}
      <h5>Payment Details</h5>
      {payments &&
        payments.map(payment => <PaymentMethod key={payment.id} paymentDetails={payment} />)}
      <div>{error && <div className="alert alert-primary">{error}</div>}</div>
    </>
  );
};

export default PaymentDetailsPage;
