// @flow
import * as React from 'react';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import { httpGet } from 'app/service/http/index';
import { useCallback, useEffect, useState } from 'react';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import SignupPageSearchResult from './SignupPageSearchResult';
import { Link, useLocation } from 'react-router-dom';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

const SignupPageSearchManager = () => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const location = useLocation();
  const [pages, setPages] = useState(null);
  const [alias, setAlias] = useState('');
  const [sfAccountCanonicalId, setSfAccountCanonicalId] = useState('');
  const [errorResult, setErrorResult] = useState(null);
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  const sfAccountId = new URLSearchParams(location.search).get('sfAccountId');

  const searchForAlias = alias => {
    executeWithSpinner(
      httpGet(`/v1/signup-page?alias=${alias}`)
        .then(response => setPages(response))
        .catch(error => setErrorResult(error && error.message))
    );
  };

  const convertToPage = response => {
    return response.map(page => ({
      membershipOfferId: page?.membershipOffer.id,
      name: page.name,
      token: page.token,
      b2cPayment: page?.membershipOffer.b2cPayment,
      activeOffer: page.activeOffer,
      sfAccountCanonicalId: page?.membershipOffer.sfAccountCanonicalId,
    }));
  };

  const searchForSfAccountCanonicalId = useCallback(
    sfAccountCanonicalId => {
      executeWithSpinner(
        httpGet(`/v1/signup-page?sfAccountCanonicalId=${sfAccountCanonicalId}`)
          .then(response => {
            setPages(convertToPage(response));
          })
          .catch(error => setErrorResult(error && error.message))
      );
    },
    [executeWithSpinner]
  );

  const searchForCompany = () => {
    if (sfAccountCanonicalId) {
      searchForSfAccountCanonicalId(sfAccountCanonicalId);
      return;
    }

    if (alias) {
      searchForAlias(alias);
    }
  };

  const submitSearchForm = event => {
    event.preventDefault();

    const searchParams = [alias, sfAccountCanonicalId].filter(Boolean);
    setPages(null);
    setErrorResult(null);

    if (!alias && !sfAccountCanonicalId) {
      setErrorResult('Please fill any of the search fields');
      return;
    }

    if (searchParams.length > 1) {
      setErrorResult('Only one search param should be filled');
      return;
    }

    searchForCompany();
  };

  useEffect(() => {
    if (sfAccountId) {
      setSfAccountCanonicalId(sfAccountId);
      searchForSfAccountCanonicalId(sfAccountId);
    }
  }, [sfAccountId, searchForSfAccountCanonicalId]);

  return (
    <>
      <form onSubmit={submitSearchForm}>
        <div className="form-group form-row">
          <div className="col offset-8">
            {isEditable && (
              <Link
                className="btn btn-secondary"
                style={{ width: '100%' }}
                to="/companies/offer-manager/new/details"
                state={{ sfAccountId }}
              >
                Create new offer
              </Link>
            )}
          </div>
          <div className="col">
            {isEditable && (
              <Link
                className="btn btn-secondary"
                style={{ width: '100%' }}
                to="/companies/self-signup-manager"
              >
                Create Signup Page
              </Link>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <div className="input-group">
                <input
                  id="sfAccountCanonicalId"
                  type="text"
                  className="form-control"
                  value={sfAccountCanonicalId}
                  placeholder="SalesForce Account Canonical ID"
                  onChange={event => setSfAccountCanonicalId(event.target.value)}
                />
                <input
                  id="alias"
                  type="text"
                  className="form-control"
                  value={alias}
                  placeholder="Company Alias Name"
                  onChange={event => setAlias(event.target.value)}
                />
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <SignupPageSearchResult pages={pages} errorResult={errorResult} />
    </>
  );
};

export default withPageTemplate(SignupPageSearchManager, { headerTitle: 'Company Offer Search' });
