//@flow
import { downloadFileFromUrl } from 'app/utils/file/fileUtil';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  taskId: string,
  reportMessage: string,
  filename: string,
  exportedFilename: string,
  className: string,
};

const UploadTaskReportLink = ({
  taskId,
  reportMessage,
  filename,
  exportedFilename,
  className,
}: Props) => {
  const downloadCheckins = () => {
    downloadFileFromUrl(`/v1/checkin/upload-task/${taskId}/export/${filename}`, exportedFilename);
  };

  return (
    <div className={className}>
      <span>{reportMessage}</span>
      <br />
      <Link to="#" onClick={() => downloadCheckins()}>
        Download report
      </Link>
    </div>
  );
};

export default UploadTaskReportLink;
