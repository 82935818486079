// @flow
import * as React from 'react';

type Props = {
  children: any,
};

const EmojiRainContext = React.createContext();

const EmojiRainProvider = ({ children }: Props) => {
  const [emojiRainTheme, setEmojiRainTheme] = React.useState('none');

  return (
    <EmojiRainContext.Provider
      value={{
        emojiRainTheme: emojiRainTheme,
        setEmojiRainTheme: setEmojiRainTheme,
      }}
    >
      {children}
    </EmojiRainContext.Provider>
  );
};

export { EmojiRainProvider };
export default EmojiRainContext;
