// @flow

/**
 * Remapping the web gateway error response
 */
export const mapErrorResponse = error => {
  let remappedErrors = error;

  // Handling the default spring validation error response
  if (error.errors) {
    remappedErrors = {
      userServiceFieldErrors: error.errors.map(e => {
        return {
          message: e.defaultMessage,
          code: e.code,
          field: e.field,
        };
      }),
    };
  }

  return remappedErrors;
};
