import { BehaviorSubject } from 'rxjs';

// The notification observable will contain:
// 1. the message to display
// 2. variant one of ["error", "warning", "success", "info"]
const allowedVariants = ['error', 'warning', 'success', 'info'];
const notificationSubject = new BehaviorSubject({ message: '', variant: '', autoClose: undefined });

/**
 * The notification service is an observable of a BehaviorSubject with 2 fields:
 * 1. `message` -> the message to be sent as notification
 * 2. `variant` -> can be one of ["error", "warning", "success", "info"]. If some other
 * value is provided then variant will default to "info".
 *
 * Observables in general are useful to trigger state changes inside components
 * from outside any React context.
 *
 * TODO: put here the correct name of the component once it's implemented.
 * This observable in particular will be useful to work together with the Snackbar
 * component to display some message to the user from anywhere in the code.
 */
export const notificationService = {
  push,
  notification: notificationSubject.asObservable(),
  get notificationValue() {
    return notificationSubject.value;
  },
};

async function push(notification) {
  if (!notification.variant || !allowedVariants.includes(notification.variant)) {
    notification.variant = 'info';
  }
  if (!notification.message) {
    notification.message = notification.variant;
  }
  console.log(`Broadcasting ${JSON.stringify(notification)}`);
  notificationSubject.next(notification);
}
