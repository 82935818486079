// @flow
/**
 * Gets a property (possibly deeply nested) of an object.
 *
 * Example usage: getPropertyValue(membership, 'company.price.currency').
 *
 * In case any of the intermediate objects is undefined, the function will return the fallback value.
 * The fallback argument is optional, and the default value is an empty string.
 */
export const getPropertyValueOrFallback = (object: any, property: string, fallback = ''): any => {
  const propertiesArray = property.split('.');
  const propertyValue = propertiesArray.reduce(
    (accumulator, currentValue) => accumulator && accumulator[currentValue],
    object
  );
  return propertyValue !== null && propertyValue !== undefined ? propertyValue : fallback;
};

export const isSame = (value1, value2: any) =>
  (!value1 && !value2) || (value1 && value1 === value2);
