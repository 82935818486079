// @flow
import {
  CET_TIMEZONE,
  dateTimeToDateCET,
  isValidDay,
  isValidMonth,
  isValidYear,
  toDate,
} from 'app/utils/date/dateUtil';
import { isValidDate } from 'app/utils/date/dateUtil';
import moment from 'moment-timezone';
import {
  VALIDATION_INVALID_DAY,
  VALIDATION_INVALID_MONTH,
  VALIDATION_INVALID_YEAR,
  VALIDATION_PAPERLIST_DATE_REQUIRED,
  VALIDATION_PAPERLIST_DUPLICATE_CHECKIN,
  VALIDATION_PAPERLIST_INVALID_DATE_BEFORE_LAST_MONTH,
  VALIDATION_PAPERLIST_INVALID_DATE_IN_FUTURE,
  VALIDATION_PAPERLIST_INVALID_DATE_OUTSIDE_OF_MEMBERSHIP,
  VALIDATION_PAPERLIST_MEMBERSHIP_REQUIRED,
  VALIDATION_PAPERLIST_COMPANY_ALIAS_INVALID,
  VALIDATION_TYPE_ERROR,
  VALIDATION_TYPE_WARNING,
} from 'app/validation/common/errorCodes';
import type { SingleFieldValidationResult } from 'app/validation/types';

export const UPLOAD_STATUS_SUCCESS = 'SUCCESS';
export const UPLOAD_STATUS_ERROR = 'ERROR';

export type ValidationResultType = SingleFieldValidationResult | undefined;

export const validatePaperlistCheckin = (
  paperlistCheckin,
  paperlistCheckins
): ValidationResultType => {
  const {
    inputDay: day,
    inputMonth: month,
    inputYear: year,
    selectedMembership,
    selectedCompanyAlias,
  } = paperlistCheckin;

  if (!selectedMembership) {
    return createError(VALIDATION_PAPERLIST_MEMBERSHIP_REQUIRED);
  } else if (day && !isValidDay(day)) {
    return createError(VALIDATION_INVALID_DAY);
  } else if (month && !isValidMonth(month)) {
    return createError(VALIDATION_INVALID_MONTH);
  } else if (year && !isValidYear(year)) {
    return createError(VALIDATION_INVALID_YEAR);
  } else if (!selectedCompanyAlias || !selectedCompanyAlias.label) {
    return createError(VALIDATION_PAPERLIST_COMPANY_ALIAS_INVALID);
  } else {
    const checkinDateString = toDate(day, month, year);
    if (!isValidDate(checkinDateString)) {
      return createError(VALIDATION_PAPERLIST_DATE_REQUIRED);
    }
    const checkinDate = moment(checkinDateString, 'DD-MM-YYYY', CET_TIMEZONE)
      .startOf('day')
      .add(12, 'hour');

    if (isDateInFuture(checkinDate)) {
      return createError(VALIDATION_PAPERLIST_INVALID_DATE_IN_FUTURE);
    } else if (isDateBeforeLastMonth(checkinDate)) {
      return createError(VALIDATION_PAPERLIST_INVALID_DATE_BEFORE_LAST_MONTH);
    } else if (isDateOutsideOfMembership(checkinDate, selectedMembership)) {
      return createError(VALIDATION_PAPERLIST_INVALID_DATE_OUTSIDE_OF_MEMBERSHIP, [
        dateTimeToDateCET(
          selectedMembership.membershipWrapper.membership.membershipStartTimestamp
        ) || '(no start date)',
        dateTimeToDateCET(selectedMembership.membershipWrapper.membership.membershipEndTimestamp) ||
          '(no end date)',
      ]);
    }

    const duplicatePaperlistCheckin = paperlistCheckins.find(other => {
      if (other.id === paperlistCheckin.id) return false;
      const otherCheckinDateString = toDate(other.inputDay, other.inputMonth, other.inputYear);
      if (otherCheckinDateString !== checkinDateString) return false;
      return (
        other.selectedMembership &&
        other.selectedMembership.membershipWrapper.membership.uuid ===
          selectedMembership.membershipWrapper.membership.uuid
      );
    });

    if (duplicatePaperlistCheckin) {
      return createWarning(VALIDATION_PAPERLIST_DUPLICATE_CHECKIN);
    }
  }
};

const createError = (code, args) => {
  return {
    type: VALIDATION_TYPE_ERROR,
    code: code,
    args: args,
  };
};

const createWarning = (code, args) => {
  return {
    type: VALIDATION_TYPE_WARNING,
    code: code,
    args: args,
  };
};

const isDateInFuture = checkinDate => {
  return checkinDate.isAfter(moment(new Date(), CET_TIMEZONE).endOf('day'));
};

const isDateBeforeLastMonth = checkinDate => {
  const beginningOfLastMonth = moment(new Date(), CET_TIMEZONE)
    .subtract(1, 'month')
    .startOf('month')
    .startOf('day');
  return checkinDate.isBefore(beginningOfLastMonth);
};

const isDateOutsideOfMembership = (checkinDate, selectedMembershp) => {
  const { membershipStartTimestamp, membershipEndTimestamp } =
    selectedMembershp.membershipWrapper.membership;

  if (membershipStartTimestamp && checkinDate.isBefore(membershipStartTimestamp)) {
    return true;
  }
  if (membershipEndTimestamp && checkinDate.isAfter(membershipEndTimestamp)) {
    return true;
  }
  return false;
};

export const hasError = paperlistCheckin => {
  return (
    paperlistCheckin.validationResult &&
    paperlistCheckin.validationResult.type === VALIDATION_TYPE_ERROR
  );
};

export const hasWarning = paperlistCheckin => {
  return (
    paperlistCheckin.validationResult &&
    paperlistCheckin.validationResult.type === VALIDATION_TYPE_WARNING
  );
};

export const hasUploadError = paperlistCheckin => {
  return (
    paperlistCheckin.uploadResult && paperlistCheckin.uploadResult.status === UPLOAD_STATUS_ERROR
  );
};

export const isSuccessfullyUploaded = paperlistCheckin => {
  return (
    paperlistCheckin.uploadResult && paperlistCheckin.uploadResult.status === UPLOAD_STATUS_SUCCESS
  );
};
