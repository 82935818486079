// @flow
import React from 'react';
import ConfirmationModal from 'app/ui/common/modal/ConfirmationModal';

type Props = {
  onConfirm: Function,
  onCancel: Function,
};

const PayoutConfirmationModal = ({ onConfirm, onCancel }: Props) => {
  return (
    <ConfirmationModal
      isOpen={true}
      modalTitle="Confirmation of Payout"
      yesButtonText="CONFIRM"
      noButtonText="CANCEL"
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <p className="text-black-50">
        Are you sure you want to confirm payout?
        <br />
        This step can not be reversed and payouts will be shown in the gym operator portal.
      </p>
    </ConfirmationModal>
  );
};

export default PayoutConfirmationModal;
