// @flow
import React from 'react';

type Props = {
  onClick: Function,
};

const TrashIcon = ({ onClick }: Props) => (
  <div className="cursor-pointer border-0 py-0" onClick={onClick}>
    <i className="far fa-trash-alt fa-lg" />
  </div>
);

export default TrashIcon;
