// @flow
/**
 * Extracts the day part from a Period string.
 * Period example: "P-1M15D" -> day is "15"
 */
export const extractMonthFromPeriod = (period: string): string => {
  const pattern = /P([-+]?\d+)M/;
  return period.indexOf('M') !== -1 ? period.match(pattern)[1] : '0';
};

/**
 * Extracts the day part from a Period string.
 * Period example: "P-1M15D" -> day is "15"
 */
export const extractDayFromPeriod = (period: string): string => {
  const pattern1 = /P([-+]?\d+)D/;
  const pattern2 = /P([-+]?\d+)M([-+]?\d+)D/;
  return period.indexOf('D') !== -1
    ? period.indexOf('M') !== -1
      ? period.match(pattern2)[2]
      : period.match(pattern1)[1]
    : '0';
};

/**
 * Converts the month/day pair into a period string.
 *
 * The format of the resulting string is: "P<months>M<days>D"
 */
export const convertToPeriod = (month: string, day: string): string => `P${month}M${day}D`;
