// @flow
import React, { useCallback, useMemo, useState } from 'react';
import qrcode from 'qrcode-generator';
import mergeImages from 'merge-images';
import qrCodeTemplateGerman from './qr_code_template.png';
import qrCodeTemplateEnglish from './qr_code_template_english.png';
import wellpassLogo from './wellpass-logo.png';
import { createGymQRCode } from '../../../../utils/pdf/gymQRCodePdf';
import GymContext from '../../gym/context/GymContext';
import { formatISODateToDate } from '../../../../utils/format/dateTimeFormatter';
import clsx from 'clsx';
import { SUPPORTED_QR_LANGUAGES } from '../../../../utils/qrCodeTranslation/constants';
import { translateQRKey } from '../../../../utils/qrCodeTranslation/utils';
import { httpGet } from '../../../../service/http';

type Props = {
  qrValue: string,
  gymId: number,
  creationTimestamp: string,
  label: string,
};

const QrCodeGenerator = ({ qrValue, gymId, creationTimestamp, language }: Props) => {
  const { gym } = React.useContext(GymContext);
  const [errorResult, setErrorResult] = useState(null);

  const { labelValue, buttonCssClass } = useMemo(() => {
    const labelTranslation = translateQRKey('DOWNLOAD_QR_LABEL', language);
    return {
      labelValue: labelTranslation,
      buttonCssClass: language === SUPPORTED_QR_LANGUAGES.ENGLISH ? 'btn-secondary' : 'btn-primary',
    };
  }, [language]);

  const downloadQRCode = useCallback(async () => {
    let gymSelectedForQrCodeDownload = gym;
    if (!gymSelectedForQrCodeDownload) {
      gymSelectedForQrCodeDownload = await httpGet(`/v1/gym/${gymId}`);
    }
    const margin = 0;
    const cellSize = 13;
    const typeNumber = 0; //0 for auto detection based on input
    const errorCorrectionLevel = 'H';

    const qrCode = qrcode(typeNumber, errorCorrectionLevel);
    qrCode.addData(qrValue);
    qrCode.make();

    const qrCodeData = qrCode.createDataURL(cellSize, margin);
    const qrCodeTemplate =
      language === SUPPORTED_QR_LANGUAGES.ENGLISH ? qrCodeTemplateEnglish : qrCodeTemplateGerman;
    mergeImages([qrCodeTemplate, { src: qrCodeData, x: 465, y: 465 }])
      .then(imgDataUrl => {
        createGymQRCode(gymSelectedForQrCodeDownload, imgDataUrl, wellpassLogo, language).then(
          pdf => {
            const gymName =
              gymSelectedForQrCodeDownload?.alias?.trim().replace(/\s+/g, '-') || 'gym-name';
            const pdfFilename = `QR_CODE_${gymId}_${gymName}_${formatISODateToDate(
              creationTimestamp
            )}.pdf`;

            pdf.save(pdfFilename);
          }
        );
      })
      .catch(error => {
        setErrorResult('Error generating pdf');
        console.log('Error while generating the qrcode, ', error);
      });
  }, [creationTimestamp, gym, gymId, qrValue, language]);

  return (
    <>
      {errorResult && <div>{errorResult}</div>}
      {!errorResult && (
        <button
          className={clsx(`btn ${buttonCssClass} btn-block btn-sm`)}
          type="button"
          onClick={() => downloadQRCode()}
        >
          {labelValue}
        </button>
      )}
    </>
  );
};

export default QrCodeGenerator;
