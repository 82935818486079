import React from 'react';
import { Form } from 'react-final-form';
import { dateToCET } from '../../../utils/date/dateUtil';
import { Link } from 'react-router-dom';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

const LinkedOffer = ({ offers, headingText }) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  return (
    <div className="mt-4">
      <Form
        onSubmit={() => {}}
        initialValues={{}}
        render={({ submitError, handleSubmit, form, submitting, valid, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              {
                <div className="mt-4">
                  <div className="text-primary">
                    <h5>{headingText}</h5>
                  </div>
                  <div>
                    <table className="table mt-2">
                      <thead className="thead-light">
                        <tr>
                          <th>Offer ID</th>
                          <th>SF ACC ID</th>
                          <th>SC ACC Name</th>
                          <th>Price</th>
                          <th>Offer Type</th>
                          <th>Available From</th>
                          <th>Available To</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {offers &&
                          offers.map(offer => (
                            <tr key={offer.id + offer.sfAccountCanonicalId}>
                              <td>{offer.id}</td>
                              <td>{offer.sfAccountCanonicalId}</td>
                              <td>{offer.sfAccountCanonicalName}</td>
                              <td>
                                {offer.priceAmount} {offer.priceCurrency}
                              </td>
                              <td>{offer.type}</td>
                              <td>{dateToCET(offer.availableFrom)}</td>
                              <td>{dateToCET(offer.availableTo)}</td>
                              <td>
                                <Link
                                  className="btn btn-primary"
                                  to={`/companies/offer-manager/${offer.id}/details`}
                                >
                                  {isEditable ? 'Edit Offer' : 'View Offer'}
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              }
              {submitError && (
                <div className="alert alert-danger">{JSON.stringify(submitError, null, 2)}</div>
              )}
            </form>
          );
        }}
      />
    </div>
  );
};

export default LinkedOffer;
