// @flow
export const VALIDATION_TYPE_ERROR = 'ERROR';
export const VALIDATION_TYPE_WARNING = 'WARNING';

export const VALIDATION_REQUIRED_FIELD = 'validation.general.requiredField';
export const VALIDATION_INVALID_DATE = 'validation.general.invalidDate';
export const VALIDATION_INVALID_DATE_ORDER = 'validation.general.invalidDateOrder';
export const VALIDATION_INVALID_DATE_MUST_BE_IN_PAST = 'validation.general.dateMustBeInPast';
export const VALIDATION_INVALID_DATE_MUST_BE_AFTER_MINIMAL = 'validation.general.dateAfterMinimal';
export const VALIDATION_INVALID_DATE_MUST_NOT_BE_IN_PAST = 'validation.general.dateMustNotBeInPast';
export const VALIDATION_INVALID_MIN_LENGTH = 'validation.general.minLength';
export const VALIDATION_INVALID_MAX_LENGTH = 'validation.general.maxLength';
export const VALIDATION_INVALID_CURRENCY = 'validation.general.invalidCurrency';
export const VALIDATION_INVALID_MONETARY_AMOUNT = 'validation.general.invalidMonetaryAmount';
export const VALIDATION_INVALID_GENDER = 'validation.general.gender';
export const VALIDATION_INVALID_EMAIL = 'validation.general.email';
export const VALIDATION_BLANK_VALUE = 'validation.general.blank';
export const VALIDATION_INVALID_DAY = 'validation.general.invalidDay';
export const VALIDATION_INVALID_MONTH = 'validation.general.invalidMonth';
export const VALIDATION_INVALID_YEAR = 'validation.general.invalidYear';
export const VALIDATION_INVALID_LOGO_SIZE = 'validation.general.invalidLogoSize';
export const VALIDATION_INVALID_REGEX = 'validation.general.invalidRegex';

// Memberships specific
export const VALIDATION_OVERLAPPING_MEMBERSHIPS =
  'validation.user.membership.overlappingMemberships';
export const VALIDATION_OVERLAPPING_REFERRER_MEMBERSHIPS =
  'validation.user.membership.overlappingReferrerMemberships';
export const VALIDATION_INVALID_MEMBERSHIP_OFFER_PRICE = 'validation.membershipOffer.invalidPrice';
export const VALIDATION_CANCELLATION_WITHOUT_END_DATE =
  'validation.membershipOffer.cancellationWithoutEndDate';
export const VALIDATION_CANCELLATION_WITHOUT_API_CANCELLATION_REASON =
  'validation.membershipOffer.cancellationReasonAPI';
export const VALIDATION_B2C_CHARGE_REQUEST_EXISTS = 'validation.membership.b2cChargeRequestExists';

// Paperlist Checkin Tool
export const VALIDATION_PAPERLIST_MEMBERSHIP_REQUIRED =
  'validation.paperlistCheckins.membership.requiredField';
export const VALIDATION_PAPERLIST_COMPANY_ALIAS_INVALID =
  'validation.paperlistCheckins.companyalias.invalid';
export const VALIDATION_PAPERLIST_DATE_REQUIRED = 'validation.paperlistCheckins.date.requiredField';
export const VALIDATION_PAPERLIST_INVALID_DATE_IN_FUTURE =
  'validation.paperlistCheckins.date.inFuture';
export const VALIDATION_PAPERLIST_INVALID_DATE_BEFORE_LAST_MONTH =
  'validation.paperlistCheckins.date.beforeLastMonth';
export const VALIDATION_PAPERLIST_INVALID_DATE_OUTSIDE_OF_MEMBERSHIP =
  'validation.paperlistCheckins.date.outsideOfMembership';
export const VALIDATION_PAPERLIST_DUPLICATE_CHECKIN =
  'validation.paperlistCheckins.duplicateCheckin';

// SF specific
export const VALIDATION_INVALID_SF_ID_FORMAT = 'validation.salesforce.id.invalidFormat';

// Multi Entity Signup Page specific
export const VALIDATION_MULTI_ENTITY_SIGNUP_PAGE_INVALID_SLUG =
  'validation.multiEntitySignupPage.slug.invalid';
export const VALIDATION_LINKED_SIGNUP_PAGE_DUPLICATE_TOKEN =
  'validation.linkedSignupPage.token.duplicate';
export const VALIDATION_LINKED_SIGNUP_PAGE_NOT_VALIDATED =
  'validation.linkedSignupPage.token.notValidated';
