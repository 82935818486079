// @flow
export type MenuItem = {
  path: string,
  text: string,
  isLink: boolean,
  submenu: Array<MenuItem>,
  isPartialPath?: boolean,
};

export type Menu = Array<MenuItem>;

const menuItem = (
  path: string,
  text: string,
  isLink: boolean,
  submenu: Menu,
  isPartialPath: boolean = false
): MenuItem => ({
  path: path,
  text: text,
  isLink: isLink,
  submenu: submenu,
  isPartialPath: isPartialPath,
});

const navigation = [
  menuItem('/', 'Home', true),
  menuItem('/users', 'User Manager', true, [
    menuItem('/users/egym-import', 'Import Egym User', true),
  ]),
  menuItem('/gyms', 'Gyms', true, [
    menuItem('/gyms/qrcodes/get', 'Batch Get QR Codes', true),
    menuItem('/gyms/payout-calculation', 'Gym Payout Calculation', true),
    menuItem('/gyms/upload-checkin', 'Upload Check-ins', true),
  ]),
  menuItem('/exports', 'Exports', false, [
    menuItem('/exports/memberships/list', 'Export memberships', true),
    menuItem('/exports/payments/list', 'B2C Payment Overview', true),
  ]),
  menuItem('/companies/search', 'Companies', true, [
    menuItem('/companies/hris-membership-mapping', 'HRIS Membership Mapping tool', true, [], true),
    menuItem('/companies/add-members', 'Batch upload memberships', true),
    menuItem('/companies/multi-entity-signup-pages/search', 'Multi-entity signup pages', true),
  ]),
];

export default navigation;
