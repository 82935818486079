// @flow
import React, { useState, useEffect } from 'react';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import UploadTasksTable from './UploadTasksTable';
import { httpGet, httpPostUpload } from 'app/service/http';

const taskLimit = 20;
const csvTemplateFileUrl = `https://storage.googleapis.com/qualitrain-admin-assets/upload_checkins.csv`;

const UploadCheckinsManager = () => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [tasks, setTasks] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();
  const [currentPageToken, setCurrentPageToken] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  const uploadCheckins = event => {
    event.preventDefault();

    httpPostUpload('/v1/checkin/upload-task', selectedFile).then(() => {
      setTasks([]);
    });
  };

  useEffect(() => {
    const fetchTasks = () => {
      let url = `/v1/checkin/upload-task?limit=${taskLimit}`;
      if (currentPageToken) {
        url += `&pageToken=${currentPageToken}`;
      }

      return executeWithSpinner(
        httpGet(url).then(response => {
          tasks.push(...response.items);
          setNextPageToken(response.nextPageToken);
        })
      );
    };
    fetchTasks();
  }, [executeWithSpinner, currentPageToken, tasks]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-8">
          <div className="font-weight-bold mb-1">
            <span className="text-primary mr-1">Upload CSV file with multiple check-ins</span>
          </div>
          <div className="mb-2">
            <span className="text-secondary mr-2">
              With this tool you can upload multiple check-ins. The csv to upload check-ins can
              contain maximum 10.000 entries. You can only upload one file a time, if an upload task
              is still in process a new upload will not be allowed. It will also not be allowed to
              upload the exact same file twice. After a file has been processed two reports will be
              generated, one report for the check-ins that were uploaded successfully and, if there
              were invalid entries, a report with the failed check-ins which will include a message
              explaining what caused that particular check-in to fail. You will also be able to
              download the file that was originally submitted.
            </span>
          </div>
        </div>
      </div>

      <form onSubmit={uploadCheckins} id="form" encType="multipart/form-data">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="mb-1">
              <p>
                Please find a example csv sheet to upload members here:&nbsp;
                <a className="font-italic" href={csvTemplateFileUrl}>
                  csv template file
                </a>
              </p>
            </div>
            <div className="mb-2">
              <div className="input-group">
                <input
                  className="text-primary mr-2"
                  id="file"
                  type="file"
                  accept=".csv"
                  onChange={e => setSelectedFile(e.target.files[0])}
                />
              </div>
            </div>
          </div>
        </div>

        {selectedFile && (
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="mb-1">
                <button type="submit" className="btn btn-secondary">
                  Upload file
                </button>
              </div>
            </div>
          </div>
        )}
      </form>

      <div className="row">
        <UploadTasksTable tasks={tasks} />
      </div>

      <div className="text-right mb-3">
        {nextPageToken && (
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => setCurrentPageToken(nextPageToken)}
          >
            Load more
          </button>
        )}
      </div>
    </>
  );
};

export default withPageTemplate(UploadCheckinsManager, { headerTitle: 'Check-in Upload' });
