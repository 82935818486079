import React from 'react';
import Modal from '../../common/modal/Modal';
import { useNavigate } from 'react-router-dom';

type Props = {
  onCancel: Function,
};

const MoveMembershipOfferSuccessModal = ({ onCancel }: Props) => {
  const navigate = useNavigate();
  return (
    <Modal isOpen={true} onRequestClose={onCancel} modalTitle="Memberships were successfully moved">
      <p className="text-black-50">
        The members did <strong>not get any automated email.</strong> Please download the list as
        csv and reach out to these members separately to inform them about the membership change.
      </p>

      <div className="form-group form-row">
        <div className="col-sm-9" />
        <button
          className="btn btn-secondary col-sm-3"
          type="button"
          onClick={() => navigate('/companies/memberships-moved')}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
export default MoveMembershipOfferSuccessModal;
