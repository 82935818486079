import React, { useEffect, useRef, useContext } from 'react';
import { animateEmojis, stopEmojiAnimation } from './emoji';
import EmojiRainContext from './EmojiRainContext';

let timeout = 0;

const EmojiRain = () => {
  const ref = useRef(null);
  const { emojiRainTheme, setEmojiRainTheme } = useContext(EmojiRainContext);

  const getEmoji = (key: string): string[] => {
    const allEmojis = {
      de: ['🥨', '🥯', '🍺', '🖤', '❤️', '💛'],
      en: ['☕️', '🫖', '💂‍♀️', '💙', '❤️', '🤍'],
      fr: ['🥐', '🥖', '🍷', '💙', '🤍', '❤️'],
      it: ['🍕', '🍝', '🍇', '💚', '❤️', '🤍'],
      default: ['🎣', '🎏', '😂', '🪺', '🐰', '🎣'],
      none: [],
    };
    return allEmojis[key];
  };

  useEffect(() => {
    if (emojiRainTheme === 'none') {
      return;
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    animateEmojis(ref, getEmoji(emojiRainTheme), window.innerHeight, window.innerWidth / 2 - 380);
    timeout = setTimeout(() => {
      stopEmojiAnimation(ref);
      setEmojiRainTheme('none');
    }, 8_000);
    return () => {
      stopEmojiAnimation(ref);
    };
  }, [emojiRainTheme, setEmojiRainTheme]);

  return <div id="emoji-rain" ref={ref}></div>;
};

export default EmojiRain;
