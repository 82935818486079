// @flow
import type { SignupPage, SignupPageFrontendModel, Coord } from 'app/ui/types';
import { convertCetDateToUtc } from 'app/utils/date/dateUtil';

export const convertSignupPageToSignupPageFrontendModel = (
  signupPage: ?SignupPage
): SignupPageFrontendModel => {
  const signupPageModel = {
    ...signupPage,
    customDescription:
      signupPage && signupPage.customDescription ? signupPage.customDescription : undefined,
    logo: {
      url: signupPage ? signupPage.logo : undefined,
      file: undefined,
    },
  };

  if (signupPage && signupPage.token) {
    signupPageModel.token = signupPage.token;
  }
  if (signupPage && signupPage.name) {
    signupPageModel.name = signupPage.name;
  }
  if (signupPage && signupPage.creation) {
    signupPageModel.creation = signupPage.creation;
  }

  // Expiration date could be nullable
  if (signupPage) {
    signupPageModel.expiration = signupPage.expiration;
  }

  if (signupPage && signupPage.localization) {
    const localization = JSON.parse(signupPage.localization);
    signupPageModel.searchTerm = localization.searchTerm;
  }

  return signupPageModel;
};

export const convertSignupPageFrontendModelToSignupPage = (
  signupPageModel: SignupPageFrontendModel,
  coord: Coord
): SignupPage => ({
  name: signupPageModel.name,
  logo: signupPageModel.logo.url,
  token: signupPageModel.token,
  customDescription: signupPageModel.customDescription,
  inviteColleagueEnabled: signupPageModel.inviteColleagueEnabled,
  expiration: convertCetDateToUtc(signupPageModel.signUpPageExpirationDate, 'DD-MM-YYYY HH:mm'),
  localization: JSON.stringify({
    searchTerm: signupPageModel.searchTerm,
    latitude: coord ? coord.lat : null,
    longitude: coord ? coord.lng : null,
  }),
});
