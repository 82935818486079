// @flow
import React from 'react';
import ConfirmationModal from 'app/ui/common/modal/ConfirmationModal';
import type { Gym } from 'app/ui/types';

type Props = {
  gym: Gym,
  countCheckinsWithoutErrors: number,
  countCheckinsWithErrors: number,
  onConfirm: Function,
  onCancel: Function,
};

const SaveCheckinsConfirmationModal = ({
  gym,
  countCheckinsWithoutErrors,
  countCheckinsWithErrors,
  onConfirm,
  onCancel,
}: Props) => (
  <ConfirmationModal
    isOpen={true}
    modalTitle="Save Paperlist Checkins?"
    yesButtonText="Upload Checkins"
    yesButtonDisabled={countCheckinsWithoutErrors === 0}
    noButtonText="Close"
    onConfirm={onConfirm}
    onCancel={onCancel}
  >
    <p className="text-center text-black-50">
      {countCheckinsWithoutErrors > 0 ? (
        <>
          Do you want to upload <strong>{countCheckinsWithoutErrors} Check-ins</strong> to:
        </>
      ) : (
        <>There are no valid Check-ins to be uploaded for:</>
      )}
    </p>
    <p className="text-center text-black-50">
      <strong>{gym && gym.alias}</strong>
      <br />
      {gym && gym.address.street}, {gym && gym.address.streetNumber}
      <br />
      {gym && gym.address.zipCode}, {gym && gym.address.city}
    </p>
    {countCheckinsWithErrors > 0 && (
      <p className="text-center text-danger">
        Additionally, <strong>{countCheckinsWithErrors} Check-ins</strong> have errors and will not
        be saved.
      </p>
    )}
  </ConfirmationModal>
);

export default SaveCheckinsConfirmationModal;
