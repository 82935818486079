// @flow
import React from 'react';

/**
 * Custom Hook that stores a state variable in the browsers Local Storage.
 *
 * @param key the Local Storage Key under which the variable is stored
 * @param initialValue the initial value
 * @return an array with three values: 1) the value itself, 2) setter function, and 3) removal function
 */
const useLocalStorage = (key: string, initialValue: any) => {
  const [value, setValue] = React.useState(() => {
    // initialValue:
    // 1) try to read from local storage, or
    // 2) fallback to the value provided if there is nothing in local storage under that key
    const localStorageItem = localStorage.getItem(key);
    return localStorageItem ? JSON.parse(localStorageItem) : initialValue;
  });

  const setLocalStorageValue = (value: any) => {
    setValue(value);
    localStorage.setItem(key, JSON.stringify(value));
  };

  const removeLocalStorageValue = () => {
    setValue(undefined);
    localStorage.removeItem(key);
  };

  return [value, setLocalStorageValue, removeLocalStorageValue];
};

export default useLocalStorage;
