// @flow
import React from 'react';
import ConfirmationModal from 'app/ui/common/modal/ConfirmationModal';

type Props = {
  onConfirm: Function,
  onCancel: Function,
};

const ClearCheckinsConfirmationModal = ({ onConfirm, onCancel }: Props) => (
  <ConfirmationModal
    isOpen={true}
    modalTitle="Clear this list?"
    yesButtonText="Delete"
    noButtonText="No, go back to the list"
    onConfirm={onConfirm}
    onCancel={onCancel}
  >
    <p className="text-black-50">The check-ins on this list are not yet saved.</p>
    <p className="text-black-50">
      <strong>Do you really want to remove them before saving?</strong>
    </p>
  </ConfirmationModal>
);

export default ClearCheckinsConfirmationModal;
