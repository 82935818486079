// @flow
import { hasError, hasWarning } from 'app/ui/gyms/gym/paperlist-checkin/validator';
import { resolveErrorCode } from 'app/validation/errorCodeResolver';
import React from 'react';
import type { PaperlistCheckinType } from 'app/ui/gyms/gym/paperlist-checkin/types';

type Props = {
  paperlistCheckin: PaperlistCheckinType,
};

const PaperlistCheckinValidationStatus = ({ paperlistCheckin }: Props) => (
  <>
    {hasError(paperlistCheckin) && (
      <div className="alert alert-danger m-0">
        <i className="fas fa-times-circle fa-lg text-danger" />
        &nbsp;
        {resolveErrorCode(paperlistCheckin.validationResult)}
      </div>
    )}
    {hasWarning(paperlistCheckin) && (
      <div className="alert alert-warning m-0">
        <i className="fas fa-exclamation-circle text-warning" />
        &nbsp;
        {resolveErrorCode(paperlistCheckin.validationResult)}
      </div>
    )}
  </>
);

export default PaperlistCheckinValidationStatus;
