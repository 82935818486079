import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { httpGet } from 'app/service/http/index';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import CancelAllMembershipsForm from './CancelAllMembershipsForm';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import moment from 'moment';
import { formatISOZonedDateTimeToCETDate } from '../../../utils/format/dateTimeFormatter';
import { useSearchParams } from 'react-router-dom';

const CancelAllMembershipsManager = () => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [memberships, setMemberships] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');

  const [searchParams] = useSearchParams();
  const sfAccountCanonicalId = searchParams.get('sfAccountCanonicalId');

  const getAllMemberships = useCallback(
    async sfAccountCanonicalId => {
      const limit = 10000;
      const membershipsUrl = `/v1/membership?sfAccountCanonicalId=${sfAccountCanonicalId}&limit=${limit}`;
      try {
        let membershipsPage = await httpGet(membershipsUrl);
        const memberships = [].concat(membershipsPage.items);

        while (membershipsPage.nextPageToken) {
          membershipsPage = await httpGet(
            `${membershipsUrl}&pageToken=${membershipsPage.nextPageToken}`
          );
          memberships.push(...membershipsPage.items);
        }
        getCompanyInformation(sfAccountCanonicalId);

        const membershipsWithStatus = [];
        memberships.forEach(function (membership) {
          if (
            moment(
              formatISOZonedDateTimeToCETDate(membership.membership.membershipEndTimestamp),
              'DD-MM-YYYY'
            ).isAfter(moment()) ||
            membership.membership.membershipEndTimestamp === null
          ) {
            membershipsWithStatus.push({ membershipWrapper: membership });
          }
        });
        setMemberships(membershipsWithStatus);
      } catch (error) {}
    },
    [setMemberships]
  );

  const getCompanyInformation = sfAccountCanonicalId => {
    httpGet(`/v1/salesforce/company?sfAccountCanonicalId=${sfAccountCanonicalId}&minified=true`)
      .then(
        companyInformation => {
          setCompanyName(companyInformation.Name);
          setCompanyAddress(
            `${companyInformation.BillingAddress.street} ${companyInformation.BillingAddress.postalCode} ${companyInformation.BillingAddress.city}`
          );
        }
        // we rely on the generic httpGet implementation in service to handle the rejection here
      )
      .catch(() => {});
  };

  const updateMembership = (idToUpdate, fieldName, newValue) => {
    setMemberships(memberships =>
      memberships.map(membership =>
        idToUpdate === membership.membershipWrapper.membership.uuid
          ? { ...membership, [fieldName]: newValue }
          : membership
      )
    );
  };

  useEffect(() => {
    if (sfAccountCanonicalId) {
      executeWithSpinner(getAllMemberships(sfAccountCanonicalId));
    }
  }, [sfAccountCanonicalId, getAllMemberships, executeWithSpinner]);

  return (
    <>
      {sfAccountCanonicalId && (
        <CancelAllMembershipsForm
          memberships={memberships}
          sfAccountCanonicalID={sfAccountCanonicalId}
          updateMembership={updateMembership}
          companyName={companyName}
          companyAddress={companyAddress}
        />
      )}
    </>
  );
};

export default withPageTemplate(CancelAllMembershipsManager, {
  headerTitle: 'Cancel/Move All Memberships',
});
