// @flow
import type {
  LinkedSignupPage,
  MultiEntitySignupPage,
  MultiEntitySignupPageFrontendModel,
  LinkedSignupPageFrontendModel,
} from 'app/ui/types';
import { convertCetDateToUtc, dateToCET } from '../../../utils/date/dateUtil';

export const convertMultiEntitySignupPageToFrontendModel = (
  page?: MultiEntitySignupPage
): MultiEntitySignupPageFrontendModel => {
  let frontendModel;

  if (page) {
    frontendModel = {
      ...page,
      logo: {
        url: page.logo,
        file: undefined,
      },
      isSlugValid: true,
      expiration: dateToCET(page.expiration),
      linkedSignupPages: convertLinkedSignupPageToFrontendModel(page.linkedSignupPages),
    };
  }

  return frontendModel;
};

export const convertLinkedSignupPageToFrontendModel = (
  linkedSignupPages?: LinkedSignupPage[]
): LinkedSignupPageFrontendModel[] | undefined => {
  if (linkedSignupPages) {
    return linkedSignupPages.map(linkedSignupPage => {
      const valid = linkedSignupPage.label && linkedSignupPage.token ? true : false;
      return { ...linkedSignupPage, isValid: valid };
    });
  }

  return [];
};

export const convertFrontendModelToMultiEntitySignupPage = (
  page: ?MultiEntitySignupPageFrontendModel
): MultiEntitySignupPage => {
  let backendModel;

  if (page) {
    backendModel = {
      ...page,
      logo: page.logo?.url,
      expiration: convertCetDateToUtc(page.expiration, 'DD-MM-YYYY HH:mm'),
      linkedSignupPages: convertFrontendModelsToLinkedSignupPage(page.linkedSignupPages),
    };
  }

  return backendModel;
};

export const convertFrontendModelsToLinkedSignupPage = (
  linkedSignupPages?: LinkedSignupPageFrontendModel[]
): LinkedSignupPage[] | undefined => {
  if (linkedSignupPages) {
    return linkedSignupPages
      .filter(
        linkedSignupPage => linkedSignupPage && linkedSignupPage.label && linkedSignupPage.token
      )
      .map(linkedSignupPage => {
        return {
          token: linkedSignupPage.token,
          label: linkedSignupPage.label,
        };
      });
  }

  return undefined;
};
