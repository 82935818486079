import { useCallback, useContext, useState } from 'react';
import { exportHrisMappingUsersToCsv } from 'app/utils/csv/hrisMappingManagerCsvExporter';
import { httpGet } from '../../../service/http';
import SpinnerContext from '../../common/spinner/SpinnerContext';

const FuzzyMatch = () => {
  const { executeWithSpinner } = useContext(SpinnerContext);
  const [isFieldFilled, setIsFieldFilled] = useState(false);
  const [sfAccountCanonicalId, setSfAccountCanonicalId] = useState(null);
  const [error, setError] = useState(null);

  const onInputTextChange = e => {
    const sanitizeInputvalue = e.target.value.trim();
    setIsFieldFilled(sanitizeInputvalue.length > 0);
    setSfAccountCanonicalId(sanitizeInputvalue);
  };

  const fetchMemberships = useCallback(async () => {
    const matchCorporateEmployeeURL = `/admin/v1/company/${sfAccountCanonicalId}/match-membership`;
    await httpGet(matchCorporateEmployeeURL, { sfAccountCanonicalId })
      .then(resp => {
        resp && exportHrisMappingUsersToCsv(resp, sfAccountCanonicalId);
      })
      .catch(err => {
        setError(err?.message);
      });
  }, [sfAccountCanonicalId]);

  const submit = useCallback(
    e => {
      e.preventDefault();
      setError(null);
      executeWithSpinner(fetchMemberships());
    },
    [fetchMemberships, executeWithSpinner]
  );

  return (
    <div>
      <h5>Match existing memberships</h5>
      <p>
        Fuzzy matching will be conducted against the data of the HRIS integration of the currently
        active offer
      </p>
      <form onSubmit={submit}>
        <div className="form-group">
          <input
            id="sfAccountCanonicalId"
            name="sfAccountCanonicalId"
            type="text"
            className="form-control w-50"
            placeholder="Enter SF Canonical ID"
            onChange={onInputTextChange}
          />
          {error && <p className="text-danger">{error}</p>}
        </div>
        <button type="submit" className="btn btn-primary" disabled={!isFieldFilled}>
          Do fuzzy match
        </button>
      </form>
    </div>
  );
};

export default FuzzyMatch;
