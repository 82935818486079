// @flow
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import { httpGet } from 'app/service/http';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import GymContext, { GymContextProvider } from 'app/ui/gyms/gym/context/GymContext';
import GymHeader from 'app/ui/gyms/gym/layout/GymHeader';
import GymSubRoutes from 'app/ui/gyms/gym/layout/GymSubRoutes';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

type SubRouteLinkProps = {
  path: string,
  text: string,
};

const SubRouteLink = ({ path, text }: SubRouteLinkProps) => (
  <NavLink className={({ isActive }) => `nav-item nav-link ${isActive ? ' active' : ''}`} to={path}>
    {text}
  </NavLink>
);

const GymTemplateWithSubRoutes = () => {
  const { gym, salesForceGym, setGym, sfErrorMsg, setSalesForceGym, setSfErrorMsg } =
    React.useContext(GymContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const { gymId } = useParams();
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  React.useEffect(() => {
    const fetchGymData = async () => {
      try {
        const egymGymResponse = await httpGet(`/v1/gym/${gymId}`);
        setGym(egymGymResponse);
        try {
          const sfGymResponse = await httpGet(`/v1/salesforce/gym?gymId=${gymId}&minified=true`);
          sfGymResponse && setSalesForceGym(sfGymResponse);
        } catch (sfError) {
          if (sfError && sfError.root === 404) {
            setSfErrorMsg(`Could not find gym with id ${gymId} in Salesforce`);
          } else if (sfError && sfError.message) {
            setSfErrorMsg(sfError.message);
          }
          setSalesForceGym(undefined);
        }
      } catch (error) {
        setGym(undefined);
      }
    };
    executeWithSpinner(fetchGymData());
  }, [gymId, setGym, setSalesForceGym, setSfErrorMsg, executeWithSpinner]);

  return (
    <>
      <GymHeader gym={gym} salesForceGym={salesForceGym} sfErrorMsg={sfErrorMsg} />
      <nav className="nav nav-tabs flex-column flex-sm-row justify-content-center">
        <SubRouteLink path={`/gyms/gym/${gymId}/qr-codes`} text="QR Codes" />
        {isEditable && (
          <SubRouteLink path={`/gyms/gym/${gymId}/add-checkins`} text="Add check-ins" />
        )}
      </nav>
      <div className="mt-4">
        <GymSubRoutes />
      </div>
    </>
  );
};

const GymTemplateWithContext = props => (
  <GymContextProvider>
    <GymTemplateWithSubRoutes {...props} />
  </GymContextProvider>
);

export default withPageTemplate(GymTemplateWithContext, { headerTitle: 'Gym Manager' });
