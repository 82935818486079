// @flow
import React from 'react';
import { dateTimeToDateTimeWithSecondsCET } from '../../../../utils/date/dateUtil';
import { formatISOZonedDateTimeToCETDate } from '../../../../utils/format/dateTimeFormatter';

type Props = {
  chargeRequest: any,
};

const PaymentLinkDetailsTable = ({ chargeRequest }: Props) => {
  return (
    <table className="table table-borderless payment-links-table">
      <thead style={{ fontWeight: 'bold' }}>
        <tr>
          <td>Payment Link Id</td>
          <td>Status</td>
          <td>Payment Id</td>
          <td>Payment status</td>
          <td>Updated at</td>
          <td>Expiry date</td>
          <td>Email sent At</td>
        </tr>
      </thead>
      <tbody>
        {chargeRequest.paymentLinks.map(link => (
          <tr key={link.adyenId}>
            <td>
              <a
                href={`${window._env_.REACT_APP_ADYEN_ADMIN_FRONTEND_BASE_URL}/ca/ca/transactions/payment-link-details.shtml?d=${link.adyenId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.adyenId}
              </a>
            </td>
            <td>{link.status}</td>
            <td>
              {link.pspReference && (
                <a
                  href={`${window._env_.REACT_APP_ADYEN_ADMIN_FRONTEND_BASE_URL}/ca/ca/accounts/showTx.shtml?pspReference=${link.pspReference}&txType=Payment`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.pspReference}
                </a>
              )}
            </td>
            <td>{link.paymentStatus}</td>
            <td>{dateTimeToDateTimeWithSecondsCET(link.updatedAt)}</td>
            <td>{formatISOZonedDateTimeToCETDate(link.expirationDate)}</td>
            <td>{dateTimeToDateTimeWithSecondsCET(link.emailSentAt)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PaymentLinkDetailsTable;
