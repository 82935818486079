// @flow
import {
  VALIDATION_BLANK_VALUE,
  VALIDATION_INVALID_CURRENCY,
  VALIDATION_INVALID_DATE,
  VALIDATION_INVALID_DATE_MUST_BE_AFTER_MINIMAL,
  VALIDATION_INVALID_DATE_MUST_BE_IN_PAST,
  VALIDATION_INVALID_DATE_MUST_NOT_BE_IN_PAST,
  VALIDATION_INVALID_DATE_ORDER,
  VALIDATION_INVALID_DAY,
  VALIDATION_INVALID_EMAIL,
  VALIDATION_INVALID_GENDER,
  VALIDATION_INVALID_LOGO_SIZE,
  VALIDATION_INVALID_MAX_LENGTH,
  VALIDATION_INVALID_MEMBERSHIP_OFFER_PRICE,
  VALIDATION_INVALID_MIN_LENGTH,
  VALIDATION_INVALID_MONETARY_AMOUNT,
  VALIDATION_INVALID_MONTH,
  VALIDATION_INVALID_REGEX,
  VALIDATION_INVALID_SF_ID_FORMAT,
  VALIDATION_INVALID_YEAR,
  VALIDATION_LINKED_SIGNUP_PAGE_DUPLICATE_TOKEN,
  VALIDATION_LINKED_SIGNUP_PAGE_NOT_VALIDATED,
  VALIDATION_MULTI_ENTITY_SIGNUP_PAGE_INVALID_SLUG,
  VALIDATION_OVERLAPPING_MEMBERSHIPS,
  VALIDATION_OVERLAPPING_REFERRER_MEMBERSHIPS,
  VALIDATION_PAPERLIST_COMPANY_ALIAS_INVALID,
  VALIDATION_PAPERLIST_DATE_REQUIRED,
  VALIDATION_PAPERLIST_DUPLICATE_CHECKIN,
  VALIDATION_PAPERLIST_INVALID_DATE_BEFORE_LAST_MONTH,
  VALIDATION_PAPERLIST_INVALID_DATE_IN_FUTURE,
  VALIDATION_PAPERLIST_INVALID_DATE_OUTSIDE_OF_MEMBERSHIP,
  VALIDATION_PAPERLIST_MEMBERSHIP_REQUIRED,
  VALIDATION_REQUIRED_FIELD,
  VALIDATION_CANCELLATION_WITHOUT_END_DATE,
  VALIDATION_B2C_CHARGE_REQUEST_EXISTS,
  VALIDATION_CANCELLATION_WITHOUT_API_CANCELLATION_REASON,
} from 'app/validation/common/errorCodes';
import type { SingleFieldValidationResult } from 'app/validation/types';

const errorCodeToTextMap = {
  [VALIDATION_REQUIRED_FIELD]: "'{fieldLabel}' is required",
  [VALIDATION_INVALID_MIN_LENGTH]: "The minimum length of '{fieldLabel}' should be {0} characters",
  [VALIDATION_INVALID_MAX_LENGTH]: "The maximum length of '{fieldLabel}' should be {0} characters",
  [VALIDATION_INVALID_CURRENCY]: 'validation.general.invalidCurrency',
  [VALIDATION_INVALID_DATE]: 'Invalid date. The format should be: {dateFormat}',
  [VALIDATION_INVALID_DATE_MUST_BE_AFTER_MINIMAL]: 'Please enter a valid date of birth',
  [VALIDATION_INVALID_DATE_ORDER]: "'{1}' should be after '{0}'",
  [VALIDATION_INVALID_DATE_MUST_BE_IN_PAST]: "'{fieldLabel}' must be in the past",
  [VALIDATION_INVALID_DATE_MUST_NOT_BE_IN_PAST]: "'{fieldLabel}' must not be in the past",
  [VALIDATION_INVALID_MONETARY_AMOUNT]:
    "'{fieldLabel}' should be a decimal amount, with max 2 digits after the decimal point",
  [VALIDATION_INVALID_REGEX]: 'Is not valid regex',
  [VALIDATION_INVALID_CURRENCY]: "'{fieldLabel}' must have exactly 3 uppercase characters",
  [VALIDATION_INVALID_GENDER]: "'{fieldLabel}' must be either 'Male' or 'Female'",
  [VALIDATION_INVALID_EMAIL]: "'{fieldLabel}' must be a valid e-mail address'",
  [VALIDATION_OVERLAPPING_MEMBERSHIPS]:
    "The membership overlaps with the membership with id: '{0}'",
  [VALIDATION_OVERLAPPING_REFERRER_MEMBERSHIPS]:
    "End date of this Plus1 membership exceeds the end date of the referrer membership with id: '{0}",
  [VALIDATION_BLANK_VALUE]: "'{fieldLabel}' value shouldn't be blank",
  [VALIDATION_INVALID_DAY]: "The 'day' should be between 1 and 31",
  [VALIDATION_INVALID_MONTH]: "The 'month' should be between 1 and 12",
  [VALIDATION_INVALID_YEAR]: "The 'year' should have 4 digits",
  [VALIDATION_PAPERLIST_MEMBERSHIP_REQUIRED]:
    'Ups! Please select a membership. Otherwise this check-in will not be saved.',
  [VALIDATION_PAPERLIST_COMPANY_ALIAS_INVALID]:
    'Ups! The selected company is not valid, please check the membership.',
  [VALIDATION_PAPERLIST_DATE_REQUIRED]:
    'Ups! Please enter a valid check-in date. Otherwise this check-in will not be saved.',
  [VALIDATION_PAPERLIST_INVALID_DATE_IN_FUTURE]:
    'The check-in date is in the future. This check-in will not be saved.',
  [VALIDATION_PAPERLIST_INVALID_DATE_BEFORE_LAST_MONTH]:
    'The check-in date is in a month that was already paid out. This check-in will not be saved.',
  [VALIDATION_PAPERLIST_INVALID_DATE_OUTSIDE_OF_MEMBERSHIP]:
    'The membership was not active on the date of the check-in. Membership duration: {0} - {1}. The check-in will not saved.',
  [VALIDATION_PAPERLIST_DUPLICATE_CHECKIN]:
    'Duplicate check-in! A check-in for the same membership and day already exists in this list.',
  [VALIDATION_INVALID_LOGO_SIZE]: 'Logo size must not be bigger then {0} bytes.',
  [VALIDATION_INVALID_SF_ID_FORMAT]: 'Incorrect SalesForce account ID. Expected ABC-123456',
  [VALIDATION_INVALID_MEMBERSHIP_OFFER_PRICE]:
    'The price can´t be 0€ when the offer is set to show B2C payment details.',
  [VALIDATION_MULTI_ENTITY_SIGNUP_PAGE_INVALID_SLUG]:
    'Invalid slug. Only uppercase letters, lowercase letters, numbers and the dash sign are allowed!',
  [VALIDATION_LINKED_SIGNUP_PAGE_DUPLICATE_TOKEN]: 'Token is already assigned!',
  [VALIDATION_LINKED_SIGNUP_PAGE_NOT_VALIDATED]: 'Please validate the signup page token!',
  [VALIDATION_CANCELLATION_WITHOUT_END_DATE]:
    'Please set an end date when setting a cancellation reason',
  [VALIDATION_B2C_CHARGE_REQUEST_EXISTS]: 'B2C charge request for the current month already exists',
  [VALIDATION_CANCELLATION_WITHOUT_API_CANCELLATION_REASON]:
    'Please pick a non API cancellation reason',
};

export const resolveErrorCode = (
  errorCode: SingleFieldValidationResult,
  fieldLabel: string
): string => {
  let code = '';
  if (typeof errorCode === 'string') {
    code = errorCode;
  } else {
    code = errorCode.code;
  }
  let errorText = errorCodeToTextMap[code] || code;
  errorText = errorText.replace('{fieldLabel}', fieldLabel);
  if (errorCode.args) {
    Object.keys(errorCode.args).forEach(
      key => (errorText = errorText.replace(`{${key}}`, errorCode.args[key]))
    );
  }
  return errorText;
};
