// @flow
import { generateCsv } from 'app/utils/csv/csvUtil';
import { downloadCsvFile } from 'app/utils/file/fileUtil';

export type MovedMembershipsInfo = {
  firstName: string,
  lastName: string,
  oldMembershipStartDate: string,
  oldMembershipEndDate: string,
  oldOfferId: string,
  newMembershipStartDate: string,
  newMembershipEndDate: string,
  newOfferId: string,
  email: string,
  userId: string,
  newMembershipId: string,
};

const COLUMN_DEFINITIONS = [
  { headerLabel: 'First Name', properties: ['firstName'] },
  { headerLabel: 'Last Name', properties: ['lastName'] },
  { headerLabel: 'Old Membership Start Date', properties: ['oldMembershipStartDate'] },
  { headerLabel: 'Old Membership End Date', properties: ['oldMembershipEndDate'] },
  { headerLabel: 'Old Offer Id', properties: ['oldOfferId'] },
  { headerLabel: 'New Membership Start Date', properties: ['newMembershipStartDate'] },
  { headerLabel: 'New Membership End Date', properties: ['newMembershipEndDate'] },
  { headerLabel: 'New Offer Id', properties: ['newOfferId'] },
  { headerLabel: 'Email', properties: ['email'] },
  { headerLabel: 'User Id', properties: ['userId'] },
  { headerLabel: 'New Membership Id', properties: ['newMembershipId'] },
];

export const exportMembershipsMovedInfo = (
  moveMembershipOfferInfo: Array<MovedMembershipsInfo>
) => {
  const csv = generateCsv(COLUMN_DEFINITIONS, moveMembershipOfferInfo);
  downloadCsvFile(csv, 'Moved Memberships.csv');
};
