import React from 'react';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import { membershipCancellationDropdownOptions } from '../../user-manager/user/memberships/membershipUtils';
import { formatISOZonedDateTimeToCETDate } from '../../../utils/format/dateTimeFormatter';
import TextFormField from '../../components/TextFormField';
import DropdownFormField from '../../components/DropdownFormField';
import { Form } from 'react-final-form';
import UserMembershipsContext from '../../user-manager/user/memberships/context/UserMembershipsContext';
import { validateCancelMembership } from './validator';

type Props = {
  countMemberships: number,
  companyName: String,
  companyAddress: String,
  sfAccountCanonicalId: string,
  onConfirm: Function,
  onCancel: Function,
};

const CancelAllMembershipsModal = ({
  countMemberships,
  companyName,
  companyAddress,
  sfAccountCanonicalId,
  onConfirm,
  onCancel,
}: Props) => {
  const [confirmationIsDisabled, setConfirmationIsDisabled] = React.useState(true);

  let submit;
  const { state } = React.useContext(UserMembershipsContext);

  const validate = values => {
    const result = validateCancelMembership(values, state);

    if (!result.membershipEndTimestamp && !result.membershipCancellationReason) {
      setConfirmationIsDisabled(false);
    } else {
      setConfirmationIsDisabled(true);
    }
    return result;
  };

  const submitEvent = event => {
    submit(event);
  };

  return (
    <ConfirmationModal
      isOpen={true}
      modalTitle="Cancel all Memberships?"
      yesButtonText="Cancel all memberships"
      yesButtonDisabled={confirmationIsDisabled}
      noButtonText="Close"
      onConfirm={submitEvent}
      onCancel={onCancel}
    >
      <p className="text-center text-black-50">
        Do you want to cancel <strong>{countMemberships} memberships</strong> of the company
      </p>
      <p className="text-center text-black-50">
        <strong>{companyName}</strong>
        <br />
        {companyAddress}
        <br />
        {sfAccountCanonicalId}
      </p>

      <Form
        onSubmit={onConfirm}
        validate={validate}
        initialValues={{
          membershipEndTimestamp: '',
        }}
        render={({ handleSubmit }) => {
          submit = handleSubmit;
          return (
            <form onSubmit={handleSubmit}>
              <TextFormField
                controlId="membershipEndTimestamp"
                controlLabel="Set membership end date for all memberships"
                placeholder="DD-MM-YYYY"
                format={formatISOZonedDateTimeToCETDate}
                formatOnBlur
              />
              <DropdownFormField
                controlId="membershipCancellationReason"
                controlLabel="Cancellation Reason"
                options={membershipCancellationDropdownOptions()}
                formatOnBlur
              />
            </form>
          );
        }}
      />
    </ConfirmationModal>
  );
};

export default CancelAllMembershipsModal;
