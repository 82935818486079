// @flow
import type { BaseAction } from 'app/ui/types';
import {
  OPEN_DELETE_CHECKIN_MODAL,
  CLOSE_DELETE_CHECKIN_MODAL,
} from 'app/ui/user-manager/user/checkins/actions';
import { DELETE_CHECKIN_FAILURE, DELETE_CHECKIN_SUCCESS } from './actions';

type State = {
  isDeleteCheckinModalOpen: boolean,
};

type Action = {
  errorMessage: any,
};

export const initialValues = {
  isDeleteCheckinModalOpen: false,
};

export const reducer = (state: State, action: BaseAction | Action) => {
  switch (action.type) {
    case OPEN_DELETE_CHECKIN_MODAL:
      return {
        ...state,
        checkinIdForDelete: action.checkinId,
        isDeleteCheckinModalOpen: true,
      };
    case CLOSE_DELETE_CHECKIN_MODAL:
      return {
        ...state,
        isDeleteCheckinModalOpen: false,
      };
    case DELETE_CHECKIN_SUCCESS:
      return {
        ...state,
        isDeleteCheckinModalOpen: false,
      };
    case DELETE_CHECKIN_FAILURE:
      return {
        ...state,
        error: action.error,
        isDeleteCheckinModalOpen: false,
      };
    default:
      return initialValues;
  }
};
