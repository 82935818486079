// @flow
import * as React from 'react';
import Header from 'app/ui/layout/Header';

type PageTemplateProps = {
  headerTitle: string,
};

type Props = {
  children: any,
  headerTitle: string,
};

const PageTemplate = ({ children, headerTitle }: Props) => (
  <>
    <Header headerTitle={headerTitle} />
    <main className="flex-grow-1">{children}</main>
  </>
);

export const withPageTemplate = (Component: any, pageTemplateProps: PageTemplateProps) => {
  const Wrapper = (props: any) => (
    <PageTemplate {...pageTemplateProps} {...props}>
      <Component {...props} />
    </PageTemplate>
  );

  Wrapper.displayName = `withPageTemplate(${Component.displayName || Component.name})`;

  return Wrapper;
};
