import React from 'react';

import { ToastContainer, toast } from 'react-toastify';

import { notificationService } from 'app/service/notification';

export const NotificationToaster = () => {
  React.useEffect(() => {
    const notificationServiceSubscription = notificationService.notification.subscribe(next => {
      if (next && next.message && next.variant) {
        toast(<div dangerouslySetInnerHTML={{ __html: next.message }} />, {
          type: next.variant,
          autoClose: next.autoClose,
        });
      }
    });

    return function cleanup() {
      // to avoid leaks, we should unsubscribe from the observable here
      notificationServiceSubscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
