import React, { useCallback, useEffect, useState } from 'react';
import SpinnerContext from '../../common/spinner/SpinnerContext';
import { httpGet, httpPut } from '../../../service/http';
import { Field, Form } from 'react-final-form';
import HorizontalFormRow from '../../components/horizontal-form/HorizontalFormRow';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

const FeatureForm = ({ sfAccountCanonicalId }) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [loading, setLoading] = useState(true);
  const [fetchedFeatures, setFetchedFeatures] = useState();
  const [enabledFeatures, setEnabledFeatures] = useState();

  const fetchFeatures = useCallback(async () => {
    try {
      const features = await httpGet(`/v1/company/${sfAccountCanonicalId}/company-portal-feature`);
      setFetchedFeatures(features);
      setEnabledFeatures({
        featureOptions: features
          .filter(featureDetail => featureDetail.enabled)
          .map(featureDetail => featureDetail.feature),
      });
    } catch (error) {
      console.error('Error fetching features data', error);
    } finally {
      setLoading(false);
    }
  }, [sfAccountCanonicalId]);

  useEffect(() => {
    executeWithSpinner(fetchFeatures());
  }, [executeWithSpinner, fetchFeatures]);

  const pristineValidator = currentModifiedFeatures => {
    const enabledFetchedFeatures = fetchedFeatures
      ?.filter(featureDetail => featureDetail.enabled)
      .map(featureDetail => featureDetail.feature);

    if (enabledFetchedFeatures?.length !== currentModifiedFeatures?.length) {
      return false;
    }

    const fetchFeatureSet = new Set(enabledFetchedFeatures);
    const modifyFeatureSet = new Set(currentModifiedFeatures);

    for (const feature of fetchFeatureSet) {
      if (!modifyFeatureSet.has(feature)) {
        return false;
      }
    }

    return true;
  };

  const createRequestBody = values => {
    return fetchedFeatures.map(featureData => ({
      ...featureData,
      enabled: values.featureOptions.includes(featureData.feature),
    }));
  };

  const onSubmit = async values => {
    const requestBody = createRequestBody(values);
    try {
      await executeWithSpinner(
        httpPut(`/v1/company/${sfAccountCanonicalId}/company-portal-feature`, requestBody)
      );
      setFetchedFeatures(requestBody);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <></>;
  }

  return (
    !loading && (
      <Form
        initialValues={enabledFeatures}
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => {
          const customPristineChecker = pristineValidator(values?.featureOptions);
          return (
            <form onSubmit={handleSubmit}>
              <div className="mt-5">
                <h5>Features active for this company</h5>
                <br />
                {fetchedFeatures?.map(option => (
                  <div key={option.feature}>
                    <HorizontalFormRow>
                      <label className={`col-md-3`}>
                        <Field
                          name="featureOptions"
                          component="input"
                          type="checkbox"
                          value={option.feature}
                          className={`simple-check-box mr-3`}
                          disabled={!isEditable}
                        />
                        {option.feature}
                      </label>
                      <br />
                    </HorizontalFormRow>
                  </div>
                ))}
              </div>
              <button
                className="btn btn-block btn-secondary col-sm-3"
                type="submit"
                disabled={customPristineChecker}
              >
                Save
              </button>
              <br />
            </form>
          );
        }}
      />
    )
  );
};

export default FeatureForm;
