export const formatAddress = address => {
  if (address === null || address === undefined) {
    return '';
  }

  const { city, street, zipCode, houseNumber } = address;

  const addressParts = [
    street ? street : '',
    houseNumber ? houseNumber : '',
    zipCode ? zipCode : '',
    city ? city : '',
  ];

  return addressParts.filter(part => part).join(' ');
};
