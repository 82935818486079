import React from 'react';
import { Link } from 'react-router-dom';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

const LinkedSignupPage = ({ signupPages, company, offerId, error }) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  return (
    <div className="mt-4">
      <div className="text-primary">
        <h5>Linked signup pages</h5>
      </div>
      {error && <div className="alert alert-primary">{error}</div>}
      {offerId && (
        <div className="text-right">
          {isEditable && (
            <Link
              className="btn btn-primary col-2"
              state={{
                name: company?.name,
                address: company?.billingAddress,
                offerId,
              }}
              to="/companies/self-signup-manager"
            >
              Create new signup page
            </Link>
          )}
        </div>
      )}
      <div>
        <table className="table mt-2">
          <thead className="thead-light">
            <tr>
              <th>Signup Page Name</th>
              <th>Signup Page Token</th>
              <th>Signup Page Link</th>
              <th>Expiration Date</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {signupPages?.map(signupPage => {
              const signupPageUrl = `${window._env_.REACT_APP_QUALITRAIN_USER_FRONTEND_BASE_URL}/signup?companyToken=${signupPage.token}`;
              return (
                <tr key={signupPage.token}>
                  <td>{signupPage.name}</td>
                  <td>{signupPage.token}</td>
                  <td>
                    <a className="d-block" target="blank" href={signupPageUrl}>
                      {signupPageUrl}
                    </a>
                  </td>
                  <td>{signupPage.expiration}</td>
                  <td>
                    <Link
                      className="btn btn-primary"
                      to={`/companies/self-signup-manager?token=${signupPage.token}`}
                    >
                      {isEditable ? 'Update Signup Page' : 'View Signup Page'}
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LinkedSignupPage;
