//@flow
import React from 'react';
import { withPageTemplate } from '../../layout/PageTemplate';

type Props = {
  offerInfo: any,
  companyName: string,
  signupPageUrls: string,
  signupPageNames: string,
};

const AddMembershipOfferInfoForm = ({
  offerInfo,
  companyName,
  signupPageUrls,
  signupPageNames,
}: Props) => {
  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-8">
          <div className="font-weight-bold mb-1">
            <span className="text-primary mr-1">ADD MEMBERS TO OFFER OF {offerInfo.name}</span>
          </div>
          <div className="mb-2">
            <span className="text-secondary mr-2">
              You should only add new users to offers where B2C Payment is set to "NO". If you
              create user accounts for offers with B2C Payment "YES", you need to send users this
              link https://app.qualitrain.net/payment-details and inform them to log-in and update
              their payment details.
            </span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 font-weight-bold col-md-8">
          <div className="mb-1">
            <span className="text-primary mr-2">MEMBERSHIP OFFER:</span>
          </div>
          <div className="mb-2">
            <span className="text-primary mr-2">Offer ID: {offerInfo.id}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-8">
          <div className="mb-1">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>B2C Price:</td>
                  <td>{offerInfo.price != null ? offerInfo.price.amount : '0'}€</td>
                </tr>
                <tr>
                  <td>B2C Payment:</td>
                  <td>{offerInfo.b2cPayment ? 'YES' : 'NO'}</td>
                </tr>
                <tr>
                  <td>Personal Identifier:</td>
                  <td>{offerInfo.employeeInternalIdentifierLabel}</td>
                </tr>
                <tr>
                  <td>Saleforce Account:</td>
                  <td>
                    {offerInfo.sfAccountCanonicalId ? offerInfo.sfAccountCanonicalId : ''}
                    {companyName ? ` (${companyName})` : ''}
                  </td>
                </tr>
                <tr>
                  <td>Signup Page:</td>
                  <td>{signupPageUrls}</td>
                </tr>
                <tr>
                  <td>Signup Page Name:</td>
                  <td>{signupPageNames}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPageTemplate(AddMembershipOfferInfoForm, { headerTitle: 'Add Members Manager' });
