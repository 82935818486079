// @flow
import React, { useState } from 'react';
import { httpGet, httpPost } from 'app/service/http/index';
import { formatISOZonedDateTimeToUTCDateTime } from 'app/utils/format/dateTimeFormatter';
import { exportQrcodesToCsv } from 'app/utils/csv/qrcodesCsvExporter';
import GymContext from 'app/ui/gyms/gym/context/GymContext';
import QrCodeGenerator from '../../qrcodes/generator/QRCodeGenerator';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import { SUPPORTED_QR_LANGUAGES } from '../../../../utils/qrCodeTranslation/constants';

const GymQRCodesPage = () => {
  const { gym } = React.useContext(GymContext);
  const [qrCodes, setQrCodes] = useState(null);
  const [errorResult, setErrorResult] = useState(null);
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  React.useEffect(() => {
    const getQRCodes = async () => {
      if (!gym) {
        return;
      }
      setQrCodes(null);
      setErrorResult(null);
      try {
        const qrcodesResult = await httpGet(`/v1/admin/qrcode?gymId=${gym.gymId}`);
        setQrCodes(qrcodesResult);
      } catch (error) {
        console.log(`Problem fetching qrcodes for gym with EGYM gymId: ${gym.gymId}`);
        console.log(error);
        setErrorResult(error && error.message);
      }
    };
    getQRCodes();
  }, [gym]);

  const createQRCode = async () => {
    try {
      const newQrcode = await httpPost('/v1/admin/qrcode', {
        egymGymId: `${gym.gymId}`,
      });
      setQrCodes([...qrCodes, newQrcode]);
    } catch (error) {
      setErrorResult(error && error.message);
    }
  };

  return (
    <>
      <h5>List of QR Codes</h5>
      {errorResult && <div className="alert alert-primary">{errorResult}</div>}
      {qrCodes && (
        <table className="table">
          <thead>
            <tr>
              <th>QR Code ID</th>
              <th>EGYM ID</th>
              <th>Creation Date</th>
              {Object.keys(SUPPORTED_QR_LANGUAGES).map((language, index) => (
                <th key={`th-${language}-${index}`}></th>
              ))}
            </tr>
          </thead>
          <tbody>
            {qrCodes.map(r => (
              <tr key={r.qrCodeUUID}>
                <td>{r.qrCodeUUID}</td>
                <td>{r.egymGymId}</td>
                <td>{formatISOZonedDateTimeToUTCDateTime(r.creationTimestamp)}</td>
                {Object.keys(SUPPORTED_QR_LANGUAGES).map((language, index) => (
                  <td key={`${r.qrCodeUUID}-${index}`}>
                    <QrCodeGenerator
                      qrValue={r.qrCodeUUID}
                      gymId={r.egymGymId}
                      creationTimestamp={r.creationTimestamp}
                      language={language}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className="row mt-4">
        <div className="col-12 col-sm-6 col-xl-4">
          <button
            className="btn btn-secondary btn-block"
            type="button"
            onClick={() => exportQrcodesToCsv(qrCodes)}
            disabled={!qrCodes || qrCodes.length === 0}
          >
            Export to CSV
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-sm-6 col-xl-4">
          {isEditable && (
            <button className="btn btn-primary btn-block" type="button" onClick={createQRCode}>
              Create new QR Code
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default GymQRCodesPage;
