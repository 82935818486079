// @flow
import React from 'react';
import Modal from 'app/ui/common/modal/Modal';

export const DateWarningModal = ({
  modalTitle,
  onConfirm,
  onRequestClose,
  startDate,
  endDate,
  modalBody,
  startDateLabel,
  endDateLabel,
}) => (
  <Modal isOpen={true} modalTitle={modalTitle} onRequestClose={onRequestClose}>
    <div>
      <div className="row text">
        <div className="col-12 row-cols-md-3 mb-2">
          <label className="col-md-12">{modalBody}</label>
          {startDate && (
            <label className="col-md-12">
              {startDateLabel}: <b>{startDate}</b>
            </label>
          )}
          {endDate && (
            <label className="col-md-12">
              {endDateLabel}: <b>{endDate}</b>
            </label>
          )}
        </div>
      </div>
      <div className="row text">
        <div className="col-12 row-cols-md-3 mb-2">
          <label className="col-md-12">Are you sure this is correct?</label>
        </div>
      </div>
    </div>
    <button className="btn btn-secondary offset-3 col-md-6" onClick={onConfirm}>
      Yes, please save this date
    </button>
    <button className="btn btn-primary ml-2 col-md-2" onClick={onRequestClose}>
      Cancel
    </button>
  </Modal>
);
