// @flow
import {
  VALIDATION_INVALID_LOGO_SIZE,
  VALIDATION_LINKED_SIGNUP_PAGE_DUPLICATE_TOKEN,
  VALIDATION_LINKED_SIGNUP_PAGE_NOT_VALIDATED,
  VALIDATION_MULTI_ENTITY_SIGNUP_PAGE_INVALID_SLUG,
} from 'app/validation/common/errorCodes';

import {
  combineValidators,
  validateDate,
  validateIsBlank,
} from 'app/validation/common/commonValidators';

import type { LinkedSignupPage, MultiEntitySignupPage } from 'app/ui/types';

export const validate = (values: MultiEntitySignupPage) => {
  const errors = {};

  errors.name = combineValidators(values.name, true)(validateIsBlank);
  errors.slug = combineValidators(values.slug, true)(validateIsBlank, validateSlug());
  errors.dropdownLabel = combineValidators(values.dropdownLabel, true)(validateIsBlank);
  errors.logo = validateLogoSize(values.logo, 1048576);
  errors.expiration = combineValidators(values.expiration, false)(validateDate('DD-MM-YYYY HH:mm'));

  errors.linkedSignupPages = validateLinkedSignupPage(values.linkedSignupPages);

  return errors;
};

const validateSlug = () => {
  return (value: string) => {
    if (!/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/.test(value)) {
      return { code: VALIDATION_MULTI_ENTITY_SIGNUP_PAGE_INVALID_SLUG, args: {} };
    }

    return undefined;
  };
};

const validateLinkedSignupPage = (linkedSignupPages: Array<LinkedSignupPage>) => {
  const signupPageTokenSet = new Set();

  if (linkedSignupPages) {
    return linkedSignupPages.map(page => {
      let tokenError;
      let labelError;

      if (page) {
        if (signupPageTokenSet.has(page.token)) {
          return { token: { code: VALIDATION_LINKED_SIGNUP_PAGE_DUPLICATE_TOKEN, args: [] } };
        }

        tokenError = validateIsBlank(page.token);

        if (page.token) {
          signupPageTokenSet.add(page.token);

          labelError = combineValidators(page.label, true)(validateIsBlank);
          tokenError = isLinkedSignupPageValidated(page.isValid);
        }
      }

      return { token: tokenError, label: labelError };
    });
  }

  return undefined;
};

const isLinkedSignupPageValidated = isValid => {
  if (!isValid) {
    return { code: VALIDATION_LINKED_SIGNUP_PAGE_NOT_VALIDATED, args: [] };
  }
  return undefined;
};

const validateLogoSize = (logo, size) => {
  if (logo && logo.file && logo.file.size > size) {
    return { code: VALIDATION_INVALID_LOGO_SIZE, args: [size] };
  }
  return undefined;
};
