// @flow
import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { openRolesModal } from 'app/ui/user-manager/user/details/actions';

type DeleteButtonProps = {
  deleteAction: Function,
};

const DeleteButton = ({ deleteAction, ...rest }: DeleteButtonProps) => (
  <button
    {...rest}
    className="btn btn-primary"
    onClick={() => {
      if (window.confirm('Are you sure you want to delete the Role?')) {
        deleteAction();
      }
    }}
  >
    Remove
  </button>
);

type Props = {
  dispatch: Function,
  removeRoleFromUser: Function,
  removeRoleEntityPairFromUser: Function,
};

/**
 * This section renders the roles of the user.
 * It is responsible for the user.roles part of the state tree.
 *
 * Example:
 * user: {
 *   ...,
 *   roles: [
 *     {
 *       authority: 'QUALITRAIN_OPS_ADMIN',
 *     },
 *     {
 *       authority: 'COMPANY_ADMIN',
 *       entityIds: ['111', '222'],
 *     }
 *   ],
 * }
 */
const UserRolesSection = ({
  dispatch,
  removeRoleFromUser,
  removeRoleEntityPairFromUser,
  isEditable,
}: Props) => (
  <>
    <h5>Roles</h5>
    <FieldArray name="user.roles">
      {({ fields }) => (
        <>
          {fields.map((roleFieldName, roleIndex) => (
            <div key={roleIndex}>
              <Field name={roleFieldName}>
                {({ input: roleInput }) => {
                  if (!roleInput.value.entityIds || roleInput.value.entityIds.length === 0) {
                    // Role without entities: Just render the role name and a "remove" button
                    return (
                      <div className="form-row">
                        <label className="col-form-label col-sm-3">
                          {roleInput.value.authority}
                        </label>
                        <label className="col-form-label col-sm-7">&nbsp;</label>
                        <div className="col-sm-2">
                          <DeleteButton
                            deleteAction={() => removeRoleFromUser(roleInput.value.authority)}
                            disabled={!isEditable}
                          />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      // Role with entities: Render the role name, the entity (i.e. company) and "remove" button
                      <FieldArray name={`${roleFieldName}.entityIds`}>
                        {({ fields: entitiesFields }) => (
                          <>
                            {entitiesFields.map((entityFieldName, entityIndex) => (
                              <Field key={entityIndex} name={entityFieldName}>
                                {({ input: entityId }) => (
                                  <div className="form-row">
                                    <label className="col-form-label col-sm-3">
                                      {roleInput.value.authority}
                                    </label>
                                    <label className="col-form-label col-sm-7">
                                      (Company ID: {entityId.value})
                                    </label>
                                    <div className="col-sm-2">
                                      <DeleteButton
                                        deleteAction={() =>
                                          removeRoleEntityPairFromUser(
                                            roleInput.value.authority,
                                            entityId.value
                                          )
                                        }
                                        disabled={!isEditable}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Field>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    );
                  }
                }}
              </Field>
            </div>
          ))}
        </>
      )}
    </FieldArray>
    <div className="form-group form-row">
      <div className="col-sm-3">
        <button
          className="btn btn-secondary"
          onClick={() => dispatch(openRolesModal())}
          disabled={!isEditable}
        >
          Add another Role
        </button>
      </div>
    </div>
  </>
);

export default UserRolesSection;
