import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { withPageTemplate } from '../../layout/PageTemplate';
import { httpGet } from '../../../service/http';
import SpinnerContext from '../../common/spinner/SpinnerContext';
import CompanyInformation from './CompanyInformation';
import LinkedOffer from './LinkedOffer';
import FeatureForm from './FeatureForm';
import LinkedSignupPage from './LinkedSignupPage';

const SettingsPage = () => {
  const { sfAccountCanonicalId } = useParams();
  const { executeWithSpinner } = useContext(SpinnerContext);
  const [linkedOffers, setLinkedOffers] = React.useState();
  const [linkedSignupPages, setLinkedSignupPages] = React.useState();
  const [sfDetail, setSfDetail] = React.useState();
  const [loading, setLoading] = useState(true);

  const getCompanyInformation = linkedSignupPages => {
    const companyDetail = linkedSignupPages.find(page => page.membershipOffer);
    const sfDetail = {};
    if (companyDetail) {
      sfDetail.sfAccountCanonicalId = companyDetail.membershipOffer.sfAccountCanonicalId;
      sfDetail.sfAccountCountryCode = companyDetail.membershipOffer.sfAccountCountryCode;
      sfDetail.sfAccountCompanyName = companyDetail.name;
    }
    return sfDetail;
  };

  const removeDuplicates = (array, idType) => {
    const seenIds = new Set();
    return array.filter(item => {
      if (seenIds.has(item[idType])) {
        return false;
      } else {
        seenIds.add(item[idType]);
        return true;
      }
    });
  };

  const fetchLinkedSignupPagesAndOffers = useCallback(async () => {
    try {
      const linkedSignupPages = await httpGet(
        `/v1/signup-page?sfAccountCanonicalId=${sfAccountCanonicalId}`
      );
      const pagesWithActiveOffer = linkedSignupPages.filter(signupPage => signupPage.activeOffer);
      const activeOffers = pagesWithActiveOffer
        .map(signupPage => signupPage.membershipOffer)
        .sort((a, b) => b.id - a.id);
      setLinkedOffers(removeDuplicates(activeOffers, 'id'));
      setSfDetail(getCompanyInformation(linkedSignupPages));
      setLinkedSignupPages(removeDuplicates(linkedSignupPages, 'token'));
    } catch (error) {
      console.error('Error fetching linked signup pages data', error);
    } finally {
      setLoading(false);
    }
  }, [sfAccountCanonicalId]);

  useEffect(() => {
    executeWithSpinner(fetchLinkedSignupPagesAndOffers());
  }, [executeWithSpinner, fetchLinkedSignupPagesAndOffers]);

  if (loading) {
    return <></>;
  }
  return (
    <div>
      <CompanyInformation sfDetail={sfDetail} />
      {sfAccountCanonicalId !== 'null' && (
        <FeatureForm sfAccountCanonicalId={sfAccountCanonicalId} />
      )}
      <LinkedOffer offers={linkedOffers} headingText="Active linked offers" />
      <LinkedSignupPage signupPages={linkedSignupPages} />
    </div>
  );
};

export default withPageTemplate(SettingsPage, {
  headerTitle: 'Company Settings',
});
