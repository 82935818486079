//@flow
import { dateTimeToDateTimeCET } from 'app/utils/date/dateUtil';
import { downloadFileFromUrl } from 'app/utils/file/fileUtil';
import React from 'react';
import { Link } from 'react-router-dom';
import UploadTaskReportLink from './components/UploadTaskReportLink';

export type Task = {
  id: string,
  state: string,
  failureReason: string,
  uploadedFileName: String,
  persistedCheckinsCount: number,
  failedCheckinsCount: number,
  createdAt: string,
  completedAt: string,
};

type Props = {
  tasks: Array<Task>,
};

const UploadTasksTable = ({ tasks }: Props) => {
  const downloadCheckins = (taskId, filename, exportedFileName) => {
    downloadFileFromUrl(`/v1/checkin/upload-task/${taskId}/export/${filename}`, exportedFileName);
  };
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Submitted file</th>
            <th>Created at</th>
            <th>State</th>
            <th>Reports</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map(task => (
            <tr key={task.id}>
              <td>
                <Link
                  to="#"
                  onClick={() => downloadCheckins(task.id, 'submitted.csv', task.uploadedFileName)}
                >
                  {task.uploadedFileName}
                </Link>
              </td>
              <td>{dateTimeToDateTimeCET(task.createdAt)}</td>
              <td>
                {task.state}
                &nbsp;
                {task.failedCheckinsCount > 0 && task.state === 'COMPLETED' && (
                  <i
                    className="fa fa-exclamation-triangle warning-icon"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Some checkins had errors. Please check the failed report."
                  />
                )}
                {task.state === 'FAILED' && (
                  <i
                    className="fas fa-info-circle"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={task.failureReason}
                  />
                )}
              </td>
              <td>
                {task.persistedCheckinsCount !== 0 && (
                  <UploadTaskReportLink
                    className="success-report-link"
                    taskId={task.id}
                    reportMessage={`${task.persistedCheckinsCount} check-ins uploaded`}
                    filename={'successful.csv'}
                    exportedFilename={`Report_success_${task.uploadedFileName}`}
                  />
                )}
                {task.failedCheckinsCount !== 0 && (
                  <UploadTaskReportLink
                    className="failed-report-link"
                    taskId={task.id}
                    reportMessage={`${task.failedCheckinsCount} check-ins failed`}
                    filename={'failed.csv'}
                    exportedFilename={`Report_failed_${task.uploadedFileName}`}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default UploadTasksTable;
