// @flow
import { generateCsv } from 'app/utils/csv/csvUtil';
import { downloadCsvFile } from 'app/utils/file/fileUtil';

export type UserExportInfo = {
  userId: string,
  email: string,
  gender: string,
  firstName: string,
  lastName: string,
};

const COLUMN_DEFINITIONS = [
  { headerLabel: 'User ID', properties: ['userId'] },
  { headerLabel: 'Email Address', properties: ['email'] },
  { headerLabel: 'Gender', properties: ['gender'] },
  { headerLabel: 'First Name', properties: ['firstName'] },
  { headerLabel: 'Last Name', properties: ['lastName'] },
];

export const generateUsersCsv = (users: Array<UserExportInfo>) => {
  return generateCsv(COLUMN_DEFINITIONS, users);
};

export const exportSearchUsersToCsv = (users: Array<UserExportInfo>) => {
  const csv = generateUsersCsv(users);
  downloadCsvFile(csv, 'users.csv');
};
