import React, { useState } from 'react';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import { httpPost } from '../../../service/http';
import SpinnerContext from '../../common/spinner/SpinnerContext';

type Props = {
  chargeRequest: any,
  onComplete: Function,
};
const NewPaymentLinkButton = ({ chargeRequest, onComplete }: Props) => {
  const [isClicked, setIsClicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { executeWithSpinner } = React.useContext(SpinnerContext);

  const onConfirm = chargeRequest => {
    setIsClicked(true);
    executeWithSpinner(
      httpPost(`/v1/payment/charge/${chargeRequest.chargeId}/payment-link`).then(onComplete)
    );
    setOpenModal(false);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary float-right mr-4"
        onClick={() => {
          setOpenModal(true);
        }}
        disabled={isClicked}
      >
        New payment link
      </button>
      {openModal && (
        <ConfirmationModal
          isOpen={true}
          modalTitle="Creating new payment link!"
          yesButtonText="Confirm"
          noButtonText="Close"
          onConfirm={() => onConfirm(chargeRequest)}
          onCancel={() => {
            setOpenModal(false);
          }}
        >
          <div>
            <div className="row text">
              <div className="col-12 row-cols-md-3 mb-2">
                <label className="col-md-12">
                  <p>
                    Any active links for this period will be expired, the new one will be emailed to
                    the user.
                  </p>
                  <p>
                    You can max. create 9 manual new payment links per charge request. Please be
                    mindful with this feature.
                  </p>
                </label>
              </div>
            </div>
          </div>
        </ConfirmationModal>
      )}
    </>
  );
};

export default NewPaymentLinkButton;
