// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import gear from 'app/styles/img/gear.svg';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

type SignupPage = {
  name: string,
  membershipOfferId: number,
  activeOffer: boolean,
  customerId: number,
  token: string,
  b2cPayment: boolean,
};

type Props = {
  pages: Array<SignupPage>,
  errorResult?: string,
};

const SignupPageSearchResult = ({ pages, errorResult }: Props) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  const getTextStyle = active => {
    return active ? {} : { color: 'silver' };
  };

  return (
    <div className="mt-4 table-responsive">
      {errorResult && <div className="alert alert-primary">{errorResult}</div>}
      <label>Search Results</label>
      {!errorResult && pages && (
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th>Offer ID</th>
              <th>{'Signup Page Name'}</th>
              <th>SalesForce Account Canonical ID</th>
              <th>Active Offer</th>
              <th />
              <th />
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {pages.map(page => (
              <tr
                key={`${page.membershipOfferId}${page.token}`}
                style={getTextStyle(page.activeOffer)}
              >
                <td>{page.membershipOfferId}</td>
                <td>{page.name}</td>
                <td>{page.sfAccountCanonicalId}</td>
                <td>{page.activeOffer ? 'yes' : 'no'}</td>
                <td>
                  <Link
                    className="btn btn-primary"
                    to={`/companies/offer-manager/${page.membershipOfferId}/details`}
                  >
                    {isEditable ? 'Edit Offer' : 'View Offer'}
                  </Link>
                </td>
                <td>
                  {page.token && (
                    <Link
                      className="btn btn-primary"
                      to={`/companies/self-signup-manager?token=${page.token}`}
                    >
                      {isEditable ? 'Edit Signup Page' : 'View Signup Page'}
                    </Link>
                  )}
                </td>
                {isEditable && (
                  <td>
                    {!page.b2cPayment && (
                      <Link
                        className="btn btn-primary"
                        to={`/companies/add-members?membershipOfferId=${page.membershipOfferId}`}
                      >
                        Add Members
                      </Link>
                    )}
                  </td>
                )}
                {isEditable && (
                  <td>
                    <Link
                      className="btn btn-primary"
                      to={`/companies/cancel-all-memberships?sfAccountCanonicalId=${page.sfAccountCanonicalId}`}
                    >
                      Cancel/Move all memberships
                    </Link>
                  </td>
                )}

                <td style={{ verticalAlign: 'middle' }}>
                  <Link to={`/companies/${page.sfAccountCanonicalId}/setting`}>
                    <img
                      style={{ minWidth: '2rem' }}
                      src={gear}
                      alt={'Company Settings'}
                      className="img-fluid"
                    />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SignupPageSearchResult;
