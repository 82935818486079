// @flow
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import OfferManager from '../offer-details/OfferManager';
import EligitablityRules from '../eligibility-rules/EligibilityRules';
import EditAllowlist from '../allowlist/EditAllowlist';
import Logs from '../Logs/Logs';

const OfferManagerSubRoutes = () => (
  <Routes>
    <Route path="details" element={<OfferManager />} />
    <Route path="eligibility-rules" element={<EligitablityRules />} />
    <Route path="edit-allowlist" element={<EditAllowlist />} />
    <Route path="logs" element={<Logs />} />
    <Route index element={<Navigate to="details" />} />
  </Routes>
);

export default OfferManagerSubRoutes;
