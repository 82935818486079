// @flow
import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { OVERVIEW_STEP, PayoutStepsInfo } from 'app/ui/gyms/gym/payout/PayoutStepsInfo';
import { ExtractLastPathSegment } from './PayoutUtils';

type Props = {
  currentPath: string,
};

const PayoutStepper = ({ currentPath }: Props) => {
  const [steps, setSteps] = useState(null);

  const updateSteps = useCallback((stepNumber, steps) => {
    const newSteps = steps ? [...steps] : generateSteps();
    let stepCounter = 0;

    newSteps.forEach(() => {
      if (stepCounter === stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: true,
          selected: true,
          completed: false,
        };
        stepCounter++;
      } else if (stepCounter < stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: true,
          completed: true,
        };
        stepCounter++;
      } else {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: false,
          completed: false,
        };
        stepCounter++;
      }
    });

    return newSteps;
  }, []);

  useEffect(() => {
    const currentStepNumber = getStepNumberByUrl(currentPath);
    setSteps(steps => updateSteps(currentStepNumber - 1, steps));
  }, [currentPath, updateSteps]);

  const getStepNumberByUrl = (currentPath: string) => {
    const currentStep = PayoutStepsInfo.get(ExtractLastPathSegment(currentPath));
    return currentStep ? currentStep.number : PayoutStepsInfo.get(OVERVIEW_STEP).number;
  };

  const generateSteps = () => {
    return [...PayoutStepsInfo.values()].map((step, index) => {
      return {
        label: step.label,
        completed: false,
        highlighted: index === 0,
        selected: index === 0,
      };
    });
  };

  return (
    <>
      <div className="stepper-container">
        <div className="stepper-wrapper">
          {steps &&
            steps.map((step, index) => {
              return (
                <div className="step-wrapper" key={index}>
                  <div
                    className={`step-number ${
                      step.selected ? 'step-number-active' : 'step-number-disabled'
                    }`}
                  >
                    {step.completed ? (
                      <span>
                        <i className="fas fa-check" />
                      </span>
                    ) : (
                      index + 1
                    )}
                  </div>
                  <div className={`step-label ${step.highlighted && 'step-label-active'}`}>
                    {step.label}
                  </div>
                  <div
                    className={
                      index !== steps.length - 1
                        ? `divider-line ${
                            step.completed ? 'divider-line-active' : 'divider-line-disabled'
                          }`
                        : ''
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default PayoutStepper;
