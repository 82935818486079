// @flow
import React from 'react';
import type { MultiEntitySignupPage } from '../../types';
import { Link } from 'react-router-dom';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

type Props = {
  pages: Array<MultiEntitySignupPage>,
  errorResult?: string,
};

const MultiEntitySignupPagesResult = ({ pages, errorResult }: Props) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  return (
    <div className="mt-4 table-responsive">
      {errorResult && <div className="alert alert-primary">{errorResult}</div>}
      <label>Search Results</label>
      {!errorResult && pages.length > 0 && (
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th>Page name</th>
              <th>Slug</th>
              <th>Dropdown label</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pages.map(page => (
              <tr key={`${page.id}`}>
                <td>{page.name}</td>
                <td>{page.slug}</td>
                <td>{page.dropdownLabel}</td>
                <td>
                  <Link className="btn btn-primary" to={`../${page.id}`}>
                    {isEditable ? 'Edit page' : 'View page'}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MultiEntitySignupPagesResult;
