// @flow
import type Moment from 'moment';
import moment from 'moment-timezone';

export const CET_TIMEZONE = 'Europe/Berlin';

/**
 * Given a reference date (i.e. current date) and the signup deadline configuration,
 * calculates what is the earliest possible membership start date.
 *
 * @param today the reference date (this would normally be the current date)
 * @param signupDeadlineMonth the month of the deadline configuration
 * @param signupDeadlineDay the day of the deadline configuration
 */
export const getNextPossibleMembershipStartDate = (
  today: Moment,
  signupDeadlineMonth: number,
  signupDeadlineDay: number
): Moment => {
  const normalizedDeadlineDay = normalizeDeadlineDay(signupDeadlineDay, today.clone());

  const beginningOfCurrentMonth = today.clone().date(1);
  const referenceDay = today.date();

  // add the deadlineMonths (negated)
  const firstPossibleStartDate = beginningOfCurrentMonth.add(-signupDeadlineMonth, 'months');
  if (signupDeadlineDay < 0) {
    firstPossibleStartDate.add(1, 'months');
  }
  if (referenceDay > normalizedDeadlineDay) {
    // +1 month since we are after the deadline
    firstPossibleStartDate.add(1, 'months');
  }

  return firstPossibleStartDate;
};

/**
 * The deadline for sign up could either be:
 * 1) the signupDeadlineDay of the current month (if the current day is <= signupDeadlineDay)
 * 2) the signupDeadlineDay in the following month (if the current day is > signupDeadlineDay)
 *
 * NOTE: It does not depend on the signupDeadlineMonth part of the configuration.
 *
 * @param today the reference date (this would normally be the current date)
 * @param signupDeadlineDay the day of the deadline configuration
 */
export const getNextSignupDeadline = (today: Moment, signupDeadlineDay: number): Moment => {
  // beginning of month
  const currentMonth = today.clone().date(1);
  const normalizedDeadlineDay = normalizeDeadlineDay(signupDeadlineDay, currentMonth);

  const nextSignupDeadlineDate = today.clone().date(normalizedDeadlineDay);
  if (today.date() > normalizedDeadlineDay) {
    // beginning of next month
    const nextMonth = currentMonth.clone().add(1, 'months');
    const normalizedDeadlineDayNextMonth = normalizeDeadlineDay(signupDeadlineDay, nextMonth);
    nextSignupDeadlineDate.date(1).add(1, 'months').date(normalizedDeadlineDayNextMonth);
  }

  return nextSignupDeadlineDate;
};

/**
 * Normalizes the deadline day.
 *
 * It does the following:
 * 1) If the deadline is negative, it will convert it to a positive value, by subtracting the days from the "total number of days in the month".
 * 2) If the deadline is positive, there is no change of the month, nor the date.
 * 3) If the deadline turns out to be bigger than the "total number of days in the month", sets it to the last day of the month.
 * 4) If the deadline turns out to be less than 1, sets it to the 1st of the month.
 *
 * @param deadlineDay the deadline day, as configured for the company.
 * @param month the reference month (could be any day in the reference month)
 * @returns {number} the deadline after normalization
 */
const normalizeDeadlineDay = (deadlineDay, month) => {
  const lastDayOfMonth = month.clone().add(1, 'months').date(0).date();

  let resultDeadline = deadlineDay;

  // support for negative days in the configuration
  if (resultDeadline < 0) {
    resultDeadline = lastDayOfMonth + resultDeadline;
  }

  // make sure there is no overflow (less than 1 or more than 'total number of days in the month')
  resultDeadline = Math.max(resultDeadline, 1);
  resultDeadline = Math.min(lastDayOfMonth, resultDeadline);

  return resultDeadline;
};

/** Converts a date from DD-MM-YYYY to YYYY-MM-DD (ISO) format. */
export const dateToISODate = (date: string): string => {
  if (date && date.length === 10) {
    return `${date.substring(6, 10)}-${date.substring(3, 5)}-${date.substring(0, 2)}`;
  }
  return date;
};

/**
 * Converts a dateTime in ISO format to a DD.MM.YYYY Date (assuming it is in CET).
 * Example:
 * - input: '2019-08-31T22:00:00Z'
 * - output: '01.09.2019'
 * NOTE:
 * In the example, the dateTime represents 10pm on 31.08.2019 in UTC.
 * However, this is 00:00 on 01.09.3019 in CET.
 * Therefore the output should be 01.09.2019 and not 31.08.2019.
 */
export const dateTimeToDateCET = (dateTime: string): string => {
  if (dateTime) {
    return moment(dateTime).tz(CET_TIMEZONE).format('DD.MM.YYYY');
  } else {
    return '';
  }
};

export const dateTimeToDate = (dateTime: string, timeZoneId: string): string => {
  if (dateTime) {
    return moment(dateTime).tz(timeZoneId).format('DD.MM.YYYY');
  } else {
    return '';
  }
};

export const dateTimeToDateTimeCET = (dateTime: string): string => {
  if (dateTime) {
    return moment(dateTime).tz(CET_TIMEZONE).format('DD.MM.YYYY HH:mm');
  } else {
    return '';
  }
};

/**
 * Converts a dateTime in ISO format to a DD.MM.YYYY HH:mm:ss Date (assuming it is in CET).
 * Example:
 * - input: '2019-08-31T22:00:00Z'
 * - output: '01.09.2019 00:00:00'
 */
export const dateTimeToDateTimeWithSecondsCET = (dateTime: string): string => {
  if (dateTime) {
    return moment(dateTime).tz(CET_TIMEZONE).format('DD.MM.YYYY HH:mm:ss');
  } else {
    return '';
  }
};

// day should be a value between 1 and 31 (can have a leading zero)
const DAY_REGEX = /^(0?[1-9]|[12][0-9]|3[01])$/;
export const isValidDay = (value: string): boolean => {
  return DAY_REGEX.test(value);
};

// month should be a value between 1 and 12 (can have a leading zero)
const MONTH_REGEX = /^(0?[1-9]|1[012])$/;
export const isValidMonth = (value: string): boolean => {
  return MONTH_REGEX.test(value);
};

// year has 4 digits and cannot start with zero
const YEAR_REGEX = /^[1-9][0-9]{3}$/;
export const isValidYear = (value: string): boolean => {
  return YEAR_REGEX.test(value);
};

/**
 * Converts the day/month/year into DD-MM-YYYY date string.
 * Adds leading zeroes if the day or the month has only one digit.
 */
export const toDate = (day: string, month: string, year: string): boolean => {
  const d = `${day}`;
  const m = `${month}`;
  const y = `${year}`;
  return `${d.length === 1 ? '0' : ''}${d}-${m.length === 1 ? '0' : ''}${m}-${y}`;
};

/**
 * Converts UTC date presentation to CET with given date format.
 * @param date
 * @param dataFormat
 * @returns {string|*}
 */
export const dateToCET = (date: string, dataFormat: string = 'DD-MM-YYYY HH:mm'): string => {
  if (date) {
    return moment(date).tz(CET_TIMEZONE).format(dataFormat);
  } else {
    return '';
  }
};

/**
 * Converts CET date to UTC format.
 * @param date
 * @param dateFormat
 * @returns {string}
 */
export const convertCetDateToUtc = (date: string, dateFormat: string = 'DD-MM-YYYY'): string => {
  if (date) {
    return moment.tz(date, dateFormat, CET_TIMEZONE).utc().format();
  } else {
    return '';
  }
};

export const convertGivenLocalDateToUtc = (
  date: string,
  dateFormat: string,
  timeZoneId: string
): string => {
  if (date) {
    return moment.tz(date, dateFormat, timeZoneId).utc().format();
  } else {
    return '';
  }
};

/**
 * Check is date valid
 * @param dateString
 * @param dateFormat
 * @returns validation result
 */
export const isValidDate = (dateString: string, dateFormat: string = 'DD-MM-YYYY') => {
  return moment(dateString, dateFormat, true).isValid();
};

/**
 * Check is date in UTC in the past
 * @param date
 * @returns validation result
 */
export const isUtcDateInPast = (date: string): boolean => {
  return moment().utc().isAfter(moment(date), 'hours');
};

/**
 * Returns now in UTC timezone
 * @returns string representation of date
 */
export const nowInUTC = (): string => {
  return moment().utc().format();
};
