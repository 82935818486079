//@flow
import React from 'react';

type Props = {
  defaultValue: any,
  onChange: any,
};

const AddMembersInputField = ({ defaultValue, onChange }: Props) => {
  return (
    <>
      <input className="form-control" type="text" defaultValue={defaultValue} onChange={onChange} />
    </>
  );
};

export default AddMembersInputField;
