// @flow
import type { MembershipWrapperType, MembershipType } from 'app/ui/types';
import moment from 'moment-timezone';

export const isFutureMembership = (membership: MembershipWrapperType) => {
  return !!(
    membership.membership.membershipStartTimestamp &&
    moment(membership.membership.membershipStartTimestamp).isAfter(moment(new Date()))
  );
};

export const isActiveMembership = (membership: MembershipWrapperType) => {
  const now = moment(new Date());
  return !!(
    (!membership.membership.membershipStartTimestamp ||
      moment(membership.membership.membershipStartTimestamp).isBefore(now)) &&
    (!membership.membership.membershipEndTimestamp ||
      moment(membership.membership.membershipEndTimestamp).isAfter(now))
  );
};

export const isPastMembership = (membership: MembershipWrapperType) => {
  const now = moment(new Date());
  return !!(
    (!membership.membership.membershipStartTimestamp ||
      moment(membership.membership.membershipStartTimestamp).isBefore(now)) &&
    membership.membership.membershipEndTimestamp &&
    moment(membership.membership.membershipEndTimestamp).isBefore(now)
  );
};

/**
 * Compares two memberships by startTimestamp.
 * Memberships without a startTimestamp are considered "smaller" than memberships with a startTimestamp.
 */
export const compareByStartTimestamp = (
  m1: MembershipWrapperType,
  m2: MembershipWrapperType
): number => {
  const m1Start = m1.membership.membershipStartTimestamp;
  const m2Start = m2.membership.membershipStartTimestamp;
  if (!m1Start && !m2Start) return 0;
  if (!m1Start) return -1;
  if (!m2Start) return 1;
  const moment1 = moment(m1Start);
  const moment2 = moment(m2Start);
  if (moment1.isBefore(moment2)) {
    return -1;
  }
  if (moment1.isAfter(moment2)) {
    return 1;
  }
  return 0;
};

export const reverseComparator =
  (comparator: Function): Function =>
  (a: any, b: any): number =>
    -comparator(a, b);

/**
 * Converts a date into a timestamp (beginning of that day in CET).
 * One use case is to convert the "membership start time" into a timestamp which is at the beginning of the day.
 */
export const dateToISODateTimeBeginningOfDayCET = (date: string): string => {
  if (!date) {
    return '';
  }
  const year = date.substring(6, 10);
  const month = date.substring(3, 5);
  const day = date.substring(0, 2);
  const beginningOfDay = moment.tz(`${year}-${month}-${day} 00:00:00`, 'Europe/Berlin');
  return beginningOfDay.utc().format();
};

/**
 * Converts a date into a timestamp (at the middle of that day in CET).
 * One use case is for the Paperlist Check-ins.
 */
export const dateToISODateTimeMiddleOfDayCET = (date: string): string => {
  if (!date) {
    return '';
  }
  const year = date.substring(6, 10);
  const month = date.substring(3, 5);
  const day = date.substring(0, 2);
  const middleOfDay = moment.tz(`${year}-${month}-${day} 12:00:00`, 'Europe/Berlin');
  return middleOfDay.utc().format();
};

/**
 * Converts a date into a timestamp (end of that day in CET).
 * One use case is to convert the "membership end date" into a timestamp which is at the end of that day.
 *
 */
export const dateToISODateTimeEndOfDayCET = (date: string): string => {
  if (!date) {
    return '';
  }
  const year = date.substring(6, 10);
  const month = date.substring(3, 5);
  const day = date.substring(0, 2);
  const endOfDay = moment.tz(`${year}-${month}-${day} 23:59:59`, 'Europe/Berlin');
  return endOfDay.utc().format();
};

export const membershipCancellationDropdownOptions = (membership: MembershipType = '') => {
  const options = [
    { value: '', label: '' },
    { value: 'UPON_EMPLOYEES_REQUEST', label: 'UPON EMPLOYEES REQUEST' },
    { value: 'END_OF_EMPLOYMENT', label: 'END OF EMPLOYMENT' },
    { value: 'B2C_END_OF_EMPLOYMENT', label: 'B2C END OF EMPLOYMENT' },
    { value: 'NOT_ENTITLED_FOR_PARTICIPATION', label: 'NOT ENTITLED FOR PARTICIPATION' },
    { value: 'HEALTH_CONCERNS_ISSUES', label: 'HEALTH CONCERNS/ISSUES' },
    { value: 'PREFER_OUTDOOR_HOME_WORKOUTS', label: 'PREFER_OUTDOOR/HOME_WORKOUTS' },
    { value: 'INACTIVE_NOT_ENOUGH_TIME', label: 'INACTIVE/NOT_ENOUGH_TIME' },
    { value: 'NETWORK_NOT_ATTRACTIVE', label: 'NETWORK NOT ATTRACTIVE' },
    { value: 'TOO_EXPENSIVE', label: 'TOO EXPENSIVE' },
    { value: 'BAD_EXPERIENCE', label: 'BAD EXPERIENCE' },
    { value: 'COMPANY_CONTRACT_ENDED', label: 'COMPANY CONTRACT ENDED' },
    { value: 'B2C_PAYMENT_REFUSED', label: 'B2C PAYMENT REFUSED' },
    { value: 'PLUS1_REFUSED_DATA_NOT_MATCHING', label: 'PLUS1 REFUSED DATA NOT MATCHING' },
    { value: 'PLUS1_ID_CHECK_INVALID', label: 'PLUS1 ID CHECK INVALID' },
    { value: 'FRAUD', label: 'FRAUD' },
    { value: 'MOVED_TO_NEW_OFFER', label: 'MOVED TO NEW OFFER' },
    { value: 'OTHER_REASON', label: 'OTHER REASON' },
  ];

  if (membership.referrerMembershipUuid) {
    options.push({ value: 'END_OF_REFERRER_MEMBERSHIP', label: 'END_OF_REFERRER_MEMBERSHIP' });
  }

  // Enable editing cancellation reason from the backend not in the options list
  const cancellationReason = membership.membershipCancellationReason;
  if (cancellationReason && cancellationReason !== 'EMPTY') {
    if (!options.find(option => option['value'] === cancellationReason)) {
      options.push({
        value: cancellationReason,
        label: cancellationReason,
      });
    }
  }

  return options;
};
