// @flow
import { generateCsv } from 'app/utils/csv/csvUtil';
import { downloadCsvFile } from 'app/utils/file/fileUtil';
import type { Member } from 'app/ui/companies/add-members/AddMembersForm';

const COLUMN_DEFINITIONS = [
  { headerLabel: 'First Name', properties: ['member.firstName'] },
  { headerLabel: 'Last Name', properties: ['member.lastName'] },
  { headerLabel: 'Gender', properties: ['member.gender'] },
  { headerLabel: 'Date of birth', properties: ['member.dateOfBirth'] },
  { headerLabel: 'Email Address', properties: ['member.email'] },
  { headerLabel: 'Membership start date', properties: ['member.membershipStartDate'] },
  { headerLabel: 'Activation code', properties: ['member.activationCode'] },
  { headerLabel: 'Offer ID', properties: ['member.membershipOfferId'] },
  { headerLabel: 'Company alias', properties: ['companyName'] },
  {
    headerLabel: 'Upload result',
    properties: ['member.uploadErrors'],
    transform: uploadErrors =>
      uploadErrors && uploadErrors.length > 0 ? 'failed to upload' : 'uploaded successfully',
  },
];

export const generateMembersCsv = (members, companyName) => {
  const membersWithCompanyInfo = members.map(member => ({ member, companyName }));
  return generateCsv(COLUMN_DEFINITIONS, membersWithCompanyInfo);
};

export const exportNewlyAddedMembersToCsv = (members: Array<Member>, companyName: string) => {
  const csv = generateMembersCsv(members, companyName);
  downloadCsvFile(csv, 'members.csv');
};
