import { useCallback, useState } from 'react';
import { httpPostUpload } from 'app/service/http';

const LinkMembership = () => {
  const [sfAccountCanonicalId, setSfAccountCanonicalId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [linkedMembersCount, setLinkedMembersCount] = useState();
  const [error, setError] = useState();

  const linkMembers = useCallback(async () => {
    const matchCorporateEmployeeURL = `/admin/v1/company/${sfAccountCanonicalId}/link-members`;
    await httpPostUpload(matchCorporateEmployeeURL, selectedFile)
      .then(resp => {
        setLinkedMembersCount(resp);
      })
      .catch(err => {
        const errorResponse = err?.userServiceGeneralErrors[0]?.message;
        if (!errorResponse) return;
        const jsonArray = JSON.parse(errorResponse);
        setError(jsonArray);
      });
  }, [selectedFile, sfAccountCanonicalId]);

  const submit = async e => {
    e.preventDefault();
    setLinkedMembersCount(null);
    setError(null);
    await linkMembers();
  };

  return (
    <div>
      <section className="mb-5">
        <h5>Upload matching file</h5>
        <p>
          Please find an example csv sheet to link members here:{' '}
          <a href="https://storage.googleapis.com/qualitrain-admin-assets/corporate_employee_mapping.csv">
            Download CSV template
          </a>
        </p>
        <form onSubmit={submit}>
          <div className="form-group">
            <input
              id="sfAccountCanonicalId"
              name="sfAccountCanonicalId"
              type="text"
              className="form-control w-50"
              placeholder="Enter SF Canonical ID"
              onChange={e => setSfAccountCanonicalId(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              id="membershipFile"
              name="membershipFile"
              placeholder="Enter SF Canonical ID"
              type="file"
              accept=".csv"
              className="text-primary w-50"
              onChange={e => setSelectedFile(e.target.files[0])}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={!sfAccountCanonicalId || selectedFile === null}
          >
            Link Members with HRIS Records
          </button>
        </form>
      </section>

      <section>
        <h5>Results</h5>
        {(linkedMembersCount || linkedMembersCount === 0) && (
          <p className="mb-1"> {linkedMembersCount} members were successfully linked</p>
        )}
        {error && (
          <div>
            <p className="mb-3">
              Duplicate Entries found for the following corporate employee ID's, Please check and
              re-upload the file:
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th>Corporate Employee ID</th>
                  <th>Egym ID</th>
                </tr>
              </thead>
              <tbody>
                {error?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.corporateEmployeeId}</td>
                    <td>{item.egymAccountId}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </section>
    </div>
  );
};

export default LinkMembership;
