// @flow
import React from 'react';
import {
  hasUploadError,
  isSuccessfullyUploaded,
} from 'app/ui/gyms/gym/paperlist-checkin/validator';
import type { PaperlistCheckinType } from 'app/ui/gyms/gym/paperlist-checkin/types';

type Props = {
  paperlistCheckin: PaperlistCheckinType,
};

const PaperlistCheckinUploadStatus = ({ paperlistCheckin }: Props) => (
  <>
    {isSuccessfullyUploaded(paperlistCheckin) && (
      <div className="alert alert-success m-0">
        <i className="fas fa-check-circle fa-lg text-success" />
        &nbsp;
        {paperlistCheckin.uploadResult.message}
      </div>
    )}
    {hasUploadError(paperlistCheckin) && (
      <div className="alert alert-danger m-0">
        <i className="fas fa-times-circle fa-lg text-danger" />
        &nbsp;
        {paperlistCheckin.uploadResult.message}
      </div>
    )}
  </>
);

export default PaperlistCheckinUploadStatus;
