// @flow
import React from 'react';
import PaperlistCheckinRow from 'app/ui/gyms/gym/paperlist-checkin/PaperlistCheckinRow';
import ClearCheckinsConfirmationModal from 'app/ui/gyms/gym/paperlist-checkin/components/ClearCheckinsConfirmationModal';
import { initialInput } from 'app/ui/gyms/gym/paperlist-checkin/initialValues';
import PlusIcon from 'app/ui/gyms/gym/paperlist-checkin/components/PlusIcon';
import {
  hasError,
  UPLOAD_STATUS_ERROR,
  UPLOAD_STATUS_SUCCESS,
  validatePaperlistCheckin,
} from 'app/ui/gyms/gym/paperlist-checkin/validator';
import SaveCheckinsConfirmationModal from 'app/ui/gyms/gym/paperlist-checkin/components/SaveCheckinsConfirmationModal';
import GymContext from 'app/ui/gyms/gym/context/GymContext';
import { httpPost } from 'app/service/http';
import { toDate } from 'app/utils/date/dateUtil';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import PaperlistCheckinsUploadProgress from 'app/ui/gyms/gym/paperlist-checkin/components/PaperlistCheckinsUploadProgress';
import { dateToISODateTimeMiddleOfDayCET } from 'app/ui/user-manager/user/memberships/membershipUtils';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

export const WORKFLOW_STATE_INITIAL = 'INITIAL';
export const WORKFLOW_STATE_SAVING = 'SAVING';
export const WORKFLOW_STATE_AFTER_SAVE = 'AFTER_SAVE';

const PaperlistCheckinPage = () => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const { gym } = React.useContext(GymContext);
  const [nextId, setNextId] = React.useState(1);
  const [paperlistCheckins, setPaperlistCheckins] = React.useState([]);
  const [workflowState, setWorkflowState] = React.useState(WORKFLOW_STATE_INITIAL);
  const [countSuccessfulUploads, setCountSuccessfulUploads] = React.useState(0);
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const [countErrorUploads, setCountErrorUploads] = React.useState(0);
  const [isClearCheckinsConfirmationModalOpen, setClearCheckinsConfirmationModalOpen] =
    React.useState(false);
  const [isSaveCheckinsConfirmationModalOpen, setSaveCheckinsConfirmationModalOpen] =
    React.useState(false);

  React.useEffect(() => {
    setPaperlistCheckins(paperlistCheckins => [
      ...paperlistCheckins,
      {
        ...initialInput,
        id: 0,
      },
    ]);
  }, []);

  const getNextId = () => {
    const id = nextId;
    setNextId(id => id + 1);
    return id;
  };

  const validateAllPaperlistCheckins = () => {
    setPaperlistCheckins(paperlistCheckins =>
      paperlistCheckins.map(paperlistCheckin => ({
        ...paperlistCheckin,
        validationResult: validatePaperlistCheckin(paperlistCheckin, paperlistCheckins),
      }))
    );
  };

  const appendPaperlistCheckin = () => {
    setPaperlistCheckins(paperlistCheckins => {
      const lastPaperlistCheckin =
        paperlistCheckins && paperlistCheckins[paperlistCheckins.length - 1];
      return [
        ...paperlistCheckins,
        {
          ...initialInput,
          inputMonth: lastPaperlistCheckin ? lastPaperlistCheckin.inputMonth : '',
          inputYear: lastPaperlistCheckin ? lastPaperlistCheckin.inputYear : '',
          id: getNextId(),
        },
      ];
    });
  };

  const clonePaperlistCheckin = idToClone => {
    const newPaperlistCheckin = paperlistCheckins.find(
      paperlistCheckin => paperlistCheckin.id === idToClone
    );
    setPaperlistCheckins(paperlistCheckins => [
      ...paperlistCheckins,
      {
        ...newPaperlistCheckin,
        id: getNextId(),
        inputDay: '',
        validationResult: undefined,
      },
    ]);
  };

  const deletePaperlistCheckin = idToDelete => {
    setPaperlistCheckins(paperlistCheckins =>
      paperlistCheckins.filter(paperlistCheckin => paperlistCheckin.id !== idToDelete)
    );
  };

  const updatePaperlistCheckinField = (idToUpdate, fieldName, newValue) => {
    setPaperlistCheckins(paperlistCheckins =>
      paperlistCheckins.map(paperlistCheckin =>
        idToUpdate === paperlistCheckin.id
          ? { ...paperlistCheckin, [fieldName]: newValue }
          : paperlistCheckin
      )
    );
  };

  const clearAllPaperlistCheckins = () => {
    setPaperlistCheckins([]);
    appendPaperlistCheckin();
    setWorkflowState(WORKFLOW_STATE_INITIAL);
  };

  const isPaperlistCheckinEmpty = paperlistCheckin => {
    const {
      inputDay,
      inputMonth,
      inputYear,
      inputCompanyAlias,
      inputFirstLastName,
      selectedMembership,
      selectedCompanyAlias,
    } = paperlistCheckin;
    return (
      !inputDay &&
      !inputMonth &&
      !inputYear &&
      !inputCompanyAlias &&
      !inputFirstLastName &&
      !selectedCompanyAlias &&
      !selectedMembership
    );
  };

  const removeEmptyPaperlistCheckins = () => {
    setPaperlistCheckins(paperlistCheckins =>
      paperlistCheckins.filter(paperlistCheckin => !isPaperlistCheckinEmpty(paperlistCheckin))
    );
  };

  const isDefaultCheckin = paperlistCheckin => {
    const {
      inputDay,
      inputMonth,
      inputYear,
      inputCompanyAlias,
      inputFirstLastName,
      selectedMembership,
      selectedCompanyAlias,
    } = paperlistCheckin;
    return (
      !inputDay &&
      inputMonth &&
      inputYear &&
      !inputCompanyAlias &&
      !inputFirstLastName &&
      !selectedCompanyAlias &&
      !selectedMembership
    );
  };

  const isDefaultLastCheckin = paperListCheckin => {
    return isDefaultCheckin(paperListCheckin) && paperListCheckin.id === nextId - 1;
  };

  const removeLastAutoPaperlistCheckin = () => {
    setPaperlistCheckins(paperlistCheckins =>
      paperlistCheckins.filter(paperlistCheckin => !isDefaultLastCheckin(paperlistCheckin))
    );
  };

  const openClearCheckinsConfirmationModal = () => {
    setClearCheckinsConfirmationModalOpen(true);
  };

  const closeClearCheckinsConfirmationModal = () => {
    setClearCheckinsConfirmationModalOpen(false);
  };

  const openSaveCheckinsConfirmationModal = () => {
    setSaveCheckinsConfirmationModalOpen(true);
  };

  const closeSaveCheckinsConfirmationModal = () => {
    setSaveCheckinsConfirmationModalOpen(false);
  };

  const updateCounts = () => {
    setCountSuccessfulUploads(0);
    setCountErrorUploads(0);
  };

  const saveCheckins = () => {
    const postCheckins = async () => {
      for (const paperlistCheckin of paperlistCheckins) {
        if (hasError(paperlistCheckin)) {
          continue;
        }
        const {
          inputDay: day,
          inputMonth: month,
          inputYear: year,
          selectedMembership: {
            membershipWrapper: { user, membership },
          },
        } = paperlistCheckin;

        try {
          const postBody = {
            userId: user.id,
            membershipUUID: membership.uuid,
            egymGymId: gym.gymId,
            checkinTimestamp: dateToISODateTimeMiddleOfDayCET(toDate(day, month, year)),
            checkinSource: 'QT_OPS_ADMIN_PAPER_LIST',
            createdBy: loggedInUser.id,
          };

          await httpPost(`/v1/checkin`, postBody);
          updatePaperlistCheckinField(paperlistCheckin.id, 'uploadResult', {
            status: UPLOAD_STATUS_SUCCESS,
            message: 'Check-in saved successfully',
          });
          setCountSuccessfulUploads(c => c + 1);
        } catch (error) {
          updatePaperlistCheckinField(paperlistCheckin.id, 'uploadResult', {
            status: UPLOAD_STATUS_ERROR,
            message: error && error.message,
          });
          setCountErrorUploads(c => c + 1);
        }
      }
    };
    executeWithSpinner(postCheckins());
    setWorkflowState(WORKFLOW_STATE_AFTER_SAVE);
    closeSaveCheckinsConfirmationModal();
  };

  const getTotalUploads = () => paperlistCheckins.filter(checkin => !hasError(checkin)).length;

  usePrompt({
    when: workflowState !== WORKFLOW_STATE_AFTER_SAVE && paperlistCheckins.length > 1,
    message: 'You have unsaved changes. Do you really want to exit the page?',
  });

  return (
    <>
      <div className="row">
        <h5 className="col-9">Enter manual check-ins for this gym here:</h5>
        {workflowState === WORKFLOW_STATE_INITIAL && (
          <div className="col-3 mb-2">
            <button
              className="btn btn-primary float-right"
              onClick={openClearCheckinsConfirmationModal}
              disabled={!paperlistCheckins || paperlistCheckins.length === 0}
            >
              Clear this list
            </button>
          </div>
        )}
      </div>
      <table className="table table-bordered table-sm">
        <thead className="thead-light">
          <tr>
            <th>Nr</th>
            <th>Company (signup page alias)</th>
            <th>First &amp; Last Name</th>
            <th colSpan={3}>Check-in date</th>
            <th>Copy</th>
            <th>Delete</th>
            <th>Errors &amp; warnings</th>
            <th>Upload Result</th>
          </tr>
        </thead>
        <tbody>
          {paperlistCheckins.map((paperlistCheckin, index) => (
            <PaperlistCheckinRow
              paperlistCheckin={paperlistCheckin}
              paperlistCheckins={paperlistCheckins}
              index={index}
              key={paperlistCheckin.id}
              workflowState={workflowState}
              updatePaperlistCheckinField={updatePaperlistCheckinField}
              appendPaperlistCheckin={appendPaperlistCheckin}
              clonePaperlistCheckin={clonePaperlistCheckin}
              deletePaperlistCheckin={deletePaperlistCheckin}
              validateAllPaperlistCheckins={validateAllPaperlistCheckins}
            />
          ))}
          {workflowState !== WORKFLOW_STATE_AFTER_SAVE && (
            <tr className="border">
              <td
                colSpan={10}
                className="align-middle text-center py-2"
                onClick={() => {
                  validateAllPaperlistCheckins();
                  appendPaperlistCheckin();
                }}
              >
                <PlusIcon />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {workflowState === WORKFLOW_STATE_INITIAL && (
        <div>
          <button
            className="btn btn-secondary"
            onClick={() => {
              removeEmptyPaperlistCheckins();
              removeLastAutoPaperlistCheckin();
              validateAllPaperlistCheckins();
              updateCounts();
              openSaveCheckinsConfirmationModal();
            }}
            disabled={!paperlistCheckins || paperlistCheckins.length === 0}
          >
            Submit manual check-ins
          </button>
        </div>
      )}
      {(workflowState === WORKFLOW_STATE_SAVING || workflowState === WORKFLOW_STATE_AFTER_SAVE) && (
        <PaperlistCheckinsUploadProgress
          countSuccessfulUploads={countSuccessfulUploads}
          countErrorUploads={countErrorUploads}
          getTotalUploads={getTotalUploads}
        />
      )}
      {workflowState === WORKFLOW_STATE_AFTER_SAVE && (
        <div>
          <button className="btn btn-primary mb-5" onClick={() => clearAllPaperlistCheckins()}>
            Add more check-ins for this gym
          </button>
        </div>
      )}
      {isClearCheckinsConfirmationModalOpen && (
        <ClearCheckinsConfirmationModal
          onConfirm={() => {
            clearAllPaperlistCheckins();
            closeClearCheckinsConfirmationModal();
          }}
          onCancel={closeClearCheckinsConfirmationModal}
        />
      )}
      {isSaveCheckinsConfirmationModalOpen && (
        <SaveCheckinsConfirmationModal
          gym={gym}
          countCheckinsWithErrors={paperlistCheckins.filter(hasError).length}
          countCheckinsWithoutErrors={
            paperlistCheckins.length - paperlistCheckins.filter(hasError).length
          }
          onConfirm={saveCheckins}
          onCancel={closeSaveCheckinsConfirmationModal}
        />
      )}
    </>
  );
};

export default PaperlistCheckinPage;
