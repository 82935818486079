// @flow
import * as React from 'react';
import { httpGet } from 'app/service/http';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { formatISOZonedDateTimeToUTCDateTime } from 'app/utils/format/dateTimeFormatter';
import { useState } from 'react';
import JSONPretty from 'react-json-pretty';
import { Link, useParams } from 'react-router-dom';

const ListChangelogPage = () => {
  const { userId } = useParams();
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const [changelogs, setChangelogs] = useState(null);
  const [errorResult, setErrorResult] = useState(null);

  const reloadLogs = () => {
    loadLogs(true);
  };

  const loadOlderLogs = () => {
    loadLogs(false);
  };

  const loadLogs = reload => {
    let url = `/v1/changelog?refObjectId=${userId}&refObjectType=User`;
    if (reload !== undefined && !reload && changelogs !== null && changelogs.length > 0) {
      const timestamp = changelogs[changelogs.length - 1].timestamp;
      url = `${url}&timestamp=${timestamp}`;
    }
    executeWithSpinner(
      httpGet(url)
        .then(newlogs => {
          if (changelogs === null || changelogs.length === 0) {
            setChangelogs(newlogs);
          } else {
            const logs = reload ? [...newlogs] : [...changelogs, ...newlogs];
            setChangelogs(logs);
          }
        })
        .catch(error => setErrorResult(error && error.message))
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(reloadLogs, []);

  return (
    <>
      <div className="my-md-1">
        <button className="btn btn-secondary" type="button" onClick={reloadLogs}>
          Reload newer logs
        </button>
      </div>
      {changelogs && (
        <div className="my-md-1">
          <table className="table">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Actor ID</th>
                <th>Object Type</th>
                <th>Object ID</th>
                <th>Audit Type</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {changelogs.map(changelog => (
                <tr key={changelog.timestamp}>
                  <td>{formatISOZonedDateTimeToUTCDateTime(changelog.timestamp)}</td>
                  <td>
                    <Link to={`/users/user/${changelog.actorUserId}/details`}>
                      {changelog.actorUserId}
                    </Link>
                  </td>
                  <td>{changelog.referencedObjectType}</td>
                  <td>
                    {changelog.referencedObjectType === 'User' ? (
                      <Link to={`/users/user/${changelog.referencedObjectId}/details`}>
                        {changelog.referencedObjectId}
                      </Link>
                    ) : (
                      changelog.referencedObjectId
                    )}
                  </td>
                  <td>{changelog.auditType}</td>
                  <td>
                    <JSONPretty data={JSON.parse(changelog.message)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="my-md-1">
        <button className="btn btn-secondary" type="button" onClick={loadOlderLogs}>
          Load older logs
        </button>
      </div>
      <div className="my-md-1">
        {errorResult && <div className="alert alert-primary">{errorResult}</div>}
      </div>
    </>
  );
};

export default ListChangelogPage;
