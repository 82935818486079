// @flow
import React, { useState, useCallback } from 'react';
import { httpGet } from 'app/service/http';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { extractRequestParameter } from 'app/service/http/requestUtil';
import { formatISODateToDate } from 'app/utils/format/dateTimeFormatter';
import { exportSearchUsersToCsv } from 'app/utils/csv/searchUsersCsvExporter';
import { isValidUuid } from 'app/utils/number/numberUtils';
import moment from 'moment';
import {
  COMPANY_ADMIN,
  COMPANY_EDITOR,
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  QUALITRAIN_OPS_VIEWER,
} from 'app/ui/common/authentication/roles';

const SearchUserPage = () => {
  const [sfAccountCanonicalId, setSfAccountCanonicalId] = useState('');
  const [userId, setUserId] = useState('');
  const [membershipId, setMembershipId] = useState('');
  const [email, setEmail] = useState('');
  const [birthday, setBirthday] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [result, setResult] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [errorResult, setErrorResult] = useState(null);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const location = useLocation();
  const navigate = useNavigate();

  const limit = 100;
  const birthdayErrorMsg = 'Birthday can only be used in conjunction with other search criteria';

  const buildQueryParamString = useCallback(
    ({
      userId,
      membershipId,
      email,
      name,
      role,
      sfAccountCanonicalId,
      birthday,
      nextPageToken,
      limit,
    }) => {
      const queryParams = [];

      if (userId) {
        queryParams.push(`userId=${encodeURIComponent(userId)}`);
      }

      if (membershipId) {
        queryParams.push(`membershipId=${encodeURIComponent(membershipId)}`);
      }

      if (email) {
        queryParams.push(`email=${encodeURIComponent(email)}`);
      }

      if (birthday) {
        queryParams.push(`birthday=${encodeURIComponent(birthday)}`);
      }

      if (name) {
        queryParams.push(`name=${encodeURIComponent(name)}`);
      }

      if (role) {
        queryParams.push(`role=${encodeURIComponent(role)}`);
      }

      if (sfAccountCanonicalId) {
        queryParams.push(`sfAccountCanonicalId=${encodeURIComponent(sfAccountCanonicalId)}`);
      }

      if (nextPageToken) {
        queryParams.push(`pageToken=${nextPageToken}`);
      }

      if (limit) {
        queryParams.push(`limit=${limit}`);
      }

      return queryParams.join('&');
    },
    []
  );

  const searchUsers = useCallback(
    (userId, membershipId, email, birthday, name, role, sfAccountCanonicalId, nextPageToken) => {
      return executeWithSpinner(
        httpGet(
          `/v1/user?${buildQueryParamString({
            userId,
            membershipId,
            email,
            name,
            role,
            sfAccountCanonicalId,
            nextPageToken,
            limit,
          })}`
        )
          .then(usersPage => {
            let users = usersPage.items || [];
            if (!userId && !membershipId && birthday) {
              users = users.filter(u => formatISODateToDate(u.dateOfBirth) === birthday);
            }

            setResult(result => [...result, ...users]);
            setNextPageToken(usersPage.nextPageToken);
          })
          .catch(error => {
            setErrorResult(error && error.message);
          })
      );
    },
    [executeWithSpinner, buildQueryParamString]
  );

  React.useEffect(() => {
    const userId = extractRequestParameter('userId');
    setUserId(userId ? userId : ''); //empty string needed otherwise field is not cleared when navigating back

    const membershipId = extractRequestParameter('membershipId');
    setMembershipId(membershipId ? membershipId : ''); //empty string needed otherwise field is not cleared when navigating back

    const email = extractRequestParameter('email');
    setEmail(email ? email : ''); //empty string needed otherwise field is not cleared when navigating back

    const birthday = extractRequestParameter('birthday');
    setBirthday(birthday ? birthday : ''); //empty string needed otherwise field is not cleared when navigating back

    const name = extractRequestParameter('name');
    setName(name ? name : ''); //empty string needed otherwise field is not cleared when navigating back

    const role = extractRequestParameter('role');
    setRole(role && role !== 'ALL' ? role : ''); //empty string needed otherwise field is not cleared when navigating back

    const sfAccountCanonicalId = extractRequestParameter('sfAccountCanonicalId');
    setSfAccountCanonicalId(sfAccountCanonicalId ? sfAccountCanonicalId : ''); //empty string needed otherwise field is not cleared when navigating back

    if (userId || membershipId || email || name || role || sfAccountCanonicalId) {
      setResult([]);
      setErrorResult(null);
      searchUsers(userId, membershipId, email, birthday, name, role, sfAccountCanonicalId);
    } else if (birthday) {
      setErrorResult(birthdayErrorMsg);
    }
  }, [searchUsers, location.search]);

  const submitSearchForm = event => {
    event.preventDefault();
    setErrorResult();

    if (
      !userId.trim() &&
      !membershipId.trim() &&
      !email &&
      !name &&
      !role &&
      !sfAccountCanonicalId
    ) {
      setErrorResult(birthday ? birthdayErrorMsg : 'Please fill any of the search fields');
      return;
    }

    if (birthday && !moment(birthday, 'DD-MM-YYYY', true).isValid()) {
      setErrorResult('Incorrect date format for Birthday. Expected format is DD-MM-YYYY.');
      return;
    }

    if (userId && !isValidUuid(userId)) {
      setErrorResult(`Invalid userId ${userId}, should be uuidv4`);
      return;
    }

    if (membershipId && !isValidUuid(membershipId)) {
      setErrorResult(`Invalid membershipId ${membershipId}, should be uuidv4`);
      return;
    }

    if (name.trim() && name.trim().length < 3) {
      setErrorResult('Name term must contain at least 3 characters');
      return;
    }

    if (email.trim() && email.trim().length < 3) {
      setErrorResult('Email term must contain at least 3 characters');
      return;
    }

    navigate(
      `/users?${buildQueryParamString({
        userId,
        membershipId,
        email,
        name,
        role,
        sfAccountCanonicalId,
        birthday,
      })}`
    );
  };

  const exportToCsv = () => {
    const exportInfo = [];

    result.forEach(user => {
      exportInfo.push({
        userId: user.accountId,
        email: user.email,
        gender: user.gender,
        firstName: user.firstName,
        lastName: user.lastName,
      });
    });

    exportSearchUsersToCsv(exportInfo);
  };

  const loadMore = () => {
    searchUsers(
      userId,
      membershipId,
      email,
      birthday,
      name,
      role,
      sfAccountCanonicalId,
      nextPageToken
    );
  };

  return (
    <>
      <form onSubmit={submitSearchForm}>
        <div className="form-group form-row">
          <div className="col-12">
            <div className="form-group">
              <div className="input-group">
                <input
                  id="sfAccountCanonicalId"
                  type="text"
                  className="form-control"
                  value={sfAccountCanonicalId}
                  placeholder="Salesforce Account Canonical Id"
                  onChange={event => setSfAccountCanonicalId(event.target.value)}
                />
                <input
                  id="userId"
                  type="text"
                  className="form-control"
                  value={userId}
                  placeholder="User Id"
                  onChange={event => setUserId(event.target.value)}
                />
                <input
                  id="membershipId"
                  type="text"
                  className="form-control"
                  value={membershipId}
                  placeholder="Membership Id"
                  onChange={event => setMembershipId(event.target.value)}
                />
                <input
                  id="email"
                  type="text"
                  className="form-control"
                  value={email}
                  placeholder="E-mail"
                  onChange={event => setEmail(event.target.value)}
                />
                <input
                  id="firstName"
                  type="text"
                  className="form-control"
                  value={name}
                  placeholder="Name"
                  onChange={event => setName(event.target.value)}
                />
                <input
                  id="birthday"
                  type="text"
                  className="form-control"
                  value={birthday}
                  placeholder="Birthday, DD-MM-YYYY"
                  onChange={event => setBirthday(event.target.value)}
                />
                <select
                  id="role"
                  className="form-control"
                  placeholder="Role"
                  onChange={event => setRole(event.target.value)}
                >
                  <option value="">ALL</option>
                  <option value={COMPANY_ADMIN}>COMPANY_ADMIN</option>
                  <option value={COMPANY_EDITOR}>COMPANY_EDITOR</option>
                  <option value={QUALITRAIN_OPS_ADMIN}>QUALITRAIN_OPS_ADMIN</option>
                  <option value={QUALITRAIN_OPS_MASTER}>QUALITRAIN_OPS_MASTER</option>
                  <option value={QUALITRAIN_OPS_VIEWER}>QUALITRAIN_OPS_VIEWER</option>
                </select>
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div>
        {errorResult && <div className="alert alert-primary">{errorResult}</div>}
        <div className="d-flex align-items-center justify-content-between mb-1">
          <span>
            Search Results:&nbsp;
            <i>
              {result && result.length
                ? `${result.length} users${nextPageToken ? ' shown' : ''}`
                : ''}
            </i>
          </span>

          {result && result.length ? (
            <button className="btn btn-secondary btn-sm" type="button" onClick={exportToCsv}>
              Export as CSV
            </button>
          ) : (
            ''
          )}
        </div>
        {result && result.length ? (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>First name</th>
                  <th>Last name</th>
                  <th>E-mail</th>
                  <th>Birthday</th>
                  <th>UUID</th>
                </tr>
              </thead>
              <tbody>
                {result.map(r => (
                  <tr key={r.accountId}>
                    <td>{r.firstName}</td>
                    <td>{r.lastName}</td>
                    <td>{r.email}</td>
                    <td>{formatISODateToDate(r.dateOfBirth)}</td>
                    <td>
                      <Link to={`user/${r.accountId}/details`}>{r.accountId}</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="text-right mb-3">
              {nextPageToken && (
                <button className="btn btn-secondary" type="button" onClick={loadMore}>
                  Load more
                </button>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default withPageTemplate(SearchUserPage, { headerTitle: 'User Manager' });
