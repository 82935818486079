// @flow
import * as React from 'react';

// The value of these constants comes from the Bootstrap classes that are used to display success or error message.
export const SUCCESS = 'success';
export const ERROR = 'danger';

type Props = {
  children: any,
};
type State = {
  isOpen: boolean,
  message: any,
};

const OverlayContext = React.createContext<State>({ isOpen: false });

const OverlayProvider = ({ children }: Props) => {
  const [isOpen, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(undefined);
  const [messageType, setMessageType] = React.useState(undefined);

  const showOverlay = (message, messageType) => {
    setMessage(message);
    setMessageType(messageType);
    setOpen(true);
  };

  const showSuccessOverlay = message => {
    showOverlay(message, SUCCESS);
  };

  const showErrorOverlay = message => {
    showOverlay(message, ERROR);
  };

  const hideOverlay = () => {
    setMessage(undefined);
    setMessageType(undefined);
    setOpen(false);
  };

  return (
    <OverlayContext.Provider
      value={{
        isOpen: isOpen,
        message: message,
        messageType: messageType,
        showSuccessOverlay: showSuccessOverlay,
        showErrorOverlay: showErrorOverlay,
        hideOverlay: hideOverlay,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
};

export { OverlayProvider };
export default OverlayContext;
