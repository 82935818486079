// @flow
import React from 'react';
import HeaderNavigation from 'app/ui/layout/HeaderNavigation';

type Props = {
  headerTitle: string,
};

const Header = ({ headerTitle }: Props) => (
  <header>
    <HeaderNavigation />
    {headerTitle && (
      <h1 className="text-uppercase text-center text-primary pt-4 pb-5">{headerTitle}</h1>
    )}
  </header>
);

export default Header;
