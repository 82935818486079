// @flow
import validator from 'validator';
import {
  validateLength,
  validateIsBlank,
  combineValidators,
  validateDate,
  validateDateNotInPast,
} from 'app/validation/common/commonValidators';

import {
  VALIDATION_INVALID_CURRENCY,
  VALIDATION_INVALID_MONETARY_AMOUNT,
  VALIDATION_INVALID_LOGO_SIZE,
} from 'app/validation/common/errorCodes';
import type { SignupPageFrontendModel } from 'app/ui/types';

export const validate = (values: SignupPageFrontendModel) => {
  const errors = {};
  errors.name = combineValidators(values.name, true)(validateLength(1, 100));
  errors.amount = validatePriceAmount(values.amount, values.b2cPayment);
  errors.currency = validatePriceCurrency(values.currency);
  errors.employeeInternalIdentifierLabel = combineValidators(
    values.employeeInternalIdentifierLabel,
    false
  )(validateLength(0, 100));
  errors.sfAccountCanonicalId = combineValidators(values.sfAccountCanonicalId, true)(
    validateLength(1, 64),
    validateIsBlank
  );
  errors.customTermsDescription = combineValidators(
    values.customTermsDescription,
    false
  )(validateLength(0, 5000));
  errors.signUpPageExpirationDate = combineValidators(values.signUpPageExpirationDate, false)(
    validateDate('DD-MM-YYYY HH:mm'),
    validateDateNotInPast('DD-MM-YYYY HH:mm')
  );
  errors.customDescription = combineValidators(
    values.customDescription,
    false
  )(validateLength(0, 5000));

  return errors;
};

export const validateNew = (values: SignupPageFrontendModel, coord: any) => {
  const errors = {};
  errors.name = combineValidators(values.name, true)(validateLength(1, 100));
  errors.signUpPageExpirationDate = combineValidators(values.signUpPageExpirationDate, false)(
    validateDate('DD-MM-YYYY HH:mm'),
    validateDateNotInPast('DD-MM-YYYY HH:mm')
  );
  errors.customDescription = combineValidators(
    values.customDescription,
    false
  )(validateLength(0, 5000));

  errors.logo = validateLogoSize(values.logo, 1048576);

  if (values.searchTerm && !coord) {
    errors.searchTerm = 'No lat/long';
  }

  return errors;
};

const validatePriceAmount = (amount, isMandatory) => {
  // when received from the server the amount is a decimal value, so we have to convert it to string in order for the validation to work
  return combineValidators(amount, isMandatory)(validateLength(0, 21), validateMonetaryAmount());
};

export const validateMonetaryAmount = () => {
  return (value: string) => {
    if (value && !validator.isDecimal(value, { decimal_digits: '1,2' })) {
      return { code: VALIDATION_INVALID_MONETARY_AMOUNT, args: {} };
    }
    return undefined;
  };
};

const validatePriceCurrency = currency => {
  return combineValidators(currency, true)(validateLength(3, 3), validateCurrency());
};

/** Each currency should have exactly 3 letters. */
const CURRENCY_PATTERN = /^[a-zA-Z][a-zA-Z][a-zA-Z]$/;

const validateCurrency = () => {
  return value => {
    if (!value.match(CURRENCY_PATTERN)) {
      return {
        code: VALIDATION_INVALID_CURRENCY,
        args: {},
      };
    }
    return undefined;
  };
};

const validateLogoSize = (logo, size) => {
  if (logo && logo.file && logo.file.size > size) {
    return { code: VALIDATION_INVALID_LOGO_SIZE, args: [size] };
  }
  return undefined;
};
