// @flow
import type { BaseAction, SignupPage } from 'app/ui/types';
import {
  USER_LOADING,
  USER_LOAD_SUCCESS,
  USER_LOAD_FAILURE,
  OPEN_ROLES_MODAL,
  CLOSE_ROLES_MODAL,
  ADD_ROLE_TO_USER_SUCCESS,
  REMOVE_ROLE_FROM_USER_SUCCESS,
  REMOVE_ROLE_FROM_USER_FAILURE,
  OPEN_NEW_EMAIL_MODAL,
  CLOSE_NEW_EMAIL_MODAL,
  SET_NEW_EMAIL_TO_USER_SUCCESS,
  USER_SAVE_SUCCESS,
  OPEN_PASSWORD_RESET_MODAL,
  CLOSE_PASSWORD_RESET_MODAL,
} from 'app/ui/user-manager/user/details/actions';
import type { UserRole, User } from 'app/ui/user-manager/types';

type State = {
  user: User,
  signupPage: SignupPage,
  errorMessage: any,
  isRolesModalOpen: boolean,
  isNewEmailModalOpen: boolean,
  isPasswordResetModalOpen: boolean,
};

type Action = {
  user: User,
  signupPage: SignupPage,
  errorMessage: any,
  newEmail: string,
  role: UserRole,
  roles: Array<UserRole>,
};

export const initialValues = {
  isRolesModalOpen: false,
  isNewEmailModalOpen: false,
  isPasswordResetModalOpen: false,
};

export const reducer = (state: State, action: BaseAction | Action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
      };
    case USER_LOAD_SUCCESS:
      return {
        user: action.user,
        isRolesModalOpen: false,
      };
    case USER_LOAD_FAILURE:
      return {
        errorMessage: action.errorMessage,
      };
    case USER_SAVE_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case OPEN_ROLES_MODAL:
      return {
        ...state,
        isRolesModalOpen: true,
      };
    case CLOSE_ROLES_MODAL:
      return {
        ...state,
        isRolesModalOpen: false,
        roleErrorMessage: undefined,
      };
    case OPEN_NEW_EMAIL_MODAL:
      return {
        ...state,
        isNewEmailModalOpen: true,
      };
    case CLOSE_NEW_EMAIL_MODAL:
      return {
        ...state,
        isNewEmailModalOpen: false,
      };
    case OPEN_PASSWORD_RESET_MODAL:
      return {
        ...state,
        isPasswordResetModalOpen: true,
      };
    case CLOSE_PASSWORD_RESET_MODAL:
      return {
        ...state,
        isPasswordResetModalOpen: false,
      };
    case SET_NEW_EMAIL_TO_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          newEmail: action.newEmail,
        },
      };
    case ADD_ROLE_TO_USER_SUCCESS:
      const { roles } = state.user;
      const isNewRole = roles.filter(r => r.authority === action.role.authority).length === 0;
      return {
        ...state,
        user: {
          ...state.user,
          roles: isNewRole
            ? [...state.user.roles, action.role]
            : roles.map(r => (r.authority === action.role.authority ? action.role : r)),
        },
      };
    case REMOVE_ROLE_FROM_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          roles: action.roles,
        },
      };
    case REMOVE_ROLE_FROM_USER_FAILURE:
      return {
        errorMessage: action.errorMessage,
      };
    default:
      return initialValues;
  }
};
