import React from 'react';
import ConfirmationModal from '../../common/modal/ConfirmationModal';

type Props = {
  onCancel: Function,
  loadNextPage: Function,
  newOffer: any,
  oldMismatchedOffers: Array,
};

const OfferTypeDifferenceModal = ({
  onCancel,
  loadNextPage,
  newOffer,
  oldMismatchedOffers,
}: Props) => {
  return (
    <>
      <ConfirmationModal
        isOpen={true}
        modalTitle="WARNING: Different offer type"
        yesButtonText="This is correct"
        noButtonText="Back"
        onConfirm={loadNextPage}
        onCancel={onCancel}
      >
        <div className="form-group">
          <p className="text-black-50">
            One or multiple old offer IDs have a different offerType than the new one!
          </p>
          <div className="overflow-auto" style={{ maxHeight: '150px' }}>
            <div className="text-black-50">
              <strong>new offer: </strong> {newOffer.id} | {newOffer.type}
            </div>
            {oldMismatchedOffers.map(offer => {
              return (
                <div className="text-black-50" key={offer.id}>
                  <strong>old offer: </strong> {offer.id} | {offer.type} <br />
                </div>
              );
            })}
            <br />
          </div>
          <p className="text-black-50">
            Please verify that this is correct. Your action can't be undone.
          </p>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default OfferTypeDifferenceModal;
