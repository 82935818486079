import React, { useCallback, useRef } from 'react';
import HorizontalFormTextField from '../../../components/horizontal-form/HorizontalFormTextField';
import HorizontalFormRow from '../../../components/horizontal-form/HorizontalFormRow';
import HorizontalFormLabel from '../../../components/horizontal-form/HorizontalFormLabel';
import { Form } from 'react-final-form';
import OfferContext from '../context/OfferContext';
import { httpPost } from '../../../../service/http';
import EligibilityRulesForm from './EligibilityRulesForm';
import VerificationOptionsForm from '../additional-verifications/VerificationOptionsForm';

const EligibilityRules = () => {
  const { offer } = React.useContext(OfferContext);
  const formRef = useRef();

  const linkHrisIntegration = useCallback(
    submitBody => {
      if (submitBody && offer.id) {
        const requestBody = {
          integrationId: submitBody.remoteIntegrationId,
        };
        httpPost(`/v1/membership-offer/${offer.id}/link-hris-integration`, requestBody)
          .then(() => {
            window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    [offer]
  );

  const onSubmit = linkHrisIntegration;

  return (
    <div className="mt-4">
      <h5>HRIS connection</h5>
      {offer?.integrationScopeId === null && (
        <Form
          onSubmit={onSubmit}
          initialValuesEqual={() => true}
          initialValues={{ ...offer }}
          render={({ submitError, handleSubmit, submitting, pristine, values, form }) => {
            formRef.current = form;
            return (
              <form onSubmit={handleSubmit}>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Remote Integration ID" />
                  <HorizontalFormTextField
                    controlId="remoteIntegrationId"
                    controlLabel="Remote Integration ID"
                    placeholder="Remote Integration ID"
                    columnWidth={6}
                    helpTextAfter={<div> example: personio:CBNMt7dSNCzBdnRTx87dev4E</div>}
                  />
                </HorizontalFormRow>
                <button className="btn btn-block btn-secondary col-sm-3" type="submit">
                  Save
                </button>
              </form>
            );
          }}
        />
      )}
      {offer?.integrationScopeId !== null && (
        <div>
          <EligibilityRulesForm offer={offer}></EligibilityRulesForm>
          <VerificationOptionsForm
            integrationScopeId={offer?.integrationScopeId}
          ></VerificationOptionsForm>
        </div>
      )}
    </div>
  );
};

export default EligibilityRules;
