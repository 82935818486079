// @flow
import * as React from 'react';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PayoutStepper from 'app/ui/gyms/gym/payout/PayoutStepper';
import PayoutSubRouter from 'app/ui/gyms/gym/payout/PayoutSubRouter';

const PayoutManager = () => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  return (
    currentLocation === location.pathname && (
      <>
        <PayoutStepper currentPath={currentLocation} />
        <PayoutSubRouter />
      </>
    )
  );
};

export default withPageTemplate(PayoutManager, { headerTitle: 'Gym Payout Calculation' });
