import React from 'react';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';
import FuzzyMatch from './FuzzyMatch';
import LinkMembership from './LinkMembership';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

type SubRouteLinkProps = {
  path: string,
  text: string,
};

const SubRouteLink = ({ path, text }: SubRouteLinkProps) => {
  return (
    <NavLink
      className={({ isActive }) => `nav-item nav-link ${isActive ? ' active' : ''}`}
      to={path}
    >
      {text}
    </NavLink>
  );
};

const HrisMembershipMappingManager = () => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  return (
    <>
      <nav className="nav nav-tabs flex-column flex-sm-row justify-content-center">
        <SubRouteLink path={'/companies/hris-membership-mapping/fuzzy'} text="Fuzzy Match" />
        {isEditable && (
          <SubRouteLink path={`/companies/hris-membership-mapping/link`} text="Link Membership" />
        )}
      </nav>

      <div className="mt-4">
        <Routes>
          <Route path="fuzzy" element={<FuzzyMatch />} />
          <Route path="link" element={<LinkMembership />} />
          <Route index element={<Navigate to="fuzzy" />} />
        </Routes>
      </div>
    </>
  );
};

export default withPageTemplate(HrisMembershipMappingManager, {
  headerTitle: 'HRIS Membership mapping tool',
});
