// @flow
import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import OfferContext, { OfferContextProvider } from '../context/OfferContext';
import OfferManagerSubRoutes from './OfferManagerSubRoutes';
import { httpGet } from '../../../../service/http';
import { covertMembershipOfferToMembershipOfferFrontendModel } from '../offer-details/converter';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';

type SubRouteLinkProps = {
  path: string,
  text: string,
};

const SubRouteLink = ({ path, text }: SubRouteLinkProps) => (
  <NavLink className={({ isActive }) => `nav-item nav-link ${isActive ? ' active' : ''}`} to={path}>
    {text}
  </NavLink>
);

const OfferManagerTemplateWithSubRoutes = () => {
  const { setOffer } = React.useContext(OfferContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const offerId = useParams().offerId;
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  const [displayAllowlist, setDisplayAllowList] = useState(false);

  React.useEffect(() => {
    const getOfferAndFeatures = async () => {
      if (offerId !== 'new') {
        try {
          const offerResponse = await httpGet(`/v1/membership-offer/${offerId}`);
          setOffer(covertMembershipOfferToMembershipOfferFrontendModel(offerResponse));
          const sfAccountCanonicalId = offerResponse.sfAccountCanonicalId;
          if (sfAccountCanonicalId) {
            const features = await httpGet(
              `/v1/company/${sfAccountCanonicalId}/company-portal-feature`
            );
            setDisplayAllowList(
              features
                .filter(featureDetail => featureDetail.enabled)
                .map(featureDetail => featureDetail.feature)
                .includes('ALLOWLIST')
            );
          }
        } catch (error) {
          setOffer(undefined);
        }
      } else {
        const offerModel = covertMembershipOfferToMembershipOfferFrontendModel(undefined);
        setOffer(offerModel);
      }
    };
    executeWithSpinner(getOfferAndFeatures());
  }, [executeWithSpinner, offerId, setOffer]);

  return (
    <>
      {offerId !== 'new' && (
        <nav className="nav nav-tabs flex-column flex-sm-row justify-content-center">
          <SubRouteLink path={`/companies/offer-manager/${offerId}/details`} text="Offer Details" />
          {isEditable && (
            <SubRouteLink
              path={`/companies/offer-manager/${offerId}/eligibility-rules`}
              text="Eligibility Rules"
            />
          )}
          {displayAllowlist && (
            <>
              <SubRouteLink
                path={`/companies/offer-manager/${offerId}/edit-allowlist`}
                text="Edit Allowlist"
              />
              <SubRouteLink path={`/companies/offer-manager/${offerId}/logs`} text="Logs" />
            </>
          )}
        </nav>
      )}
      <div className="mt-4">
        <OfferManagerSubRoutes />
      </div>
    </>
  );
};

const OfferManagerTemplateWithContext = props => (
  <OfferContextProvider>
    <OfferManagerTemplateWithSubRoutes {...props} />
  </OfferContextProvider>
);

export default withPageTemplate(OfferManagerTemplateWithContext, { headerTitle: 'Offer Manager' });
