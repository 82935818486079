// @flow
import {
  GET_CHARGE_STATS_SUCCESS,
  GET_CHARGE_STATS_FAILURE,
  GET_FAILED_CHARGES_SUCCESS,
  GET_FAILED_CHARGES_FAILURE,
} from 'app/ui/payment/list-payments/actions';
import type { BaseAction } from 'app/ui/types';

type State = {
  chargeStats: any,
  charges: any,
  errorMessage: string,
  emptyMembershipsMessage: string,
};

type Action = {
  chargeStats: any,
  charges: any,
  errorMessage: string,
  emptyMembershipsMessage: string,
};

export const initialState = {
  chargeStats: undefined,
  charges: undefined,
  errorMessage: undefined,
  emptyMembershipsMessage: undefined,
};

export const reducer = (state: State, action: BaseAction | Action) => {
  switch (action.type) {
    case GET_CHARGE_STATS_SUCCESS:
      return {
        ...state,
        chargeStats: action.chargeStats,
        errorMessage: undefined,
        emptyMembershipsMessage: undefined,
      };
    case GET_CHARGE_STATS_FAILURE:
      return {
        ...state,
        chargeStats: undefined,
        errorMessage: action.errorMessage,
        emptyMembershipsMessage: undefined,
      };
    case GET_FAILED_CHARGES_SUCCESS:
      return {
        ...state,
        charges: action.charges,
        errorMessage: undefined,
      };
    case GET_FAILED_CHARGES_FAILURE:
      return {
        ...state,
        charges: undefined,
        errorMessage: action.errorMessage,
      };
    default:
      return initialState;
  }
};
