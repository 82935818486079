// @flow
import type {
  BaseAction,
  MembershipOfferSignupPagesWrapperDto,
  MembershipWrapperTypeWithUIFlags,
} from 'app/ui/types';

import {
  MEMBERSHIPS_LOAD_FAILURE,
  MEMBERSHIPS_LOAD_SUCCESS,
  MEMBERSHIPS_LOADING,
  START_EDITING_MEMBERSHIP,
  CANCEL_EDITING_MEMBERSHIP,
  SAVING_MEMBERSHIP,
  MEMBERSHIP_SAVE_SUCCESS,
  MEMBERSHIP_OFFER_SAVE_SUCCESS,
  START_CHANGING_MEMBERSHIP_OFFER,
  FINISH_CHANGING_MEMBERSHIP_OFFER,
  START_SELECTING_OFFER_FOR_NEW_MEMBERSHIP,
  FINISH_SELECTING_OFFER_FOR_NEW_MEMBERSHIP,
  CANCEL_SELECTING_OFFER_FOR_NEW_MEMBERSHIP,
  CANCEL_NEW_MEMBERSHIP,
  NEW_MEMBERSHIP_SAVE_SUCCESS,
  MEMBERSHIP_REVOKE_FAILURE,
  OPEN_EDIT_CHILD_MEMBERSHIP_MODAL,
  CLOSE_EDIT_CHILD_MEMBERSHIP_MODAL,
  EMPLOYEE_UNLINKING_FAILURE,
} from 'app/ui/user-manager/user/memberships/actions';
import type { MembershipWrapperType } from 'app/ui/types';

export type UserMembershipsState = {
  userId: string,
  memberships: Array<MembershipWrapperTypeWithUIFlags>,
  membershipUUIDInChangeOfferMode: string,
  newMembership: MembershipWrapperTypeWithUIFlags,
  selectOfferForNewMembership: boolean,
  membershipUUIDForDelete: string,
  isMembershipDeleteModalOpen: boolean,
  editChildMembershipModalOpen: boolean,
  error: any,
  sfAccountCountryCode: string,
};

type Action = {
  userId: string,
  memberships: Array<MembershipWrapperType>,
  membership: MembershipWrapperType,
  membershipUUID: string,
  b2cPayment: boolean,
  error: any,
  membershipOfferSignupPagesWrapperDto: MembershipOfferSignupPagesWrapperDto,
};

export const initialValue = {
  userId: undefined,
  memberships: [],
  membershipUUIDInChangeOfferMode: undefined, // the uuid of the membership for which the offer is being changed
  newMembership: undefined,
  selectOfferForNewMembership: false, // whether to show a modal for selecting offer for a new membership
  editChildMembershipModalOpen: false,
};

export const reducer = (state: UserMembershipsState, action: BaseAction | Action) => {
  switch (action.type) {
    case MEMBERSHIPS_LOADING:
      return {
        ...state,
        userId: action.userId,
      };
    case MEMBERSHIPS_LOAD_SUCCESS:
      return {
        ...state,
        memberships: action.memberships,
      };
    case MEMBERSHIPS_LOAD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case START_EDITING_MEMBERSHIP:
      return {
        ...state,
        memberships: state.memberships.map(m =>
          m.membership.uuid !== action.membershipUUID ? { ...m } : { ...m, editMode: true }
        ),
      };
    case CANCEL_EDITING_MEMBERSHIP:
      return {
        ...state,
        memberships: state.memberships.map(m =>
          m.membership.uuid !== action.membershipUUID ? { ...m } : { ...m, editMode: false }
        ),
      };
    case START_CHANGING_MEMBERSHIP_OFFER:
      return {
        ...state,
        membershipUUIDInChangeOfferMode: action.membershipUUID,
        b2cPayment: action.b2cPayment,
        sfAccountCountryCode: action.sfAccountCountryCode,
      };
    case FINISH_CHANGING_MEMBERSHIP_OFFER:
      return {
        ...state,
        membershipUUIDInChangeOfferMode: undefined,
      };
    case SAVING_MEMBERSHIP:
      return {
        ...state,
      };
    case MEMBERSHIP_SAVE_SUCCESS:
      return {
        ...state,
        memberships: state.memberships.map(m =>
          m.membership.uuid !== action.membership.membership.uuid
            ? { ...m }
            : { ...action.membership, editMode: false }
        ),
      };
    case MEMBERSHIP_OFFER_SAVE_SUCCESS:
      return {
        ...state,
        memberships: state.memberships.map(m =>
          m.membership.uuid !== action.membership.membership.uuid
            ? { ...m }
            : { ...action.membership, editMode: false }
        ),
      };
    case START_SELECTING_OFFER_FOR_NEW_MEMBERSHIP:
      return {
        ...state,
        selectOfferForNewMembership: true,
      };
    case FINISH_SELECTING_OFFER_FOR_NEW_MEMBERSHIP:
      return {
        ...state,
        newMembership: {
          membership: {
            membershipOfferId: action.membershipOfferSignupPagesWrapperDto.membershipOfferDto.id,
          },
          membershipOfferDto: {
            ...action.membershipOfferSignupPagesWrapperDto.membershipOfferDto,
          },
          relatedSignupPageNames:
            action.membershipOfferSignupPagesWrapperDto.signupPageDtos &&
            action.membershipOfferSignupPagesWrapperDto.signupPageDtos[0] &&
            action.membershipOfferSignupPagesWrapperDto.signupPageDtos[0].name,
          editMode: true,
        },
      };
    case CANCEL_SELECTING_OFFER_FOR_NEW_MEMBERSHIP:
      return {
        ...state,
        selectOfferForNewMembership: false,
      };
    case NEW_MEMBERSHIP_SAVE_SUCCESS:
      return {
        ...state,
        memberships: [...state.memberships, action.membership],
        newMembership: undefined,
      };
    case CANCEL_NEW_MEMBERSHIP:
      return {
        ...state,
        newMembership: undefined,
      };
    case MEMBERSHIP_REVOKE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case OPEN_EDIT_CHILD_MEMBERSHIP_MODAL:
      return {
        ...state,
        childMembershipUserId: action.childMembershipUserId,
        editChildMembershipModalOpen: true,
      };
    case CLOSE_EDIT_CHILD_MEMBERSHIP_MODAL:
      return {
        ...state,
        editChildMembershipModalOpen: false,
      };
    case EMPLOYEE_UNLINKING_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default: {
      return initialValue;
    }
  }
};
