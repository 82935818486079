// @flow
import {
  VALIDATION_INVALID_DATE,
  VALIDATION_INVALID_DATE_MUST_BE_IN_PAST,
  VALIDATION_REQUIRED_FIELD,
  VALIDATION_INVALID_MAX_LENGTH,
  VALIDATION_INVALID_MIN_LENGTH,
  VALIDATION_BLANK_VALUE,
  VALIDATION_INVALID_DATE_MUST_NOT_BE_IN_PAST,
  VALIDATION_INVALID_DATE_ORDER,
  VALIDATION_INVALID_DATE_MUST_BE_AFTER_MINIMAL,
} from 'app/validation/common/errorCodes';

import type { SingleFieldValidationResult } from 'app/validation/types';
import moment from 'moment';

export const validateRequired = (value: string): ?ValidationResult => {
  if (!value) {
    return { code: VALIDATION_REQUIRED_FIELD, args: {} };
  }
  return undefined;
};

export const validateLength = (minLength: number, maxLength: number): Function => {
  return (value: string): ?SingleFieldValidationResult => {
    if (value.length < minLength) {
      return { code: VALIDATION_INVALID_MIN_LENGTH, args: { '0': minLength } };
    }

    if (value.length > maxLength) {
      return { code: VALIDATION_INVALID_MAX_LENGTH, args: { '0': maxLength } };
    }
    return undefined;
  };
};

export const validateIsBlank = (value: string): SingleFieldValidationResult => {
  if (value && value.trim() === '') {
    return { code: VALIDATION_BLANK_VALUE };
  }
  return undefined;
};

/**
 * Function that can execute arbitrary number of validators on the given value.
 *
 * Prior to executing the array of validators, it will check whether the input value is empty.
 * In such case, the function will immediately return.
 * The returned value depends on the "required" attribute:
 * - if (required === false), then it returns "undefined", meaning NO validation error.
 * - if (required === true) (default), then it returns an error code.
 *
 * @param value the value to validate
 * @param required whether this is a required field
 * @returns {Function} a function that accepts any number of validators as arguments
 */
export const combineValidators = (value: ?string, required: boolean = true) => {
  return (...validators: Array<Function>) => {
    if (!value) {
      return required ? { code: VALIDATION_REQUIRED_FIELD, args: {} } : undefined;
    }

    const stringValue = typeof value === 'string' ? value : value.toString();

    for (let index = 0; index < validators.length; ++index) {
      const validator = validators[index];
      if (!isFunction(validator)) {
        continue;
      }
      const error = validator(stringValue);
      if (error !== undefined) {
        return error;
      }
    }
    return undefined;
  };
};

const isFunction = variable => {
  return variable && typeof variable === 'function';
};

export const validateDate = (dateFormat: string = 'DD-MM-YYYY'): Function => {
  return (value: string): ?SingleFieldValidationResult => {
    if (!moment(value, dateFormat, true).isValid()) {
      return { code: VALIDATION_INVALID_DATE, args: { dateFormat } };
    }
    return undefined;
  };
};

export const validateDateNotInPast = (dateFormat: string, granularity: string = ''): Function => {
  return (value: string): ?SingleFieldValidationResult => {
    if (moment().isAfter(moment(value, dateFormat), granularity)) {
      return { code: VALIDATION_INVALID_DATE_MUST_NOT_BE_IN_PAST };
    }
    return undefined;
  };
};

export const validateDateInPast = (value: string): ?SingleFieldValidationResult => {
  if (moment().diff(moment(value, 'DD.MM.YYYY')) < 0) {
    return { code: VALIDATION_INVALID_DATE_MUST_BE_IN_PAST };
  }
  return undefined;
};

export const validateDateIsAfterMinimal = (
  value: string,
  dateFormat: string = 'DD-MM-YYYY'
): ?SingleFieldValidationResult => {
  if (!moment(value, dateFormat, true).isAfter('1900-01-01')) {
    return { code: VALIDATION_INVALID_DATE_MUST_BE_AFTER_MINIMAL };
  }
  return undefined;
};

export const validateDateIsBeforeComparableDate = (
  date: string,
  dateFormat: string = 'DD-MM-YYYY'
): ?SingleFieldValidationResult => {
  return (value: string): ?SingleFieldValidationResult => {
    if (moment(value, dateFormat).isBefore(moment(date, dateFormat))) {
      return { code: VALIDATION_INVALID_DATE_ORDER, args: ['From', 'To'] };
    }
    return undefined;
  };
};

export const validatePassword = password => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{20,}$/;
  return regex.test(password);
};
