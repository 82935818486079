// @flow
import React from 'react';
import * as moment from 'moment';
import type {
  MembershipOfferFrontendModel,
  MembershipOfferSignupPagesWrapperDto,
} from 'app/ui/types';

type Props = {
  membershipOffer: MembershipOfferFrontendModel,
  membershipOfferSignupPagesWrapperDto: MembershipOfferSignupPagesWrapperDto,
};

const isActive = membershipOffer => {
  const now = moment.now();
  return (
    (!membershipOffer.availableFrom || moment(membershipOffer.availableFrom).isBefore(now)) &&
    (!membershipOffer.availableTo || moment(membershipOffer.availableTo).isAfter(now))
  );
};
const MembershipOfferInfo = ({ membershipOffer, membershipOfferSignupPagesWrapperDto }: Props) => (
  <dl className="row">
    <dt className="col-4">Country Code:</dt>
    <dd className="col-8">{membershipOffer.sfAccountCountryCode}</dd>
    <dt className="col-4">Status:</dt>
    <dd className="col-8">{isActive(membershipOffer) ? 'ACTIVE' : 'NOT ACTIVE'}</dd>
    <dt className="col-4">Price:</dt>
    <dd className="col-8">
      {membershipOffer.price
        ? `${membershipOffer.price.amount} ${membershipOffer.price.currency}`
        : '0.0'}
    </dd>
    <dt className="col-4">B2C Payment:</dt>
    <dd className="col-8">{membershipOffer.b2cPayment ? 'YES' : 'NO'}</dd>
    <dt className="col-4">Personal ID:</dt>
    <dd className="col-8">{membershipOffer.employeeInternalIdentifierLabel}</dd>
    {membershipOfferSignupPagesWrapperDto.signupPageDtos &&
    membershipOfferSignupPagesWrapperDto.signupPageDtos.length > 0 ? (
      membershipOfferSignupPagesWrapperDto.signupPageDtos.map(signupPage => (
        <React.Fragment key={signupPage.token}>
          <dt className="col-4">Signup Page:</dt>
          <dd className="col-8">{signupPage.name}</dd>
          <dt className="col-4">Link:</dt>
          <dd className="col-8">
            <a
              target="blank"
              href={`${window._env_.REACT_APP_QUALITRAIN_USER_FRONTEND_BASE_URL}/signup?companyToken=${signupPage.token}`}
            >
              {signupPage.token}
            </a>
          </dd>
        </React.Fragment>
      ))
    ) : (
      <>
        <dt className="col-4">Signup Page:</dt>
        <dd className="col-8 alert-warning">No signup pages for this offer</dd>
      </>
    )}
  </dl>
);

export default MembershipOfferInfo;
