// @flow
import React from 'react';

type Props = {
  children: any,
};

const HorizontalFormRow = ({ children }: Props) => (
  <div className="form-group form-row">{children}</div>
);

export default HorizontalFormRow;
