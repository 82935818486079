// @flow
import {
  SEARCH_FOR_SF_COMPANY_SUCCESS,
  SEARCH_FOR_SF_COMPANY_FAILURE,
  ADD_SF_COMPANY_TO_SELECTED_SF_COMPANIES,
  REMOVE_SF_COMPANY_FROM_SELECTED_SF_COMPANIES,
  ADD_ROLE_TO_USER_FAILURE,
} from 'app/ui/user-manager/user/roles/actions';
import type { BaseAction, SfCompany } from 'app/ui/types';

type State = {
  searchResultSfCompany: SfCompany,
  selectedSfCompanies: Array<SfCompany>,
  errorMessage: any,
};

type Action = {
  searchResultSfCompany: SfCompany,
  sfCompany: SfCompany,
  errorMessage: any,
};

export const initialValues = {
  searchResultSfCompany: undefined,
  selectedSfCompanies: [],
};

export const reducer = (state: State, action: BaseAction | Action) => {
  switch (action.type) {
    case SEARCH_FOR_SF_COMPANY_SUCCESS:
      return {
        ...state,
        searchResultSfCompany: action.sfCompany,
        errorMessage: undefined,
      };
    case SEARCH_FOR_SF_COMPANY_FAILURE:
      return {
        ...state,
        searchResultSfCompany: undefined,
        errorMessage: action.errorMessage,
      };
    case ADD_SF_COMPANY_TO_SELECTED_SF_COMPANIES:
      return {
        ...state,
        // remove it from the search results
        searchResultSfCompany: undefined,
        // add it to the selected companies
        selectedSfCompanies:
          state.selectedSfCompanies.filter(
            sfCompany => sfCompany.CanonicalId__c === action.sfCompany.CanonicalId__c
          ).length === 0
            ? [...state.selectedSfCompanies, action.sfCompany]
            : [...state.selectedSfCompanies],
        errorMessage: undefined,
      };
    case REMOVE_SF_COMPANY_FROM_SELECTED_SF_COMPANIES:
      return {
        ...state,
        selectedSfCompanies: [
          ...state.selectedSfCompanies.filter(
            sfCompany => sfCompany.CanonicalId__c !== action.sfCompanyId
          ),
        ],
        errorMessage: undefined,
      };
    case ADD_ROLE_TO_USER_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    default:
      return initialValues;
  }
};
