import { QR_TRANSLATIONS, SUPPORTED_QR_LANGUAGES } from './constants';

export const translateQRKey = (key, language) => {
  const nestedKeys = key.split('.');
  if (nestedKeys.length === 1) {
    if (!SUPPORTED_QR_LANGUAGES[language] || !QR_TRANSLATIONS[key]) return '';
    return QR_TRANSLATIONS[key][language];
  }

  let translation = { ...QR_TRANSLATIONS };
  nestedKeys.forEach(nestedKey => (translation = translation[nestedKey]));

  return translation[language];
};

export const getTranslationsByKey = key => QR_TRANSLATIONS[key];
