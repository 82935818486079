// @flow
import { getPropertyValueOrFallback } from 'app/utils/object/objectUtil';

const BYTE_ORDER_MARK = '\ufeff';
const SEPARATOR = ';';
const DELIMINATOR = '"';
const NEW_LINE = '\n';

type ColumnDefinition = {
  headerLabel: string,
  properties: Array<string>,
  transform: Function,
};

/**
 * Escapes the given value by doing two things:
 * 1) Encloses the value inside double quotes (DELIMINATOR)
 * 2) Escapes any double quote character in the value by placing a backslash character in front of it.
 *
 * Example:
 * - value: 12"345
 * - escaped value: "12\"345"
 */
const csvEscape = value => {
  const escapedValue = `${value.toString().replace(/"/g, `\\${DELIMINATOR}`)}`;
  return `${DELIMINATOR}${escapedValue}${DELIMINATOR}`;
};

const headerRowReducer = (accumulator, currentValue, index) =>
  index === 0 ? csvEscape(currentValue) : `${accumulator}${SEPARATOR}${csvEscape(currentValue)}`;

/**
 * Generates the header row for the CSV file.
 */
const generateHeaderRow = (columnDefinitions: Array<ColumnDefinition>): string => {
  return columnDefinitions.map(({ headerLabel }) => headerLabel).reduce(headerRowReducer, '');
};

const csvRowReducer =
  object =>
  (accumulator, { headerLabel, properties, transform }, index) => {
    const values = properties.map(property => {
      return getPropertyValueOrFallback(object, property);
    });
    let value = values[0];
    if (transform && typeof transform === 'function') {
      value = transform(...values);
    }
    return index === 0 ? csvEscape(value) : `${accumulator}${SEPARATOR}${csvEscape(value)}`;
  };

/**
 * Generates the csv rows (all rows after the header) for the CSV file.
 */
const generateCsvRows = (
  columnDefinitions: Array<ColumnDefinition>,
  objects: Array<any>
): string => {
  const csvRows = objects.map(object => columnDefinitions.reduce(csvRowReducer(object), ''));
  return csvRows.reduce(
    (accumulator, currentValue) =>
      accumulator ? `${accumulator}${NEW_LINE}${currentValue}` : currentValue,
    ''
  );
};

/**
 * Generates the complete content for the CSV file.
 *
 * NOTE:
 * It puts a BOM (Byte Order Mark) character as first character in the file.
 * This way the file can be easily opened by Microsoft Excel.
 */
export const generateCsv = (
  columnDefinitions: Array<ColumnDefinition>,
  objects: Array<any>
): string => {
  const headerRow = generateHeaderRow(columnDefinitions);
  const csvRows = generateCsvRows(columnDefinitions, objects);
  return `${BYTE_ORDER_MARK}${headerRow}${NEW_LINE}${csvRows}`;
};
