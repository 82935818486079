import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { httpGet, httpPost, httpPostUpload, httpPut } from 'app/service/http/index';
import { notificationService } from 'app/service/notification';
import MultiEntitySignupPageForm from './MultiEntitySignupPageForm';
import { withPageTemplate } from '../../layout/PageTemplate';

const MultiEntitySignupPageManager = () => {
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [multiEntitySignupPage, setMultiEntitySignupPage] = useState({
    name: '',
    slug: '',
    dropdownLabel: '',
    logo: '',
    expiration: null,
    linkedSignupPages: [],
  });

  const setErrorMessage = useCallback(errorMessage => {
    notificationService.push({
      message: errorMessage,
      variant: 'error',
    });
  }, []);

  const populateErrorMessage = useCallback(
    error => {
      error && error.message
        ? setErrorMessage(error.message)
        : setErrorMessage(JSON.stringify(error));
    },
    [setErrorMessage]
  );

  const getMultiEntitySignupPageById = useCallback(
    id => {
      executeWithSpinner(
        httpGet(`/v1/multi-entity-signup-page/${id}`)
          .then(res => {
            if (res) {
              setMultiEntitySignupPage(res);
            } else {
              populateErrorMessage('Signup page not found');
            }
          })
          .catch(error => populateErrorMessage(error))
      );
    },
    [executeWithSpinner, populateErrorMessage]
  );

  useEffect(() => {
    if (id) {
      getMultiEntitySignupPageById(id);
    }
  }, [getMultiEntitySignupPageById, id]);

  const uploadLogo = useCallback(async (multiEntitySignupPage, logo) => {
    if (multiEntitySignupPage && logo != null) {
      return await httpPostUpload(
        `/v1/multi-entity-signup-page/${multiEntitySignupPage.id}/logo`,
        logo
      );
    }
    return undefined;
  }, []);

  const createMultiEntitySignupPage = useCallback(
    async (multiEntitySignupPage, logoFile) => {
      const newPageResponse = await httpPost(`/v1/multi-entity-signup-page`, multiEntitySignupPage);
      if (newPageResponse && logoFile != null) {
        const logoResponse = await uploadLogo(newPageResponse, logoFile);
        if (logoResponse) {
          newPageResponse.logo = logoResponse;
        }
      }
      navigate(`${newPageResponse.id}`);
    },
    [navigate, uploadLogo]
  );

  const updateMultiEntitySignupPage = useCallback(
    async (multiEntitySignupPage, logoFile) => {
      await httpPut(
        `/v1/multi-entity-signup-page/${multiEntitySignupPage.id}`,
        multiEntitySignupPage
      );

      if (logoFile != null) {
        const logoResponse = await uploadLogo(multiEntitySignupPage, logoFile);
        if (logoResponse) {
          multiEntitySignupPage.logo = logoResponse;
        }
      }
      setMultiEntitySignupPage(multiEntitySignupPage);
    },
    [uploadLogo]
  );

  const onSubmit = useCallback(
    (multiEntitySignupPage, logo) => {
      if (multiEntitySignupPage.id) {
        executeWithSpinner(updateMultiEntitySignupPage(multiEntitySignupPage, logo));
      } else {
        executeWithSpinner(createMultiEntitySignupPage(multiEntitySignupPage, logo));
      }
    },
    [createMultiEntitySignupPage, executeWithSpinner, updateMultiEntitySignupPage]
  );

  const getMultiEntitySignupPageBySlug = useCallback(async slug => {
    return await httpGet(`/v1/multi-entity-signup-page/slug/${slug}`, null, response => {
      if (response.ok) {
        return response.json().catch(() => {
          notificationService.push({
            message: 'There was a problem getting the Multy-Entity signup page by slug',
            variant: 'error',
          });
        });
      }
      return Promise.resolve();
    });
  }, []);

  const getSignupPageByToken = useCallback(async token => {
    return await httpGet(`/v2/signup-page/${token}`, null, response => {
      if (response.ok) {
        return response.json().catch(() => {
          notificationService.push({
            message: 'There was a problem getting the Signup Page by token',
            variant: 'error',
          });
        });
      }
      return Promise.resolve();
    });
  }, []);

  return (
    <>
      <MultiEntitySignupPageForm
        multiEntitySignupPage={multiEntitySignupPage}
        getMultiEntitySignupPageBySlugFn={getMultiEntitySignupPageBySlug}
        getSignupPageByTokenFn={getSignupPageByToken}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default withPageTemplate(MultiEntitySignupPageManager, {
  headerTitle: 'Multi-Entity Signup Page Configurator',
});
