// @flow
import * as React from 'react';
import type { AdjustedCompanyRevenueInfo, CompanyRevenue } from '../../types';
import { useState, useEffect } from 'react';
import { httpPatch } from 'app/service/http';

type Props = {
  revenue: AdjustedCompanyRevenueInfo,
  isEditable: boolean,
};

const CompanyRevenueTableRow = ({ revenue, isEditable }: Props) => {
  const [adjustedCompanyRevenue, setAdjustedCompanyRevenue] = useState(null);
  const [comment, setComment] = useState(null);
  const [adjustedRevenue, setAdjustedRevenue] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setAdjustedCompanyRevenue(revenue);
    setComment(revenue.companyRevenue.comment ? revenue.companyRevenue.comment : '');

    const { adjustedRevenue } = revenue.companyRevenue;
    setAdjustedRevenue(adjustedRevenue || adjustedRevenue === 0 ? adjustedRevenue : '');
  }, [revenue]);

  const processKeyDown = event => {
    // ESC key processing
    if (event.keyCode === 27) {
      setValidationError(null);
      setComment(adjustedCompanyRevenue.companyRevenue.comment);
      setAdjustedRevenue(adjustedCompanyRevenue.companyRevenue.adjustedRevenue);
    }
    // ENTER key processing
    if (event.keyCode === 13) {
      validateAndUpdateCompanyRevenue();
    }
  };

  const isRevenuesDifferenceHigherThanExpected = (revenue: AdjustedCompanyRevenueInfo) => {
    if (!revenue && !revenue.revenueLastMonth) {
      return false;
    }

    if (revenue.companyRevenue.revenue === revenue.revenueLastMonth) {
      return false;
    }

    const multiplier =
      revenue.revenueLastMonth > revenue.companyRevenue.revenue
        ? revenue.revenueLastMonth / revenue.companyRevenue.revenue - 1
        : 1 - revenue.revenueLastMonth / revenue.companyRevenue.revenue;
    const percentage = parseFloat(multiplier * 100).toFixed(2);

    return percentage >= 15;
  };

  const onBlurProcess = () => {
    validateAndUpdateCompanyRevenue();
  };

  const validateAndUpdateCompanyRevenue = () => {
    const companyRevenue = adjustedCompanyRevenue.companyRevenue;

    if (
      ((!companyRevenue.comment && comment.trim() === '') || companyRevenue.comment === comment) &&
      ((companyRevenue.adjustedRevenue == null && adjustedRevenue === '') ||
        companyRevenue.adjustedRevenue === parseFloat(adjustedRevenue))
    ) {
      return;
    }

    if (adjustedRevenue.length !== 0 && adjustedRevenue < 0) {
      setValidationError('Incorrect value');
      return;
    }

    const updatedRevenue = { ...companyRevenue };
    updatedRevenue.comment = comment;
    updatedRevenue.adjustedRevenue = adjustedRevenue ? parseFloat(adjustedRevenue) : null;

    updateCompanyRevenue(updatedRevenue);
  };

  const updateCompanyRevenue = (revenue: CompanyRevenue) => {
    (async () => {
      try {
        await httpPatch(`/v1/payout/calculation/company-revenue/${revenue.id}`, revenue).then(
          updatedCompanyRevenue => {
            const updatedAdjustedCompanyRevenue = { ...adjustedCompanyRevenue };
            updatedAdjustedCompanyRevenue.companyRevenue = updatedCompanyRevenue;
            setAdjustedCompanyRevenue(updatedAdjustedCompanyRevenue);
          }
        );
      } catch (error) {
        setError(error && error.message);
      }
    })();
  };

  return (
    <>
      {adjustedCompanyRevenue && (
        <tr>
          <td>{adjustedCompanyRevenue.companyRevenue.sfCompanyAccountID}</td>
          <td>{adjustedCompanyRevenue.companyRevenue.sfCompanyName}</td>
          <td className="text-right">
            {adjustedCompanyRevenue.companyRevenue.revenue}{' '}
            <span>
              <i className="fas fa-xs fa-euro-sign" />
            </span>
          </td>
          <td>
            <div>
              <div>
                <input
                  id="inputAdjustedRevenue"
                  type="number"
                  min="0"
                  className="borderless-input text-right"
                  value={adjustedRevenue}
                  onChange={event => {
                    setValidationError(null);
                    setAdjustedRevenue(event.target.value);
                  }}
                  onKeyDown={event => processKeyDown(event)}
                  onBlur={() => onBlurProcess()}
                  readOnly={isEditable}
                />
              </div>
              <div>
                <small id="inputAdjustedRevenue" className="text-danger">
                  {validationError && <span>{validationError}</span>}
                </small>
              </div>
            </div>
          </td>
          <td>
            <input
              type="text"
              className="borderless-input left-pull"
              value={comment}
              onChange={event => setComment(event.target.value)}
              onKeyDown={event => processKeyDown(event)}
              onBlur={() => onBlurProcess()}
              readOnly={isEditable}
              data-toggle="tooltip"
              data-placement="bottom"
              title={comment}
            />
          </td>
          <td className="text-right">
            {adjustedCompanyRevenue.revenueLastMonth && (
              <span
                className={`${
                  isRevenuesDifferenceHigherThanExpected(adjustedCompanyRevenue)
                    ? 'warningText'
                    : ''
                }`}
              >
                {adjustedCompanyRevenue.revenueLastMonth}{' '}
                <i className="fas fa-xs inline-img fa-euro-sign" />
              </span>
            )}
          </td>
          <td>{adjustedCompanyRevenue.commentLastMonth}</td>
        </tr>
      )}
      {error && (
        <tr className="borderless-column">
          <td colSpan="7">
            <div className="alert alert-danger">{error}</div>
          </td>
        </tr>
      )}
    </>
  );
};

export default CompanyRevenueTableRow;
