import * as React from 'react';
import { formatMoneyAmount } from 'app/utils/format/currencyFormatter';
import { Link } from 'react-router-dom';
import { formatISOZonedDateTimeToUTCDateTime } from 'app/utils/format/dateTimeFormatter';

type Props = {
  charges: Object[],
  action: Function,
};

const FailedChargesTable = ({ charges, action }: Props) => {
  const withChargeRequest =
    Component =>
    ({ charge }) =>
      <Component charge={charge} />;
  const Button = withChargeRequest(action);

  return charges && charges.length ? (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>User Id</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Status message</th>
            <th>Created At</th>
            <th>Attempted At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {charges.map(charge => (
            <tr key={charge.chargeId}>
              <td>
                <Link to={`/users/user/${charge.userId}/paymentdetails`}>{charge.userId}</Link>
              </td>
              <td>{formatMoneyAmount(charge.amount)}</td>
              <td>{charge.status}</td>
              <td>{charge.statusMessage}</td>
              <td>{formatISOZonedDateTimeToUTCDateTime(charge.createdAt)}</td>
              <td>{formatISOZonedDateTimeToUTCDateTime(charge.attemptedAt)}</td>
              <td align="center">
                <Button charge={charge} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  ) : null;
};

export default FailedChargesTable;
