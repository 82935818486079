// @flow
import * as React from 'react';
import { initialState, reducer } from 'app/ui/membership/list-memberships/reducer';
import { httpGet } from 'app/service/http';
import {
  getMembershipsFailure,
  getMembershipsSuccess,
  getMembershipsEmpty,
} from 'app/ui/membership/list-memberships/actions';
import {
  MembershipsExportType,
  exportMembershipsToCsv,
} from 'app/utils/csv/membershipsCsvExporter';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { useState } from 'react';
import {
  DATE_VALIDATION_FAILURE_MESSAGE,
  GET_MEMBERSHIPS_EMPTY_MESSAGE,
} from 'app/ui/membership/list-memberships/membershipsAlertMessages';
import moment from 'moment';
import { dateToISODateTimeBeginningOfDayCET } from '../../user-manager/user/memberships/membershipUtils';
import { QUALITRAIN_OPS_MASTER, userHasRole } from '../../common/authentication/roles';
import AuthenticationContext from '../../common/authentication/AuthenticationContext';

const ListMembershipsPage = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [salesforceAccountCanonicalId, setSalesforceAccountCanonicalId] = useState('');
  const [date, setDate] = useState('');
  const [type, setType] = useState(MembershipsExportType.COUNTS);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const { user: loggedInUser } = React.useContext(AuthenticationContext);

  const isOpsMaster = userHasRole(loggedInUser, QUALITRAIN_OPS_MASTER);

  const exportMemberships = async (queryParams: Array<string>) => {
    queryParams.push('limit=10000');
    const opsMasterURL = isOpsMaster ? '/ops-master' : '';
    let membershipsPage = await httpGet(`/v1/membership${opsMasterURL}?${queryParams.join('&')}`);
    const memberships = [].concat(membershipsPage.items);

    while (membershipsPage.nextPageToken) {
      membershipsPage = await httpGet(
        `/v1/membership${opsMasterURL}?${queryParams.join('&')}&pageToken=${
          membershipsPage.nextPageToken
        }`
      );
      memberships.push(...membershipsPage.items);
    }
    //In case of multiple corporate employees for same egym account id we are selecting the latest one
    memberships.forEach(membership => {
      if (membership.corporateEmployees) {
        membership.corporateEmployee = membership.corporateEmployees.reduce((earliest, current) =>
          current.startDate < earliest.startDate ? earliest : current
        );
      }
    });
    return memberships;
  };
  const exportMembershipsCount = (queryParams: Array<string>) =>
    httpGet(`/v1/membership-count?${queryParams.join('&')}`);

  const dataLoaders = {
    [MembershipsExportType.COUNTS]: exportMembershipsCount,
    [MembershipsExportType.LIST]: exportMemberships,
  };

  const csvFileNameSuffix = (type, date, sfAccountCanonicalId: string) => {
    let suffix = `${type.toLowerCase()}_${date}`;
    if (type === MembershipsExportType.LIST && sfAccountCanonicalId) {
      suffix += `_${salesforceAccountCanonicalId}`;
    }
    return suffix;
  };

  const getMemberships = event => {
    let membershipExportType = type;
    event.preventDefault();

    if (date && !moment(date, 'DD-MM-YYYY', true).isValid()) {
      dispatch(getMembershipsFailure(DATE_VALIDATION_FAILURE_MESSAGE));
      return;
    }

    const queryParams = [];
    if (date) {
      queryParams.push(
        `effectiveTimestamp=${encodeURIComponent(dateToISODateTimeBeginningOfDayCET(date))}`
      );
    }

    if (salesforceAccountCanonicalId) {
      queryParams.push(`sfAccountCanonicalId=${encodeURIComponent(salesforceAccountCanonicalId)}`);
    }

    if (type === MembershipsExportType.LIST && date && salesforceAccountCanonicalId) {
      membershipExportType = MembershipsExportType.LIST_WITH_HRIS;
    }

    executeWithSpinner(
      (async () => {
        try {
          const response = await dataLoaders[type](queryParams);
          if (response && response.length) {
            exportMembershipsToCsv(
              membershipExportType,
              response,
              csvFileNameSuffix(type, date, salesforceAccountCanonicalId)
            );
            dispatch(getMembershipsSuccess(response));
          } else {
            dispatch(getMembershipsEmpty(GET_MEMBERSHIPS_EMPTY_MESSAGE));
          }
        } catch (error) {
          dispatch(getMembershipsFailure(error));
        }
      })()
    );
  };

  return (
    <>
      <form onSubmit={getMemberships}>
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <div className="input-group">
                <select
                  id="role"
                  className="form-control w-25"
                  style={{ maxWidth: '220px' }}
                  placeholder="Role"
                  onChange={event => setType(event.target.value)}
                >
                  <option value={MembershipsExportType.COUNTS}>Total # Per Company</option>
                  <option value={MembershipsExportType.LIST}>List</option>
                </select>
                {type === MembershipsExportType.LIST && (
                  <input
                    id="salesforceAccountCanonicalId"
                    type="text"
                    className="form-control"
                    value={salesforceAccountCanonicalId}
                    placeholder="Salesforce Account Canonical Id"
                    onChange={event => setSalesforceAccountCanonicalId(event.target.value)}
                  />
                )}
                <input
                  id="date"
                  type="text"
                  className="form-control"
                  value={date}
                  placeholder="Date* DD-MM-YYYY"
                  onChange={event => setDate(event.target.value)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    type="submit"
                    disabled={
                      type === MembershipsExportType.LIST
                        ? isOpsMaster
                          ? !salesforceAccountCanonicalId && !date
                          : !salesforceAccountCanonicalId
                        : !date
                    }
                  >
                    Export memberships
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <p className="text-left text-muted">
        * The date you set will give you all memberships active on that date
      </p>
      {state.errorMessage && (
        <div className="alert alert-danger mt-4">
          {state.errorMessage.message ? state.errorMessage.message : state.errorMessage}
        </div>
      )}
      {state.emptyMembershipsMessage && (
        <div className="alert alert-dark mt-4">{state.emptyMembershipsMessage}</div>
      )}
    </>
  );
};

export default withPageTemplate(ListMembershipsPage, { headerTitle: 'Memberships' });
