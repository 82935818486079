// @flow
import * as React from 'react';
import { useState } from 'react';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import { httpPost } from 'app/service/http';
import { isValidUuid } from 'app/utils/number/numberUtils';
import { isDev } from 'app/utils/env/envUtils';
import egym_id_screenshot from './egym_id_screenshot.png';
import { useNavigate } from 'react-router-dom';

const EgymUserImportPage = () => {
  const [accountId, setAccountId] = useState('');
  const [error, setError] = useState('');
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const navigate = useNavigate();
  const host =
    isDev() || window.location.hostname.includes('coffee')
      ? 'monolith.staging.test.co.egym.coffee'
      : 'www.egym.com';
  const userManagerUrl = `https://${host}/manage/user/`;

  const getPayments = event => {
    event.preventDefault();

    if (isValidUuid(accountId)) {
      executeWithSpinner(
        httpPost(`/v1/user/${accountId}/egym-import`)
          .then(resp => navigate(`/users/user/${accountId}`))
          .catch(error => {
            const errorMsg =
              error && error.message
                ? error.message.includes('404')
                  ? 'User not found, please double check ID'
                  : error.message
                : JSON.stringify(error);
            setError(errorMsg);
          })
      );
    } else {
      setError(
        `Invalid accountID ${accountId}. It should look like the one marked in the screenshot below.`
      );
    }
  };

  return (
    <>
      <form onSubmit={getPayments}>
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <div className="input-group">
                <input
                  id="egym-account-id"
                  type="text"
                  className="form-control"
                  value={accountId}
                  placeholder="EGYM Account ID (format looks like this: 1cf29096-39d3-4836-95e5-73ba4ea31)"
                  onChange={event => setAccountId(event.target.value)}
                />
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="submit" disabled={!accountId}>
                    Import & open this user
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {error && <div className="alert alert-primary my-3">{error}</div>}

      <div>
        You find the EGYM account ID here:
        <a rel="noopener noreferrer" target="_blank" href={userManagerUrl}>
          <img className="w-100 mt-2" src={egym_id_screenshot} alt={'EGYM ID screenshot'} />
        </a>
      </div>
    </>
  );
};

export default withPageTemplate(EgymUserImportPage, { headerTitle: 'Import EGYM User' });
